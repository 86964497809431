import { Heading, VStack, Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import startPageCar from "../../assets/images/startPageCar.png";
import startPageCarMobile from "../../assets/images/startPageCarMobile.jpg";
import { useCkeckIfMobile } from "../../hooks/useCheckIfMobile";
import { logIn } from "../../utils/navigationLinks";

export const LandingPage = () => {
  const { isMobile } = useCkeckIfMobile();
  const navigate = useNavigate();
  return (
    <VStack className="page">
      <Box
        w={isMobile ? "27rem" : "60rem"}
        h={isMobile ? "33rem" : "32rem"}
        backgroundImage={isMobile ? startPageCarMobile : startPageCar}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        //  mb={isMobile ? 0 : 64}
      >
        {/* <Image src={isMobile ? startPageCarMobile : startPageCar} /> */}
        <Heading
          textAlign="center"
          color={"brand.white"}
          pt={isMobile ? 40 : 28}
          fontSize={{ md: "xxl", base: "6xl" }}
          fontWeight={400}
        >
          Use real time
          <br /> vehicle data
        </Heading>
      </Box>
      <VStack pb={24} spacing={""}>
        <Heading
          textAlign="center"
          color={"brand.darkGreen"}
          pt={isMobile ? 40 : 28}
          fontSize={{ md: "6xl", base: "6xl" }}
          fontWeight={400}
        >
          Welcome to the Evolvo Portal! <br /> Please login to proceed.
        </Heading>
        <Button
          onClick={() => navigate(logIn.href)}
          type="submit"
          size={"long"}
          variant="primary"
        >
          Log In
        </Button>
      </VStack>
    </VStack>
  );
};
