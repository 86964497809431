import { useEffect, useState } from "react";
import { services } from "../../components/ServiceBox/services";
import { useToastbar } from "../../hooks/useToastbar";
import { useUser } from "../../services/user.context";
import { CheckboxServiceItem, User } from "../../types";
import { servicesToCheckboxItems } from "../../utils/TypeUtils";
import { ManageUsers } from "./ManageUsers";
import {
  deleteUserId,
  getSubscriptionCompanyCompany_id,
  getUser,
  postUserId,
} from "../../services/services";
import { AxiosError } from "axios";

export const ManageUsersContainer = () => {
  const toastbar = useToastbar();
  const { user: currentUser } = useUser();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [availableServices, setAvailableServices] = useState<
    CheckboxServiceItem[]
  >([]);

  const updateUser = async (user: User) => {
    postUserId(user.id, {
      _id: user.id,
      name: user.name,
      role_id: user.roleId,
      custom_scopes: [...user.roleScopes, ...user.serviceScopes],
      deactivated: false,
    }).catch((error: AxiosError) => {
      console.log(error.response);
      toastbar({
        title: "An error occured while updating user",
        description: error.message,
        status: "error",
      });
    });
  };

  const removeUser = async (user: User) => {
    deleteUserId(user.id)
      .then(() => {
        removeUserFromList(user);
        toastbar({
          title: "User removed",
          status: "success",
        });
      })
      .catch((error: AxiosError) => {
        const description =
          error.response?.status === 404 ? "User not found." : error.message;
        toastbar({
          title: "An error occured",
          description: description,
          status: "error",
        });
      });
  };

  useEffect(() => {
    const allServices = services; // This is here to make sure the list is fetched in time
    const fetchUsersAndServices = async () => {
      // GET company users
      getUser()
        .then((response) => {
          const userInfo = response.map((u) => {
            const userInfo: User = {
              id: u._id || "",
              name: u.name,
              email: u.email,
              companyId: u.company_id,
              companyName: currentUser!.companyName,
              serviceScopes: u?.custom_scopes || [],
              roleScopes: [], // Not applicable here
              roleId: u.role_id,
              hasMfaSetup: false,
            };
            return userInfo;
          });
          setUsers(userInfo);
          // GET available services
          return getSubscriptionCompanyCompany_id(currentUser?.companyId || "");
        })
        .then((response) => {
          const availableServiceIds = response.map((sub) => sub.service_uid);
          const filteredServices = allServices.filter((serv) => {
            return availableServiceIds.includes(serv.id);
          });
          const serviceItems = servicesToCheckboxItems(filteredServices);
          setAvailableServices(serviceItems);
        })
        .catch((error: AxiosError) => {
          toastbar({
            title: "An error occured",
            description: error.message,
            status: "error",
          });
        });
    };

    fetchUsersAndServices();
    setLoading(false);
  }, []);

  const findUserIndex = (user: User) => {
    return users.findIndex((item) => item.id === user.id);
  };

  const updateUserScopes = async (user: User, item: CheckboxServiceItem) => {
    user.serviceScopes = item.readIsChecked
      ? [...user.serviceScopes, item.readScope.valueOf()]
      : [
          ...user.serviceScopes.filter(
            (scope) => scope !== item.readScope.valueOf()
          ),
        ];

    if (item.writeScope !== undefined) {
      user.serviceScopes =
        item.writeIsChecked === true
          ? [...user.serviceScopes, item.writeScope.valueOf()]
          : [
              ...user.serviceScopes.filter(
                (scope) => scope !== item.writeScope!.valueOf()
              ),
            ];
    }
    await updateUser(user);
  };

  const removeUserFromList = (user: User) => {
    setUsers((users) => {
      const index = findUserIndex(user);
      if (index > -1) {
        users.splice(index, 1);
      }
      return [...users];
    });
  };

  return (
    <ManageUsers
      removeUser={removeUser}
      updateUser={updateUserScopes}
      serviceItems={loading ? [] : availableServices}
      users={loading ? [] : users}
    />
  );
};
