import { Box, VStack, Text } from "@chakra-ui/react";

type SingleValueBoxProps = {
  title: string;
  value: number;
};

export const SingleValueBox: React.FC<SingleValueBoxProps> = ({
  title,
  value,
}) => {
  return (
    <Box borderWidth={"1px"} padding={5} maxWidth={250}>
      <VStack spacing={2}>
        <Text textAlign={"left"} fontSize={"sm"}>
          {title}
        </Text>
        <Text as={"b"} fontSize={"5xl"}>
          {value}
        </Text>
      </VStack>
    </Box>
  );
};
