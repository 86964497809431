import { Checkbox, HStack, CheckboxGroup } from "@chakra-ui/react";

type Props = {
  title: string;
  disabled: boolean;
  secondBoxDisabled: boolean;
  initialState: { first: boolean; second?: boolean };
  onFirstBoxChange: (checked: boolean) => void;
  onSecondBoxChange: (checked: boolean) => void;
};

export const DoubleCheckboxWithTitle = ({
  title,
  disabled = false,
  secondBoxDisabled = false,
  initialState = { first: false, second: false },
  onFirstBoxChange,
  onSecondBoxChange,
}: Props) => {
  return (
    <HStack paddingLeft={3} spacing={3} key={title}>
      <CheckboxGroup colorScheme={"green"} key={title + "checkboxgroup"}>
        <Checkbox
          paddingRight={2}
          isDisabled={disabled}
          onChange={event => onFirstBoxChange(event.target.checked)}
          defaultChecked={initialState.first}
          key={title + "read"}
        ></Checkbox>
        <Checkbox
          paddingRight={2}
          onChange={event => onSecondBoxChange(event.target.checked)}
          isDisabled={secondBoxDisabled || disabled}
          defaultChecked={initialState.second || false}
          key={title + "write"}
        ></Checkbox>
        {title}
      </CheckboxGroup>
    </HStack>
  );
};
