import { LinkNameAndHref } from "../types";

export const root: LinkNameAndHref = { linkName: "Root", href: "/" };
export const logIn: LinkNameAndHref = { linkName: "Log in", href: "/login" };
export const mfaSetup: LinkNameAndHref = {
  linkName: "MFA Setup",
  href: "/mfasetup",
};
export const resetPassword: LinkNameAndHref = {
  linkName: "Reset password",
  href: "/reset-password",
};
export const createAccount: LinkNameAndHref = {
  linkName: "Create Account",
  href: "/create-account",
};
export const createPassword: LinkNameAndHref = {
  linkName: "Create Password",
  href: "/newpassword",
};
export const start = "/start";
export const startLink: LinkNameAndHref = { linkName: "Start", href: start };
export const termsAndConditions: LinkNameAndHref = {
  linkName: "Terms and conditions",
  href: "/",
};
export const privacyAndPolicy: LinkNameAndHref = {
  linkName: "Privacy policy",
  href: "/",
};

export const rgs: LinkNameAndHref = {
  linkName: "Reverse Geocoding Service",
  href: "/rgs",
};

/**
 * Travel time estimation
 */
export const travelTimeEstimation: LinkNameAndHref = {
  linkName: "Travel time measurements",
  href: "/travel-time-measurements",
};
export const travelTimeEstimationInteractive: LinkNameAndHref = {
  linkName: "Travel times interactive map",
  href: "/travel-time-measurements/map",
};
export const travelTimeEstimationApiUsers: LinkNameAndHref = {
  linkName: "Travel times api tokens",
  href: "/travel-time-measurements/apiusers",
};

export const manageUsers: LinkNameAndHref = {
  linkName: "Manage Users",
  href: "/manage-users",
};

export const trafficWatch: LinkNameAndHref = {
  linkName: "AD Clearance",
  href: "/adclearance",
};

export const rekorOne: LinkNameAndHref = {
  linkName: "RekorOne Client",
  href: "/rekorone",
};

export const autonomousDrivingClearanceService: LinkNameAndHref = {
  linkName: "Autonomous Driving Clearance Service  ",
  href: "/",
};
export const accidentRiskAlert: LinkNameAndHref = {
  linkName: "Accident Risk Alert",
  href: "/",
};

export const accidentRiskEstimation: LinkNameAndHref = {
  linkName: "ARE",
  href: "/are",
};

export const historicalAccidentAnalysisService: LinkNameAndHref = {
  linkName: "Historical Accident Analysis Service",
  href: "/",
};

export const roadSignAnalysis: LinkNameAndHref = {
  linkName: "Road Sign Analysis",
  href: "/rsa",
};
export const safetyGraph: LinkNameAndHref = {
  linkName: "Safety Graph",
  href: "/safetygraph",
};
export const realtimeHazardWarning: LinkNameAndHref = {
  linkName: "Realtime Hazard Warning",
  href: "/",
};
export const safeSpeedService: LinkNameAndHref = {
  linkName: "Safe Speed Service",
  href: "/",
};

export const geographicalDataExploration: LinkNameAndHref = {
  linkName: "Geographical Data Exploration",
  href: "/gde",
};

export const footerPageGeneralLinks: Array<LinkNameAndHref> = [
  logIn,
  startLink,
  privacyAndPolicy,
];

export const linksWithHiddenLoginButton: Array<string> = [
  logIn.href,
  root.href,
];
