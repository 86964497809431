import { Text, VStack, Link } from "@chakra-ui/react";
import "@fontsource/azeret-mono";
import { startLink } from "../utils/navigationLinks";

type Props = {
  systemColor: string;
  levelColor: string;
  safetyColor: string;
};

export const Logo = ({ systemColor, levelColor, safetyColor }: Props) => {
  return (
    <Link href={startLink.href}>
      <VStack>
        <Text style={style.logoStyle} color={systemColor}>
          SYSTEM
        </Text>
        <Text
          style={{ ...style.logoStyle, ...style.wordLevelStyle }}
          color={levelColor}
        >
          LEVEL
        </Text>
        <Text
          style={{ ...style.logoStyle, ...style.wordSafetyStyle }}
          color={safetyColor}
        >
          SAFETY
        </Text>
      </VStack>
    </Link>
  );
};

const style = {
  logoStyle: {
    lineHeight: "0.8rem",
    fontWeight: "700",
    fontFamily: "Azeret Mono",

    letterSpacing: "0.375rem",
    fontSize: "1.3rem",
  },
  wordLevelStyle: {
    letterSpacing: "0.7rem",
    paddingLeft: "0.6rem",
  },
  wordSafetyStyle: {
    letterSpacing: "0.40rem",
    paddingLeft: "0.1rem",
  },
};
