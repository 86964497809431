/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import { customInstance } from './customAxiosConfig';
export type MapmatchSinglePointRgsPointGetParams = {
lat: number;
lon: number;
radius: number;
};

export type GetDatasetLocationdataDatasetNameGetParams = {
sw_lon: number;
sw_lat: number;
ne_lon: number;
ne_lat: number;
start_unixtime?: number;
end_unixtime?: number;
num_samples?: number;
};

export type GetMarkersAreGetMarkersGetParams = {
query_type: string;
lat?: number;
lon?: number;
radius?: number;
country?: string;
state?: string;
day_of_week?: string;
time_of_day?: string;
start_date?: string;
end_date?: string;
};

export type GetRiskScoreAreGetRiskScoreGetParams = {
lat: number;
lon: number;
};

export type GetAccidentDataAreGetAccidentDataGetParams = {
lat: number;
lon: number;
};

export type GetMonitoringStatisticsWithFiltersTtmMonitorIdStatisticsGetParams = {
start_time: number;
end_time?: number;
max_speed?: number;
max_distance?: number;
max_drive_time?: number;
via_gfs?: string;
};

export type GetCompanySubscriptionsSubscriptionCompanyCompanyIdGetParams = {
only_active?: boolean;
};

export interface ViaPointRouteStats {
  count_travels?: number;
  drive_distance?: MetricStat;
  drive_speed?: MetricStat;
  drive_time?: MetricStat;
  num_stops?: MetricStat;
  total_time?: MetricStat;
}

export interface ViaPointRoute {
  gfs: string[];
  name?: string;
  stats: ViaPointRouteStats;
}

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

/**
 * Document Mapping class.

Fields:

- `id` - MongoDB document ObjectID "_id" field.
Mapped to the PydanticObjectId class

Inherited from:

- Pydantic BaseModel
- [UpdateMethods](https://roman-right.github.io/beanie/api/interfaces/#aggregatemethods)
 */
export interface UserUpdate {
  _id?: string;
  custom_scopes?: string[];
  deactivated: boolean;
  name?: string;
  role_id?: number;
}

export interface UserEmail {
  email: string;
}

/**
 * Document Mapping class.

Fields:

- `id` - MongoDB document ObjectID "_id" field.
Mapped to the PydanticObjectId class

Inherited from:

- Pydantic BaseModel
- [UpdateMethods](https://roman-right.github.io/beanie/api/interfaces/#aggregatemethods)
 */
export interface UserData {
  _id?: string;
  company_id: string;
  custom_scopes?: string[];
  deactivated: boolean;
  email: string;
  name: string;
  role_id: number;
}

export interface UpdateCompany {
  address?: string;
  email?: string;
  name?: string;
  phone?: string;
}

export interface UidScopeData {
  description: string;
  name: string;
  service_uid?: number;
}

export interface Token {
  access_token: string;
  token_type: string;
}

export interface TTGeofenceGeometryPolygon {
  coordinates: LatLon[];
}

export interface TTGeofenceGeometryCircle {
  la: number;
  lo: number;
  radius: number;
}

export interface TTGeofenceGeometry {
  circle?: TTGeofenceGeometryCircle;
  polygon?: TTGeofenceGeometryPolygon;
}

export interface SubscriptionRenew {
  valid_time: number;
}

export interface SubscriptionCreate {
  service_uid: number;
  valid_time: number;
}

/**
 * Document Mapping class.

Fields:

- `id` - MongoDB document ObjectID "_id" field.
Mapped to the PydanticObjectId class

Inherited from:

- Pydantic BaseModel
- [UpdateMethods](https://roman-right.github.io/beanie/api/interfaces/#aggregatemethods)
 */
export interface Subscription {
  _id?: string;
  company_id: string;
  revision_id?: string;
  service_uid: number;
  valid_from: number;
  valid_to: number;
}

export interface SinglePointResponse {
  geo_json?: FeatureCollection;
  map_unixtime: number;
  map_version: number;
}

export interface SignCount {
  annotations_count: number;
  detected_count: number;
  nvdb_count: number;
}

/**
 * Document Mapping class.

Fields:

- `id` - MongoDB document ObjectID "_id" field.
Mapped to the PydanticObjectId class

Inherited from:

- Pydantic BaseModel
- [UpdateMethods](https://roman-right.github.io/beanie/api/interfaces/#aggregatemethods)
 */
export interface Service {
  _id?: string;
  description: string;
  name: string;
  uid: number;
}

export interface ScopeData {
  description: string;
  name: string;
}

export interface ResetToken {
  reset_token: string;
  token_type: string;
}

export interface NotFoundError {
  /** Unique identifier which this error references to */
  identifier: string;
  /** Error message or description */
  message: string;
}

export interface NewlyCreatedApiUser {
  id: string;
  name: string;
  token: string;
}

export interface NVDBSign {
  info: string;
  lat: number;
  lon: number;
  nvdb_id: number;
  wkt: string;
}

export interface MonitorStatistics {
  count_travels?: number;
  drive_distance?: MetricStat;
  drive_speed?: MetricStat;
  drive_time?: MetricStat;
  num_stops?: MetricStat;
  routes?: ViaPointRoute[];
  total_time?: MetricStat;
}

export interface MonitorName {
  name: string;
}

export interface MonitorData {
  gf_via?: string[];
  id: string;
  name: string;
  start_geofence_id: string;
  stop_geofence_id: string;
}

export interface Monitor {
  name: string;
  start_geofence_id: string;
  stop_geofence_id: string;
}

export interface MetricStat {
  max?: number;
  mean?: number;
  median?: number;
  min?: number;
}

export interface MeUpdate {
  name: string;
}

export interface MeData {
  company_name: string;
  email: string;
  hasMfaSetup: boolean;
  name: string;
}

export interface LoginReturn {
  mfa_active: boolean;
}

export interface LatLon {
  la: number;
  lo: number;
}

export interface Impersonator {
  company_id: string;
  custom_scopes: string[];
  role_id: number;
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface GetTokenData {
  company_id: string;
  custom_scopes: string[];
  role_id: number;
  role_scopes: string[];
  user_id: string;
}

export interface Geometry {
  coordinates: number[];
  type: string;
}

export interface GeofenceName {
  name: string;
}

export interface GeofenceData {
  geometry: TTGeofenceGeometry;
  id: string;
  name: string;
}

export interface Geofence {
  geometry: TTGeofenceGeometry;
  name: string;
}

export type FeatureProperties = { [key: string]: any };

export interface Feature {
  geometry: Geometry;
  properties?: FeatureProperties;
  type: string;
}

export interface FeatureCollection {
  features: Feature[];
  type: string;
}

export interface DiffStats {
  no_signs: number;
  radius: number;
}

export interface DatasetInfo {
  description: string;
  end_unixtime?: number;
  lat: number;
  lon: number;
  name: string;
  num_entries: number;
  start_unixtime?: number;
}

export interface DatasetsData {
  datasets: DatasetInfo[];
}

export type DatasetDataData = { [key: string]: any };

export interface DatasetData {
  data: DatasetDataData;
  lat: number;
  lon: number;
  unixtime?: number;
}

export interface CreateUser {
  custom_scopes?: string[];
  deactivated: boolean;
  email: string;
  name: string;
  otp_secret?: string;
  role_id: number;
}

export interface CreateApiUser {
  name: string;
}

/**
 * Document Mapping class.

Fields:

- `id` - MongoDB document ObjectID "_id" field.
Mapped to the PydanticObjectId class

Inherited from:

- Pydantic BaseModel
- [UpdateMethods](https://roman-right.github.io/beanie/api/interfaces/#aggregatemethods)
 */
export interface Company {
  _id?: string;
  address: string;
  email: string;
  name: string;
  phone: string;
}

export interface CameraDetected {
  heading?: number;
  info: string;
  lat: number;
  lon: number;
  prob: number;
  speedLim: number;
}

export interface CMSUpdate {
  imageId?: string;
  sequence?: number;
  service_uid: number;
  text?: string;
  title?: string;
}

/**
 * Document Mapping class.

Fields:

- `id` - MongoDB document ObjectID "_id" field.
Mapped to the PydanticObjectId class

Inherited from:

- Pydantic BaseModel
- [UpdateMethods](https://roman-right.github.io/beanie/api/interfaces/#aggregatemethods)
 */
export interface CMSEntry {
  _id?: string;
  imageId?: string;
  sequence?: number;
  service_uid?: number;
  text?: string;
  title: string;
}

export interface BodyVerifyMfaLoginMfaVerifyPost {
  client_id?: string;
  client_secret?: string;
  grant_type?: string;
  password: string;
  scope?: string;
  user_totp: string;
  username: string;
}

export interface BodyUploadImageCmsCmsEntryIdImagePut {
  image_file: Blob;
}

export interface BodyResetTokenLoginTokenResetPost {
  client_id?: string;
  client_secret?: string;
  grant_type?: string;
  password: string;
  scope?: string;
  username: string;
}

export interface BodyNewPasswordLoginPasswordNewPost {
  client_id?: string;
  client_secret?: string;
  grant_type?: string;
  password: string;
  scope?: string;
  username: string;
}

export interface BodyLoginLoginPost {
  client_id?: string;
  client_secret?: string;
  grant_type?: string;
  password: string;
  scope?: string;
  username: string;
}

export interface BaseExternalApiError {
  /** The status code from the external api */
  external_status_code: number;
  /** Error message or description */
  message: string;
}

export interface BaseError {
  /** Error message or description */
  message: string;
}

export interface ApiUser {
  id: string;
  name: string;
}

export interface Annotation {
  info: string;
  lat: number;
  lon: number;
}

export interface AlreadyExistsError {
  /** Unique identifier which this error references to */
  identifier: string;
  /** Error message or description */
  message: string;
}




// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


  /**
 * @summary Read Root
 */
export const readRootGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/`, method: 'GET'
    },
      options);
    }
  
/**
 * Get users own info
 * @summary Get Me
 */
export const getMeMeIdGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MeData>(
      {url: `/me/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * Update users own info
 * @summary Update Me
 */
export const updateMeMeIdPost = (
    id: string,
    meUpdate: MeUpdate,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MeUpdate>(
      {url: `/me/${id}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: meUpdate
    },
      options);
    }
  
/**
 * Get all active users in authorized company
 * @summary Get Active Users
 */
export const getActiveUsersUserGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserData[]>(
      {url: `/user`, method: 'GET'
    },
      options);
    }
  
/**
 * Create a new user in authorized company
 * @summary Create User
 */
export const createUserUserPost = (
    createUser: CreateUser,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserData>(
      {url: `/user`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createUser
    },
      options);
    }
  
/**
 * Get all deactivated users in authorized company
 * @summary Get Deactivated Users
 */
export const getDeactivatedUsersUserDeactivatedGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserData[]>(
      {url: `/user/deactivated`, method: 'GET'
    },
      options);
    }
  
/**
 * Get a user
 * @summary Get User
 */
export const getUserUserIdGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserData>(
      {url: `/user/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * Update user info
 * @summary Update User
 */
export const updateUserUserIdPost = (
    id: string,
    userUpdate: UserUpdate,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserUpdate>(
      {url: `/user/${id}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userUpdate
    },
      options);
    }
  
/**
 * Deactivate a user
 * @summary Delete User
 */
export const deleteUserUserIdDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/user/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * Get identity and permission details
 * @summary Get Login Info
 */
export const getLoginInfoLoginGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GetTokenData>(
      {url: `/login`, method: 'GET'
    },
      options);
    }
  
/**
 * Login
 * @summary Login
 */
export const loginLoginPost = (
    bodyLoginLoginPost: BodyLoginLoginPost,
 options?: SecondParameter<typeof customInstance>,) => {const formUrlEncoded = new URLSearchParams();
if(bodyLoginLoginPost.grant_type !== undefined) {
 formUrlEncoded.append('grant_type', bodyLoginLoginPost.grant_type)
 }
formUrlEncoded.append('username', bodyLoginLoginPost.username)
formUrlEncoded.append('password', bodyLoginLoginPost.password)
if(bodyLoginLoginPost.scope !== undefined) {
 formUrlEncoded.append('scope', bodyLoginLoginPost.scope)
 }
if(bodyLoginLoginPost.client_id !== undefined) {
 formUrlEncoded.append('client_id', bodyLoginLoginPost.client_id)
 }
if(bodyLoginLoginPost.client_secret !== undefined) {
 formUrlEncoded.append('client_secret', bodyLoginLoginPost.client_secret)
 }

      return customInstance<LoginReturn>(
      {url: `/login`, method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded', },
       data: formUrlEncoded
    },
      options);
    }
  
/**
 * Verify MFA TOTP code
 * @summary Verify Mfa
 */
export const verifyMfaLoginMfaVerifyPost = (
    bodyVerifyMfaLoginMfaVerifyPost: BodyVerifyMfaLoginMfaVerifyPost,
 options?: SecondParameter<typeof customInstance>,) => {const formUrlEncoded = new URLSearchParams();
formUrlEncoded.append('user_totp', bodyVerifyMfaLoginMfaVerifyPost.user_totp)
if(bodyVerifyMfaLoginMfaVerifyPost.grant_type !== undefined) {
 formUrlEncoded.append('grant_type', bodyVerifyMfaLoginMfaVerifyPost.grant_type)
 }
formUrlEncoded.append('username', bodyVerifyMfaLoginMfaVerifyPost.username)
formUrlEncoded.append('password', bodyVerifyMfaLoginMfaVerifyPost.password)
if(bodyVerifyMfaLoginMfaVerifyPost.scope !== undefined) {
 formUrlEncoded.append('scope', bodyVerifyMfaLoginMfaVerifyPost.scope)
 }
if(bodyVerifyMfaLoginMfaVerifyPost.client_id !== undefined) {
 formUrlEncoded.append('client_id', bodyVerifyMfaLoginMfaVerifyPost.client_id)
 }
if(bodyVerifyMfaLoginMfaVerifyPost.client_secret !== undefined) {
 formUrlEncoded.append('client_secret', bodyVerifyMfaLoginMfaVerifyPost.client_secret)
 }

      return customInstance<Token>(
      {url: `/login/mfa/verify`, method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded', },
       data: formUrlEncoded
    },
      options);
    }
  
/**
 * Get a reset token
 * @summary Reset Token
 */
export const resetTokenLoginTokenResetPost = (
    bodyResetTokenLoginTokenResetPost: BodyResetTokenLoginTokenResetPost,
 options?: SecondParameter<typeof customInstance>,) => {const formUrlEncoded = new URLSearchParams();
if(bodyResetTokenLoginTokenResetPost.grant_type !== undefined) {
 formUrlEncoded.append('grant_type', bodyResetTokenLoginTokenResetPost.grant_type)
 }
formUrlEncoded.append('username', bodyResetTokenLoginTokenResetPost.username)
formUrlEncoded.append('password', bodyResetTokenLoginTokenResetPost.password)
if(bodyResetTokenLoginTokenResetPost.scope !== undefined) {
 formUrlEncoded.append('scope', bodyResetTokenLoginTokenResetPost.scope)
 }
if(bodyResetTokenLoginTokenResetPost.client_id !== undefined) {
 formUrlEncoded.append('client_id', bodyResetTokenLoginTokenResetPost.client_id)
 }
if(bodyResetTokenLoginTokenResetPost.client_secret !== undefined) {
 formUrlEncoded.append('client_secret', bodyResetTokenLoginTokenResetPost.client_secret)
 }

      return customInstance<ResetToken>(
      {url: `/login/token/reset`, method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded', },
       data: formUrlEncoded
    },
      options);
    }
  
/**
 * Send a mail with a reset password url to the provided email
 * @summary Forgot Password
 */
export const forgotPasswordLoginPasswordForgotPost = (
    userEmail: UserEmail,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/login/password/forgot`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userEmail
    },
      options);
    }
  
/**
 * Add new password
 * @summary New Password
 */
export const newPasswordLoginPasswordNewPost = (
    bodyNewPasswordLoginPasswordNewPost: BodyNewPasswordLoginPasswordNewPost,
 options?: SecondParameter<typeof customInstance>,) => {const formUrlEncoded = new URLSearchParams();
if(bodyNewPasswordLoginPasswordNewPost.grant_type !== undefined) {
 formUrlEncoded.append('grant_type', bodyNewPasswordLoginPasswordNewPost.grant_type)
 }
formUrlEncoded.append('username', bodyNewPasswordLoginPasswordNewPost.username)
formUrlEncoded.append('password', bodyNewPasswordLoginPasswordNewPost.password)
if(bodyNewPasswordLoginPasswordNewPost.scope !== undefined) {
 formUrlEncoded.append('scope', bodyNewPasswordLoginPasswordNewPost.scope)
 }
if(bodyNewPasswordLoginPasswordNewPost.client_id !== undefined) {
 formUrlEncoded.append('client_id', bodyNewPasswordLoginPasswordNewPost.client_id)
 }
if(bodyNewPasswordLoginPasswordNewPost.client_secret !== undefined) {
 formUrlEncoded.append('client_secret', bodyNewPasswordLoginPasswordNewPost.client_secret)
 }

      return customInstance<void>(
      {url: `/login/password/new`, method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded', },
       data: formUrlEncoded
    },
      options);
    }
  
/**
 * Get a new token
 * @summary Refresh Token
 */
export const refreshTokenLoginRefreshPost = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Token>(
      {url: `/login/refresh`, method: 'POST'
    },
      options);
    }
  
/**
 * Get a token with specified permissions
 * @summary Get Impersonator Token
 */
export const getImpersonatorTokenLoginImpersonatorPost = (
    impersonator: Impersonator,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Token>(
      {url: `/login/impersonator`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: impersonator
    },
      options);
    }
  
/**
 * Get all companies
 * @summary Get Companies
 */
export const getCompaniesCompanyGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Company[]>(
      {url: `/company`, method: 'GET'
    },
      options);
    }
  
/**
 * Create a company
 * @summary Create Company
 */
export const createCompanyCompanyPost = (
    company: Company,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Company>(
      {url: `/company`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: company
    },
      options);
    }
  
/**
 * Get a company
 * @summary Get Company
 */
export const getCompanyCompanyIdGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Company>(
      {url: `/company/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * Update a company
 * @summary Update Company
 */
export const updateCompanyCompanyIdPost = (
    id: string,
    updateCompany: UpdateCompany,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Company>(
      {url: `/company/${id}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateCompany
    },
      options);
    }
  
/**
 * Get all services
 * @summary Get Services
 */
export const getServicesServiceGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Service[]>(
      {url: `/service`, method: 'GET'
    },
      options);
    }
  
/**
 * Get specified service
 * @summary Get Service
 */
export const getServiceServiceIdGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Service>(
      {url: `/service/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * Get all scopes
 * @summary Get All Scopes
 */
export const getAllScopesScopeGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ScopeData[]>(
      {url: `/scope`, method: 'GET'
    },
      options);
    }
  
/**
 * Get service scopes
 * @summary Get All Service Scopes
 */
export const getAllServiceScopesScopeServiceGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UidScopeData[]>(
      {url: `/scope/service`, method: 'GET'
    },
      options);
    }
  
/**
 * Get scopes for specified service
 * @summary Get Scopes For Service
 */
export const getScopesForServiceScopeServiceUidGet = (
    uid: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UidScopeData[]>(
      {url: `/scope/service/${uid}`, method: 'GET'
    },
      options);
    }
  
/**
 * Get all subscriptions
 * @summary Get All Subscriptions
 */
export const getAllSubscriptionsSubscriptionGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Subscription[]>(
      {url: `/subscription`, method: 'GET'
    },
      options);
    }
  
/**
 * Get a company's subscriptions
 * @summary Get Company Subscriptions
 */
export const getCompanySubscriptionsSubscriptionCompanyCompanyIdGet = (
    companyId: string,
    params?: GetCompanySubscriptionsSubscriptionCompanyCompanyIdGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Subscription[]>(
      {url: `/subscription/company/${companyId}`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Create a new subscription
 * @summary Create Company Subscription
 */
export const createCompanySubscriptionSubscriptionCompanyCompanyIdPost = (
    companyId: string,
    subscriptionCreate: SubscriptionCreate,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Subscription>(
      {url: `/subscription/company/${companyId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subscriptionCreate
    },
      options);
    }
  
/**
 * Get a company's subscription
 * @summary Get Company Subscription
 */
export const getCompanySubscriptionSubscriptionCompanyCompanyIdIdGet = (
    companyId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Subscription>(
      {url: `/subscription/company/${companyId}/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * Renew a company's subscription
 * @summary Renew Company Subscription
 */
export const renewCompanySubscriptionSubscriptionCompanyCompanyIdIdPost = (
    companyId: string,
    id: string,
    subscriptionRenew: SubscriptionRenew,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Subscription>(
      {url: `/subscription/company/${companyId}/${id}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subscriptionRenew
    },
      options);
    }
  
/**
 * Delete a company's subscription
 * @summary Delete Company Subscription
 */
export const deleteCompanySubscriptionSubscriptionCompanyCompanyIdIdDelete = (
    companyId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/subscription/company/${companyId}/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * Get company geofences
 * @summary Get Geofences
 */
export const getGeofencesTtmGeofenceGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GeofenceData[]>(
      {url: `/ttm/geofence`, method: 'GET'
    },
      options);
    }
  
/**
 * Create a geofence
 * @summary Create Geofence
 */
export const createGeofenceTtmGeofencePost = (
    geofence: Geofence,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GeofenceData>(
      {url: `/ttm/geofence`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: geofence
    },
      options);
    }
  
/**
 * Get a geofence
 * @summary Get Geofence
 */
export const getGeofenceTtmGeofenceIdGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GeofenceData>(
      {url: `/ttm/geofence/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * Update the name of a geofence
 * @summary Update Geofence Name
 */
export const updateGeofenceNameTtmGeofenceIdPut = (
    id: string,
    geofenceName: GeofenceName,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/ttm/geofence/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: geofenceName
    },
      options);
    }
  
/**
 * Delete a geofence
 * @summary Delete Geofence
 */
export const deleteGeofenceTtmGeofenceIdDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/ttm/geofence/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * Get company monitorings
 * @summary Get Monitorings
 */
export const getMonitoringsTtmMonitorGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MonitorData[]>(
      {url: `/ttm/monitor`, method: 'GET'
    },
      options);
    }
  
/**
 * Start to monitor geofences
 * @summary Create Monitor
 */
export const createMonitorTtmMonitorPost = (
    monitor: Monitor,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MonitorData>(
      {url: `/ttm/monitor`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: monitor
    },
      options);
    }
  
/**
 * Get a monitoring
 * @summary Get Monitoring
 */
export const getMonitoringTtmMonitorIdGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MonitorData>(
      {url: `/ttm/monitor/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * Update the name of a monitoring
 * @summary Update Monitor Name
 */
export const updateMonitorNameTtmMonitorIdPut = (
    id: string,
    monitorName: MonitorName,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MonitorData>(
      {url: `/ttm/monitor/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: monitorName
    },
      options);
    }
  
/**
 * Delete a monitoring
 * @summary Delete Monitoring
 */
export const deleteMonitoringTtmMonitorIdDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/ttm/monitor/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * Get statistics for a monitoring
 * @summary Get Monitoring Statistics With Filters
 */
export const getMonitoringStatisticsWithFiltersTtmMonitorIdStatisticsGet = (
    id: string,
    params: GetMonitoringStatisticsWithFiltersTtmMonitorIdStatisticsGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<MonitorStatistics>(
      {url: `/ttm/monitor/${id}/statistics`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get api users
 * @summary Get Api Users
 */
export const getApiUsersTtmApiusersGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiUser[]>(
      {url: `/ttm/apiusers`, method: 'GET'
    },
      options);
    }
  
/**
 * Create api users
 * @summary Get Api Users
 */
export const getApiUsersTtmApiusersPost = (
    createApiUser: CreateApiUser,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<NewlyCreatedApiUser>(
      {url: `/ttm/apiusers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createApiUser
    },
      options);
    }
  
/**
 * Create api users
 * @summary Get Api Users
 */
export const getApiUsersTtmApiusersIdDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/ttm/apiusers/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * Get data for the accident at the given coordinates
 * @summary Get Accident Data
 */
export const getAccidentDataAreGetAccidentDataGet = (
    params: GetAccidentDataAreGetAccidentDataGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/are/get_accident_data`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get risk score for the given coordinates if they are on a road segment
 * @summary Get Risk Score
 */
export const getRiskScoreAreGetRiskScoreGet = (
    params: GetRiskScoreAreGetRiskScoreGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/are/get_risk_score`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get markers and associated data as a list
 * @summary Get Markers
 */
export const getMarkersAreGetMarkersGet = (
    params: GetMarkersAreGetMarkersGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/are/get_markers`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Get annotated speed signs
 * @summary Get Annotations
 */
export const getAnnotationsRsaAnnotationsGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Annotation[]>(
      {url: `/rsa/annotations`, method: 'GET'
    },
      options);
    }
  
/**
 * Get NVDB speed signs
 * @summary Get Nvdb Data
 */
export const getNvdbDataRsaNvdbGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<NVDBSign[]>(
      {url: `/rsa/nvdb`, method: 'GET'
    },
      options);
    }
  
/**
 * Get camera detected speed signs
 * @summary Get Camera Detected
 */
export const getCameraDetectedRsaDetectedGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CameraDetected[]>(
      {url: `/rsa/detected`, method: 'GET'
    },
      options);
    }
  
/**
 * Get stats about detected signs differencing from NVDB data
 * @summary Get Detected Diff Stats
 */
export const getDetectedDiffStatsRsaDetectedDiffStatsGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DiffStats[]>(
      {url: `/rsa/detected_diff_stats`, method: 'GET'
    },
      options);
    }
  
/**
 * Get stats about annotated signs differencing from NVDB data
 * @summary Get Annotations Diff Stats
 */
export const getAnnotationsDiffStatsRsaAnnotationsDiffStatsGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DiffStats[]>(
      {url: `/rsa/annotations_diff_stats`, method: 'GET'
    },
      options);
    }
  
/**
 * Get the amount of signs in each category
 * @summary Get Signs Count
 */
export const getSignsCountRsaSignsCountGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<SignCount>(
      {url: `/rsa/signs_count`, method: 'GET'
    },
      options);
    }
  
/**
 * Get available datasets
 * @summary Get Datasets
 */
export const getDatasetsLocationdataDatasetGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DatasetsData>(
      {url: `/locationdata/dataset`, method: 'GET'
    },
      options);
    }
  
/**
 * Get samples from dataset
 * @summary Get Dataset
 */
export const getDatasetLocationdataDatasetNameGet = (
    name: string,
    params: GetDatasetLocationdataDatasetNameGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DatasetData[]>(
      {url: `/locationdata/dataset/${name}`, method: 'GET',
        params
    },
      options);
    }
  
/**
 * Create a CMS entry
 * @summary Create Cms Data
 */
export const createCmsDataCmsPost = (
    cMSEntry: CMSEntry,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<string>(
      {url: `/cms`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cMSEntry
    },
      options);
    }
  
/**
 * Get all CMS entries for a service
 * @summary Get Cms Entries
 */
export const getCmsEntriesCmsServiceUidGet = (
    serviceUid: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CMSEntry[]>(
      {url: `/cms/${serviceUid}`, method: 'GET'
    },
      options);
    }
  
/**
 * Get a CMS entry by ID
 * @summary Get Cms Entry
 */
export const getCmsEntryCmsServiceUidIdGet = (
    serviceUid: number,
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CMSEntry>(
      {url: `/cms/${serviceUid}/${id}`, method: 'GET'
    },
      options);
    }
  
/**
 * Delete a CMS entry
 * @summary Delete Cms Entry And Image
 */
export const deleteCmsEntryAndImageCmsIdDelete = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/cms/${id}`, method: 'DELETE'
    },
      options);
    }
  
/**
 * Update a CMS entry
 * @summary Update Cms Entry
 */
export const updateCmsEntryCmsIdPatch = (
    id: string,
    cMSUpdate: CMSUpdate,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/cms/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: cMSUpdate
    },
      options);
    }
  
/**
 * Get the image corresponding to a CMS entry
 * @summary Get Image
 */
export const getImageCmsCmsEntryIdImageGet = (
    cmsEntryId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown | Blob>(
      {url: `/cms/${cmsEntryId}/image`, method: 'GET'
    },
      options);
    }
  
/**
 * Upload an image for a CMS entry
 * @summary Upload Image
 */
export const uploadImageCmsCmsEntryIdImagePut = (
    cmsEntryId: string,
    bodyUploadImageCmsCmsEntryIdImagePut: BodyUploadImageCmsCmsEntryIdImagePut,
 options?: SecondParameter<typeof customInstance>,) => {const formData = new FormData();
formData.append('image_file', bodyUploadImageCmsCmsEntryIdImagePut.image_file)

      return customInstance<string>(
      {url: `/cms/${cmsEntryId}/image`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  
/**
 * Delete the image corresponding to a CMS entry
 * @summary Delete Image
 */
export const deleteImageCmsCmsEntryIdImageDelete = (
    cmsEntryId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/cms/${cmsEntryId}/image`, method: 'DELETE'
    },
      options);
    }
  
/**
 * Get image data for image corresponding to a CMS entry
 * @summary Get Image Data
 */
export const getImageDataCmsCmsEntryIdImageDataGet = (
    cmsEntryId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<string>(
      {url: `/cms/${cmsEntryId}/image_data`, method: 'GET'
    },
      options);
    }
  
/**
 * @summary Mapmatch Single Point
 */
export const mapmatchSinglePointRgsPointGet = (
    params: MapmatchSinglePointRgsPointGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<SinglePointResponse>(
      {url: `/rgs/point`, method: 'GET',
        params
    },
      options);
    }
  
export type ReadRootGetResult = NonNullable<Awaited<ReturnType<typeof readRootGet>>>
export type GetMeMeIdGetResult = NonNullable<Awaited<ReturnType<typeof getMeMeIdGet>>>
export type UpdateMeMeIdPostResult = NonNullable<Awaited<ReturnType<typeof updateMeMeIdPost>>>
export type GetActiveUsersUserGetResult = NonNullable<Awaited<ReturnType<typeof getActiveUsersUserGet>>>
export type CreateUserUserPostResult = NonNullable<Awaited<ReturnType<typeof createUserUserPost>>>
export type GetDeactivatedUsersUserDeactivatedGetResult = NonNullable<Awaited<ReturnType<typeof getDeactivatedUsersUserDeactivatedGet>>>
export type GetUserUserIdGetResult = NonNullable<Awaited<ReturnType<typeof getUserUserIdGet>>>
export type UpdateUserUserIdPostResult = NonNullable<Awaited<ReturnType<typeof updateUserUserIdPost>>>
export type DeleteUserUserIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteUserUserIdDelete>>>
export type GetLoginInfoLoginGetResult = NonNullable<Awaited<ReturnType<typeof getLoginInfoLoginGet>>>
export type LoginLoginPostResult = NonNullable<Awaited<ReturnType<typeof loginLoginPost>>>
export type VerifyMfaLoginMfaVerifyPostResult = NonNullable<Awaited<ReturnType<typeof verifyMfaLoginMfaVerifyPost>>>
export type ResetTokenLoginTokenResetPostResult = NonNullable<Awaited<ReturnType<typeof resetTokenLoginTokenResetPost>>>
export type ForgotPasswordLoginPasswordForgotPostResult = NonNullable<Awaited<ReturnType<typeof forgotPasswordLoginPasswordForgotPost>>>
export type NewPasswordLoginPasswordNewPostResult = NonNullable<Awaited<ReturnType<typeof newPasswordLoginPasswordNewPost>>>
export type RefreshTokenLoginRefreshPostResult = NonNullable<Awaited<ReturnType<typeof refreshTokenLoginRefreshPost>>>
export type GetImpersonatorTokenLoginImpersonatorPostResult = NonNullable<Awaited<ReturnType<typeof getImpersonatorTokenLoginImpersonatorPost>>>
export type GetCompaniesCompanyGetResult = NonNullable<Awaited<ReturnType<typeof getCompaniesCompanyGet>>>
export type CreateCompanyCompanyPostResult = NonNullable<Awaited<ReturnType<typeof createCompanyCompanyPost>>>
export type GetCompanyCompanyIdGetResult = NonNullable<Awaited<ReturnType<typeof getCompanyCompanyIdGet>>>
export type UpdateCompanyCompanyIdPostResult = NonNullable<Awaited<ReturnType<typeof updateCompanyCompanyIdPost>>>
export type GetServicesServiceGetResult = NonNullable<Awaited<ReturnType<typeof getServicesServiceGet>>>
export type GetServiceServiceIdGetResult = NonNullable<Awaited<ReturnType<typeof getServiceServiceIdGet>>>
export type GetAllScopesScopeGetResult = NonNullable<Awaited<ReturnType<typeof getAllScopesScopeGet>>>
export type GetAllServiceScopesScopeServiceGetResult = NonNullable<Awaited<ReturnType<typeof getAllServiceScopesScopeServiceGet>>>
export type GetScopesForServiceScopeServiceUidGetResult = NonNullable<Awaited<ReturnType<typeof getScopesForServiceScopeServiceUidGet>>>
export type GetAllSubscriptionsSubscriptionGetResult = NonNullable<Awaited<ReturnType<typeof getAllSubscriptionsSubscriptionGet>>>
export type GetCompanySubscriptionsSubscriptionCompanyCompanyIdGetResult = NonNullable<Awaited<ReturnType<typeof getCompanySubscriptionsSubscriptionCompanyCompanyIdGet>>>
export type CreateCompanySubscriptionSubscriptionCompanyCompanyIdPostResult = NonNullable<Awaited<ReturnType<typeof createCompanySubscriptionSubscriptionCompanyCompanyIdPost>>>
export type GetCompanySubscriptionSubscriptionCompanyCompanyIdIdGetResult = NonNullable<Awaited<ReturnType<typeof getCompanySubscriptionSubscriptionCompanyCompanyIdIdGet>>>
export type RenewCompanySubscriptionSubscriptionCompanyCompanyIdIdPostResult = NonNullable<Awaited<ReturnType<typeof renewCompanySubscriptionSubscriptionCompanyCompanyIdIdPost>>>
export type DeleteCompanySubscriptionSubscriptionCompanyCompanyIdIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteCompanySubscriptionSubscriptionCompanyCompanyIdIdDelete>>>
export type GetGeofencesTtmGeofenceGetResult = NonNullable<Awaited<ReturnType<typeof getGeofencesTtmGeofenceGet>>>
export type CreateGeofenceTtmGeofencePostResult = NonNullable<Awaited<ReturnType<typeof createGeofenceTtmGeofencePost>>>
export type GetGeofenceTtmGeofenceIdGetResult = NonNullable<Awaited<ReturnType<typeof getGeofenceTtmGeofenceIdGet>>>
export type UpdateGeofenceNameTtmGeofenceIdPutResult = NonNullable<Awaited<ReturnType<typeof updateGeofenceNameTtmGeofenceIdPut>>>
export type DeleteGeofenceTtmGeofenceIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteGeofenceTtmGeofenceIdDelete>>>
export type GetMonitoringsTtmMonitorGetResult = NonNullable<Awaited<ReturnType<typeof getMonitoringsTtmMonitorGet>>>
export type CreateMonitorTtmMonitorPostResult = NonNullable<Awaited<ReturnType<typeof createMonitorTtmMonitorPost>>>
export type GetMonitoringTtmMonitorIdGetResult = NonNullable<Awaited<ReturnType<typeof getMonitoringTtmMonitorIdGet>>>
export type UpdateMonitorNameTtmMonitorIdPutResult = NonNullable<Awaited<ReturnType<typeof updateMonitorNameTtmMonitorIdPut>>>
export type DeleteMonitoringTtmMonitorIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteMonitoringTtmMonitorIdDelete>>>
export type GetMonitoringStatisticsWithFiltersTtmMonitorIdStatisticsGetResult = NonNullable<Awaited<ReturnType<typeof getMonitoringStatisticsWithFiltersTtmMonitorIdStatisticsGet>>>
export type GetApiUsersTtmApiusersGetResult = NonNullable<Awaited<ReturnType<typeof getApiUsersTtmApiusersGet>>>
export type GetApiUsersTtmApiusersPostResult = NonNullable<Awaited<ReturnType<typeof getApiUsersTtmApiusersPost>>>
export type GetApiUsersTtmApiusersIdDeleteResult = NonNullable<Awaited<ReturnType<typeof getApiUsersTtmApiusersIdDelete>>>
export type GetAccidentDataAreGetAccidentDataGetResult = NonNullable<Awaited<ReturnType<typeof getAccidentDataAreGetAccidentDataGet>>>
export type GetRiskScoreAreGetRiskScoreGetResult = NonNullable<Awaited<ReturnType<typeof getRiskScoreAreGetRiskScoreGet>>>
export type GetMarkersAreGetMarkersGetResult = NonNullable<Awaited<ReturnType<typeof getMarkersAreGetMarkersGet>>>
export type GetAnnotationsRsaAnnotationsGetResult = NonNullable<Awaited<ReturnType<typeof getAnnotationsRsaAnnotationsGet>>>
export type GetNvdbDataRsaNvdbGetResult = NonNullable<Awaited<ReturnType<typeof getNvdbDataRsaNvdbGet>>>
export type GetCameraDetectedRsaDetectedGetResult = NonNullable<Awaited<ReturnType<typeof getCameraDetectedRsaDetectedGet>>>
export type GetDetectedDiffStatsRsaDetectedDiffStatsGetResult = NonNullable<Awaited<ReturnType<typeof getDetectedDiffStatsRsaDetectedDiffStatsGet>>>
export type GetAnnotationsDiffStatsRsaAnnotationsDiffStatsGetResult = NonNullable<Awaited<ReturnType<typeof getAnnotationsDiffStatsRsaAnnotationsDiffStatsGet>>>
export type GetSignsCountRsaSignsCountGetResult = NonNullable<Awaited<ReturnType<typeof getSignsCountRsaSignsCountGet>>>
export type GetDatasetsLocationdataDatasetGetResult = NonNullable<Awaited<ReturnType<typeof getDatasetsLocationdataDatasetGet>>>
export type GetDatasetLocationdataDatasetNameGetResult = NonNullable<Awaited<ReturnType<typeof getDatasetLocationdataDatasetNameGet>>>
export type CreateCmsDataCmsPostResult = NonNullable<Awaited<ReturnType<typeof createCmsDataCmsPost>>>
export type GetCmsEntriesCmsServiceUidGetResult = NonNullable<Awaited<ReturnType<typeof getCmsEntriesCmsServiceUidGet>>>
export type GetCmsEntryCmsServiceUidIdGetResult = NonNullable<Awaited<ReturnType<typeof getCmsEntryCmsServiceUidIdGet>>>
export type DeleteCmsEntryAndImageCmsIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteCmsEntryAndImageCmsIdDelete>>>
export type UpdateCmsEntryCmsIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateCmsEntryCmsIdPatch>>>
export type GetImageCmsCmsEntryIdImageGetResult = NonNullable<Awaited<ReturnType<typeof getImageCmsCmsEntryIdImageGet>>>
export type UploadImageCmsCmsEntryIdImagePutResult = NonNullable<Awaited<ReturnType<typeof uploadImageCmsCmsEntryIdImagePut>>>
export type DeleteImageCmsCmsEntryIdImageDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteImageCmsCmsEntryIdImageDelete>>>
export type GetImageDataCmsCmsEntryIdImageDataGetResult = NonNullable<Awaited<ReturnType<typeof getImageDataCmsCmsEntryIdImageDataGet>>>
export type MapmatchSinglePointRgsPointGetResult = NonNullable<Awaited<ReturnType<typeof mapmatchSinglePointRgsPointGet>>>
