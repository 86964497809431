import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  Button,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { CMSEditorFormState, CMSEditorModal } from "./CMSEditorModal";
import { useUser } from "../../services/user.context";
import {
  CMSEntry,
  CMSUpdate,
  createCmsDataCmsPost,
  deleteCmsEntryAndImageCmsIdDelete,
  deleteImageCmsCmsEntryIdImageDelete,
  getCmsEntriesCmsServiceUidGet,
  getImageDataCmsCmsEntryIdImageDataGet,
  updateCmsEntryCmsIdPatch,
  uploadImageCmsCmsEntryIdImagePut,
} from "../../http/http";
import { useToastbar } from "../../hooks/useToastbar";
import { AxiosError } from "axios";

export type Section = {
  id: string;
  title: string;
  text: string;
  sequence: number;
  imageDataUrl: string;
};

type TextAndImageSectionProps = {
  sec: Section;
  canEdit: boolean;
  onEdit: (tb: Section) => void;
};

const TextAndImageSection: React.FC<TextAndImageSectionProps> = ({
  sec,
  canEdit,
  onEdit,
}) => {
  return (
    <Box
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      p="4"
      width={"100%"}
    >
      <VStack spacing={1} alignItems={"start"}>
        <Heading size="lg" mb="2">
          {sec.title}
        </Heading>
        <Text mb="2">{sec.text}</Text>
        {sec.imageDataUrl !== "" && (
          <Image
            src={sec.imageDataUrl}
            alt={sec.title}
            maxW={"100%"}
            maxH={"500px"}
          />
        )}
        {canEdit && (
          <HStack spacing={1}>
            <Button size={"sm"} onClick={() => onEdit(sec)}>
              Edit
            </Button>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

type CMSProps = { serviceUid: number };

// TODO: Replace image byte strings with URLs and remove image_data endpoint
// TODO: Move state management to parent component to avoid reloading data on every render
const CMS: React.FC<CMSProps> = ({ serviceUid }) => {
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const [sections, setSections] = useState<Section[]>([]);
  const [selectedSection, setSelectedSection] = useState<Section | null>(null);
  const { user: currentUser } = useUser();
  const [canEdit] = useState<boolean>(currentUser?.roleId === 1 || false);
  const toast = useToastbar();

  const fileBlobToImageUrl = async (file: Blob) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject(new Error("Failed to convert Blob to image URL."));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const sortBySequence = (sectionList: Section[]) => {
    return sectionList.sort((a, b) => a.sequence - b.sequence);
  };

  const fetchSections = async (serviceUid: number): Promise<Section[]> => {
    const cmsEntries = await getCmsEntriesCmsServiceUidGet(serviceUid);
    const sectionPromises = cmsEntries.map(async (entry) => {
      if (entry.imageId && entry.imageId !== "") {
        const file = await getImageDataCmsCmsEntryIdImageDataGet(
          entry._id || ""
        );
        const section: Section = {
          id: entry._id || "",
          title: entry.title,
          text: entry.text || "",
          sequence: entry.sequence || 0,
          imageDataUrl: file,
        };
        return section;
      } else {
        const section: Section = {
          id: entry._id || "",
          title: entry.title,
          text: entry.text || "",
          sequence: entry.sequence || 0,
          imageDataUrl: "",
        };
        return section;
      }
    });
    return Promise.all(sectionPromises);
  };

  useEffect(() => {
    fetchSections(serviceUid)
      .then((data) => {
        setSections(data);
      })
      .catch((error: AxiosError) => {
        toast({
          title: "Error fetching CMS data",
          description: error.message,
          status: "error",
        });
      });
  }, []);

  const handleSubmit = async (formData: CMSEditorFormState) => {
    const cmsEntry: CMSEntry = {
      service_uid: serviceUid,
      title: formData.title,
      text: formData.text,
      sequence: sections.length + 1,
    };
    await createCmsDataCmsPost(cmsEntry)
      .then(async (cmsEntryId) => {
        cmsEntry._id = cmsEntryId;
        if (formData.imageFile !== null) {
          // upload image
          await uploadImageCmsCmsEntryIdImagePut(cmsEntryId, {
            image_file: formData.imageFile,
          }).then(async (cmsImageId) => {
            // update cms entry with image id
            await updateCmsEntryCmsIdPatch(cmsEntryId, {
              service_uid: serviceUid,
              title: formData.title,
              text: formData.text,
              sequence: sections.length + 1,
              imageId: cmsImageId,
            });
            let imageUrl = "";
            if (formData.imageFile !== null) {
              // extra null check to appease the type checker gods
              await fileBlobToImageUrl(formData.imageFile).then((url) => {
                imageUrl = url;
              });
            }
            setSections([
              ...sections,
              {
                id: cmsEntryId,
                title: cmsEntry.title,
                text: cmsEntry.text || "",
                sequence: cmsEntry.sequence || 0,
                imageDataUrl: imageUrl,
              },
            ]);
          });
        } else {
          // no image to upload
          setSections([
            ...sections,
            {
              id: cmsEntryId,
              title: cmsEntry.title,
              text: cmsEntry.text || "",
              sequence: cmsEntry.sequence || 0,
              imageDataUrl: "",
            },
          ]);
        }
      })
      .then(() => {
        toast({
          title: "CMS entry created",
          description: "Successfully created CMS entry",
          status: "success",
        });
      })
      .catch((error: AxiosError) => {
        toast({
          title: "Error creating CMS entry",
          description: error.message,
          status: "error",
        });
      });
  };

  const handleUpdateSubmit = async (formData: CMSEditorFormState) => {
    if (selectedSection === null) {
      return;
    }
    // updating existing section
    const cmsUpdate: CMSUpdate = {
      service_uid: serviceUid,
      title: formData.title,
      text: formData.text,
      sequence: selectedSection.sequence,
    };

    let imgData = "";
    if (formData.imageFile !== null) {
      // replace image
      await deleteImageCmsCmsEntryIdImageDelete(selectedSection.id).catch(
        (error: AxiosError) => {
          toast({
            title: "Error deleting image",
            description: error.message,
            status: "error",
          });
        }
      );
      const newImageId = await uploadImageCmsCmsEntryIdImagePut(
        selectedSection.id,
        {
          image_file: formData.imageFile,
        }
      ).catch((error: AxiosError) => {
        toast({
          title: "Error uploading image",
          description: error.message,
          status: "error",
        });
        return "";
      });
      if (newImageId === "") {
        return;
      }
      cmsUpdate.imageId = newImageId;
      imgData = await fileBlobToImageUrl(formData.imageFile);
    }

    await updateCmsEntryCmsIdPatch(selectedSection.id, cmsUpdate)
      .then(() => {
        const filteredSections = sections.filter(
          (s) => s.id !== selectedSection.id
        );

        const updatedSection: Section = {
          id: selectedSection.id,
          title: formData.title,
          text: formData.text,
          sequence: selectedSection.sequence,
          imageDataUrl: imgData,
        };

        setSections(sortBySequence([...filteredSections, updatedSection]));
      })
      .then(() => {
        toast({
          title: "CMS entry updated",
          description: "Successfully updated CMS entry",
          status: "success",
        });
      })
      .catch((error: AxiosError) => {
        toast({
          title: "Error updating CMS entry",
          description: error.message,
          status: "error",
        });
      });
  };

  const handleDelete = (sec: Section) => {
    deleteCmsEntryAndImageCmsIdDelete(sec.id)
      .then(() => {
        setSections(sections.filter((b) => b.title !== sec.title));
      })
      .then(() => {
        toast({
          title: "CMS entry deleted",
          description: "Successfully deleted CMS entry",
          status: "success",
        });
      })
      .catch((error: AxiosError) => {
        toast({
          title: "Error deleting CMS entry",
          description: error.message,
          status: "error",
        });
      });
  };

  return (
    <VStack spacing="4" maxW={"container.lg"}>
      {sections.map((sec, index) => (
        <TextAndImageSection
          key={index}
          sec={sec}
          canEdit={canEdit}
          onEdit={(sec) => {
            setSelectedSection(sec);
            onOpen();
          }}
        />
      ))}
      {canEdit && (
        <Button mt="4" size={"md"} onClick={onOpen}>
          Add Section
        </Button>
      )}
      {selectedSection && (
        <CMSEditorModal
          content={selectedSection}
          isModalOpen={isOpen}
          onModalClose={() => {
            onModalClose();
            setSelectedSection(null);
          }}
          handleSubmit={handleUpdateSubmit}
          handleDelete={handleDelete}
          isEditing={true}
        />
      )}
      {!selectedSection && (
        <CMSEditorModal
          content={{
            title: "",
            text: "",
            imageDataUrl: "",
            sequence: 0,
            id: "",
          }}
          isModalOpen={isOpen}
          onModalClose={() => {
            onModalClose();
            setSelectedSection(null);
          }}
          handleSubmit={handleSubmit}
          handleDelete={() => {}}
          isEditing={false}
        />
      )}
    </VStack>
  );
};

export default CMS;
