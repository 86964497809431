import { proxy, useSnapshot } from "valtio";
import { RouteMonitorData } from "../types";
import { polylineTemporaryID } from "../components/Map/mapUtils";
import { GeofenceData } from "../http/http";
export class PolylineRouteState {
  routeMonitors: RouteMonitorData[] = [];
  selectedRoute: RouteMonitorData | null = null;

  addRouteMonitor(route: RouteMonitorData) {
    if (
      route !== undefined &&
      route !== null &&
      route.id !== polylineTemporaryID
    ) {
      this.routeMonitors.push(route);
    }
  }

  setRouteMonitors(routes: RouteMonitorData[] | []) {
    if (routes !== undefined && routes !== null) {
      this.routeMonitors = routes;
    }
  }

  setSelectedRoute(route: RouteMonitorData | null) {
    if (this.selectedRoute == route)
      this.selectedRoute = null;
    else
      this.selectedRoute = route;
  }

  setFilteredRouteMonitors(route: RouteMonitorData) {
    if (route !== null) {
      let r = this.routeMonitors.filter((r) => r.id !== route.id);
      this.routeMonitors = r;
    }
  }

  setFilteredRouteMonitorIfGeofenceRemoved(geofence: GeofenceData) {
    let r = this.routeMonitors.filter(
      (r) => r.from.id !== geofence.id && r.to.id !== geofence.id
    );
    this.routeMonitors = r;
  }
}

export const polylineRouteState = proxy(new PolylineRouteState());

export const usePolylineRouteSnap = () => useSnapshot(polylineRouteState);
export const usePolylineRouteState = () => polylineRouteState;
