import { serviceScopeFromString } from "../enum";
import { CheckboxServiceItem, Service } from "../types";

export const servicesToCheckboxItems = (
  services: Service[]
): CheckboxServiceItem[] => {
  return services.map(s => {
    let readScope = getReadScope(s);
    let writeScope = getWriteScope(s);
    let item: CheckboxServiceItem = {
      serviceName: s.title,
      readIsChecked: false,
      readScope: readScope,
      writeIsChecked: writeScope === undefined ? undefined : false,
      writeScope: getWriteScope(s),
    };
    return item;
  });
};

const getReadScope = (service: Service) => {
  const readscope = service.scopes.find(s => s.includes("read")) || "";
  return serviceScopeFromString(readscope);
};

const getWriteScope = (service: Service) => {
  const writescope = service.scopes.find(s => s.includes("write"));
  if (!writescope) return undefined;
  return serviceScopeFromString(writescope);
};
