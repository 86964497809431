import { Box, Button, Text, Image, Flex, Heading } from "@chakra-ui/react";
import { LinkNameAndHref } from "../../types";
import { useNavigate } from "react-router-dom";

type Props = {
  image?: string;
  title: string;
  description?: string;
  navigationLink?: LinkNameAndHref;
  preview?: boolean;
};

export const ServiceBox = ({
  image,
  title,
  description,
  navigationLink,
  preview,
}: Props) => {
  const navigate = useNavigate();

  const onPreview = (link: LinkNameAndHref) => {
    navigate(link.href);
  };

  const previewButtonOrTextComingSoon = () => {
    return (
      <Flex justify="center">
        {preview ? (
          <Button
            onClick={() => navigationLink && onPreview(navigationLink)}
            variant="whiteWithoutBorder"
            size={"small"}
          >
            Preview
          </Button>
        ) : (
          <Heading
            letterSpacing={1}
            fontWeight={"hairline"}
            size={"sm"}
            mt={8}
            pt={"0.1rem"}
            pb={2}
          >
            Coming soon
          </Heading>
        )}
      </Flex>
    );
  };

  return (
    <Box maxW="xs">
      <Image height={"50%"} src={image} />
      <Box
        background={"rgba(193, 189, 191, 0.7)"}
        px={4}
        pb={8}
        pt={6}
        borderBottomWidth="1px"
        borderBottomColor={"rgba(0, 0, 0, 0)"}
        borderBottomRadius="lg"
        overflow="hidden"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      >
        <Box h={14}>
          <Text
            noOfLines={2}
            textAlign={"center"}
            lineHeight={1.1}
            fontSize={"2xl"}
          >
            {title}
          </Text>
        </Box>

        <Box mb={3} h={14}>
          <Text textAlign={"center"} noOfLines={2}>
            {description}
          </Text>
        </Box>
        {previewButtonOrTextComingSoon()}
      </Box>
    </Box>
  );
};
