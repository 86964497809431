import { HStack } from "@chakra-ui/react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { TitleAndBox } from "../EditRoute/TitleAndBox";

type Props = {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
};

export const RouteDatePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: Props) => {
  return (
    <HStack>
      <TitleAndBox
        title="Data from"
        boxContent={
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => date && setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate}
            className="date-picker-grey-border"
          />
        }
      />
      <TitleAndBox
        title="Data to"
        boxContent={
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => date && setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            className="date-picker-grey-border"
          />
        }
      />
    </HStack>
  );
};
