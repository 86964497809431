import { extendTheme } from "@chakra-ui/react";
import "@fontsource/azeret-mono";
import "@fontsource/bebas-neue";
import "@fontsource/roboto";

const fontSizes = {
  xs: "0.75rem",
  sm: "0.875rem",
  // md: 16px
  md: "1rem",
  // lg: 18px
  lg: "1.125rem",
  xl: "1.25rem",
  // "2xl":  = 24px
  "2xl": "1.5rem",
  "3xl": "1.875rem",
  "4xl": "2.25rem",
  "5xl": "2.5rem",
  //"6xl": 48px
  "6xl": "3rem",
  "7xl": "3.75rem",
  "8xl": "4rem",
  "9xl": "4.5rem",
  xxl: "6rem",
  "2xxl": "8rem",
  "3xxl": "9rem",
};

const spacing = {
  space: {
    px: "1px",
    0.5: "0.125rem",
    // 1: = 16px
    1: "0.25rem",
    1.5: "0.375rem",
    2: "0.5rem",
    2.5: "0.625rem",
    3: "0.75rem",
    3.5: "0.875rem",
    4: "1rem",
    // 5: = 20px
    5: "1.25rem",
    // 6: = 24px
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    12: "3rem",
    14: "3.5rem",
    15: "3.75rem",
    // 16: = 48px
    16: "4rem",
    17: "4.25rem",
    18: "4.5rem",
    19: "4.75rem",
    20: "5rem",
    21: "5.25rem",
    22: "5.5rem",
    24: "6rem",
    26: "6.5rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    66: "16.5rem",
    68: "17rem",
    72: "18rem",
    80: "20rem",
    84: "21rem",
    88: "22rem",
    96: "24rem",
    md: "28rem",
    lg: "32rem",
    "2lg": "33rem",
    xl: "36rem",
    "2xl": "42rem",
    "3xl": "48rem",
    "4xl": "56rem",
    "5xl": "64rem",
    "6xl": "72rem",
    "7xl": "80rem",
    "8xl": "90rem",
  },
};

const sizes = {
  sizes: {
    ...spacing.space,
    max: "max-content",
    min: "min-content",
    full: "100%",
    "3xs": "14rem",
    "2xs": "16rem",
    xs: "20rem",
    sm: "24rem",

    container: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
    },
  },
};

const fonts = {
  // body: "CircularStd-Book, Noto Sans JP, sans-serif",
  body: "Roboto",
  heading: "Bebas Neue",

  // heading: "CircularStd-Book, Noto Sans JP, sans-serif",
};

const colors = {
  brand: {
    white: "#FFFFFF",
    black: "#111111",
    darkGrey: "#808080",
    lineGrey: "#DEDEDA",
    red: "#EC3636",
    lightOrange: "#FCBF49",
    darkGreen: "#395B51",
  },

  button: {
    primary: "#395B51",
    secondary: "#111111",
    destructive: "#9E0000",
    white: "#FFFFFF",
    inactive: "#808080",
  },
  border: {
    black: "#111111",
    white: "#FFFFFF",
    lineGrey: "#DEDEDA",
  },
};

export const theme = extendTheme({
  components: {
    Button: {
      sizes: {
        small: {
          // fontSize: "md",
          fontSize: "2xl",

          fontWeight: "400px",
          h: "60px",
          w: "144px",
          borderRadius: "16px",
        },
        long: {
          // fontSize: "md",
          fontSize: "2xl",
          fontWeight: "400px",
          h: "60px",
          w: { md: "md", base: "sm" },
          borderRadius: "16px",
        },
        mediumAndLow: {
          // fontSize: "md",
          fontSize: "2xl",
          fontWeight: "400px",
          h: "50px",
          w: "175px",
          borderRadius: "16px",
        },
        medium: {
          // fontSize: "md",
          fontSize: "2xl",
          fontWeight: "400px",
          h: "60px",
          w: "291px",
          borderRadius: "16px",
        },
        dropDownButtonSmall: {
          fontSize: "md",
          fontWeight: "non",
          h: "40px",
          w: "210px",

          borderRadius: "10px",
        },
      },
      variants: {
        primary: {
          backgroundColor: "button.primary",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

          color: "button.white",
          _hover: {
            filter: " brightness(1.5)",
          },

          _active: {
            transform: "scale(0.98)",
          },
        },
        secondary: {
          backgroundColor: "button.secondary",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          color: "button.white",
          _hover: {
            backgroundColor: "button.white",
            color: "button.secondary",
          },

          _active: {
            filter: " brightness(0.75)",
            transform: "scale(0.98)",
          },
        },
        white: {
          backgroundColor: "button.white",
          borderColor: "border.black",
          borderWidth: "1px",
          _hover: {
            backgroundColor: "button.secondary",
            color: "button.white",
          },

          _active: {
            filter: " brightness(0.98)",
            transform: "scale(0.98)",
            borderColor: "border.lineGrey",
          },
        },
        whiteWithoutBorder: {
          backgroundColor: "button.white",

          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          _hover: {
            backgroundColor: "button.secondary",
            color: "button.white",
          },
          _active: {
            filter: " brightness(0.98)",
            transform: "scale(0.98)",
            borderColor: "border.lineGrey",
          },
        },
        whiteWithUnderLine: {
          color: "button.primary",
          fontWeight: "bold",
          textDecorationLine: "underline",
        },
        inactive: {
          color: "button.inactive",
          _hover: {
            filter: " brightness(1.5)",
            color: "button.primary",
            fontWeight: "bold",
            textDecorationLine: "underline",
          },
        },
        destructive: {
          backgroundColor: "button.destructive",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          color: "button.white",
          _hover: {
            filter: " brightness(1.5)",
          },
          _active: {
            filter: " brightness(0.75)",
            transform: "scale(0.98)",
          },
        },
        clickableText: {
          _active: {
            filter: " brightness(0.75)",
            transform: "scale(0.98)",
          },
        },
        dropDownButton: {
          borderColor: "border.lineGrey",
          borderWidth: "1px",
        },
      },
      defaultProps: {
        size: "small",
        variant: "primary",
      },
    },
  },
  fontSizes,
  fonts,
  colors,
  sizes,
  spacing,
});
