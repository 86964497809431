import { Button, Heading, Spacer } from "@chakra-ui/react";
import { isEmptyString } from "../../../utils/check-utils";
import { useCallback } from "react";
import { LocationInputName } from "./LocationNameInput";
import { DeleteAndEditLocation } from "./DeleteAndEditLocation";
import {
  GeofenceMode,
  useCircleGeofenceSnap,
  useCircleGeofenceState,
} from "../../../state/circle-geofence-state";
import { putTtmGeofenceId } from "../../../services/services";
import { useToastbar } from "../../../hooks/useToastbar";
import { AxiosError } from "axios";
import { GeofenceData, GeofenceName } from "../../../http/http";

type Props = {
  geofence: GeofenceData;
  showAlert: GeofenceData | null;
  value: string;
  setValue: (value: string) => void;
  setShowAlert: (geofence: GeofenceData | null) => void;
};

export const LocationInfoRow = ({
  geofence,
  showAlert,
  value,
  setValue,
  setShowAlert,
}: Props) => {
  const toastbar = useToastbar();
  const onSelectGeofence = useCircleGeofenceState();
  const selectedGeofence = useCircleGeofenceSnap().selectedGeofence;
  const geofenceMode = useCircleGeofenceSnap().geofenceMode;

  const textStyle = useCallback(() => {
    return {
      fontSize: "1.5em",
      color: geofence?.id === selectedGeofence?.id ? "red" : "black",
    };
  }, [selectedGeofence]);

  const onEditGeofenceButtonPress = () => {
    onSelectGeofence.setSelectedGeofence(geofence);
    onSelectGeofence.setOnGeofenceMode(GeofenceMode.Editing);

    if (geofence.name !== value) {
      setValue(!isEmptyString(geofence.name) ? geofence.name : "");
    } else {
      onSelectGeofence.setSelectedGeofence(null);
      onSelectGeofence.setOnGeofenceMode(GeofenceMode.Non);
      setValue("");
    }
  };

  const onEditGeofenceFocusEnd = () => {
    if (geofence.name !== value) {
      const gfName: GeofenceName = { name: value };
      putTtmGeofenceId(geofence.id, gfName)
        .then(() => {
          useCircleGeofenceState().setOnGeofenceMode(null);
          useCircleGeofenceState().setSelectedGeofence(null);
          useCircleGeofenceState().edditGeofence(
            geofence.id,
            gfName.name.toString()
          );
        })
        .catch((error: AxiosError) =>
          toastbar({
            title: "Unable to update geofence name",
            status: "error",
            description: error.message,
          })
        );
    }
    setValue("");
    onSelectGeofence.setSelectedGeofence(null);
  };

  return (
    <>
      {geofence.id === selectedGeofence?.id &&
      geofenceMode === GeofenceMode.Editing ? (
        <LocationInputName
          value={value}
          setValue={setValue}
          onFocusEnd={onEditGeofenceFocusEnd}
        />
      ) : (
        <Button
          onClick={() => onSelectGeofence.setSelectedGeofence(geofence)}
          size={"auto"}
          variant={"clickableText"}
        >
          <Heading maxW={"22rem"} noOfLines={1} style={textStyle()}>
            {geofence.name}
          </Heading>
        </Button>
      )}
      <Spacer></Spacer>
      <DeleteAndEditLocation
        geofence={geofence}
        setShowAlert={setShowAlert}
        showAlert={showAlert}
        onEditGeofence={() => onEditGeofenceButtonPress()}
      />
    </>
  );
};
