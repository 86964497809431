export enum GeofenceSelectionType {
  Locations = "Locations",
  Routes = "Routes",
}

export enum ServiceScopes {
  None = "none",
  ReadTtm = "read_ttm",
  WriteTtm = "write_ttm",
  ReadRGService = "read_rg_service",
  ReadADClearance = "read_ad_clearance",
  ReadSafetyGraph = "read_safety_graph",
  ReadLocationData = "read_location_data",
  ReadRoadSignInfo = "read_road_sign_info",
  WriteRoadSignInfo = "write_road_sign_info",
  ReadSafeSpeedInfo = "read_safe_speed_info",
  WriteSafeSpeedInfo = "write_safe_speed_info",
  ReadAccidentAnalysis = "read_accident_analysis",
  WriteAccidentAnalysis = "write_accident_analysis",
  ReadTrafficManagement = "read_traffic_management",
  ReadGeneralMapService = "read_general_map_service",
  ReadAccidentRiskAlert = "read_accident_risk_alert",
  WriteAccidentRiskAlert = "write_accident_risk_alert",
  ReadAccidentRiskEstimation = "read_accident_risk_estimation",
  WriteAccidentRiskEstimation = "write_accident_risk_estimation",
}

export const serviceScopeFromString = (scope: string): ServiceScopes => {
  switch (scope) {
    case "read_ttm":
      return ServiceScopes.ReadTtm;
    case "write_ttm":
      return ServiceScopes.WriteTtm;
    case "read_rg_service":
      return ServiceScopes.ReadRGService;
    case "read_ad_clearance":
      return ServiceScopes.ReadADClearance;
    case "read_safety_graph":
      return ServiceScopes.ReadSafetyGraph;
    case "read_location_data":
      return ServiceScopes.ReadLocationData;
    case "read_road_sign_info":
      return ServiceScopes.ReadRoadSignInfo;
    case "write_road_sign_info":
      return ServiceScopes.WriteRoadSignInfo;
    case "read_safe_speed_info":
      return ServiceScopes.ReadSafeSpeedInfo;
    case "write_safe_speed_info":
      return ServiceScopes.WriteSafeSpeedInfo;
    case "read_accident_analysis":
      return ServiceScopes.ReadAccidentAnalysis;
    case "write_accident_analysis":
      return ServiceScopes.WriteAccidentAnalysis;
    case "read_traffic_management":
      return ServiceScopes.ReadTrafficManagement;
    case "read_general_map_service":
      return ServiceScopes.ReadGeneralMapService;
    case "read_accident_risk_alert":
      return ServiceScopes.ReadAccidentRiskAlert;
    case "write_accident_risk_alert":
      return ServiceScopes.WriteAccidentRiskAlert;
    case "read_accident_risk_estimation":
      return ServiceScopes.ReadAccidentRiskEstimation;
    case "write_accident_risk_estimation":
      return ServiceScopes.WriteAccidentRiskEstimation;
    default:
      return ServiceScopes.None;
  }
};

export enum RoleScopes {
  ReadMe = "read_me",
  WriteMe = "write_me",
  ReadUser = "read_user",
  WriteUser = "write_user",
  ReadScope = "read_scope",
  ReadAdmin = "read_admin",
  WriteAdmin = "write_admin",
  SuperAdmin = "super_admin",
  ReadService = "read_service",
  ReadSubscribe = "read_subscribe",
  WriteSubscribe = "write_subscribe",
  ReadComapnyInfo = "read_company_info",
  WriteCompanyInfo = "write_company_info",
}

export enum RoleSelection {
  CompanyAdmin = 2,
  CompanyUser = 3,
}
