import { VStack, Box, Button, HStack, Heading } from "@chakra-ui/react";
import { useState } from "react";
import { services } from "../../components/ServiceBox/services";
import { CMSButton } from "../../components/CMS/CMSButton";
import CMS from "../../components/CMS/CMS";

export const SafetyGraph = () => {
  const openSafetyGraph = (url: string, w: number, h: number) => {
    window.open(url, "Safety Graph", `popup,  width=${w}, height=${h}`);
  };
  const [showCms, setShowCms] = useState(false);
  const serviceUid = services.find((s) => s.title === "Safety Graph")?.id || 0;

  return (
    <VStack maxWidth={"container.xl"} w="full" margin={10}>
      <HStack alignItems={"center"}>
        <Heading lineHeight="normal">SAFETY GRAPH</Heading>
        <CMSButton
          buttonText={showCms ? "Return" : "About"}
          variant={showCms ? undefined : "white"}
          onClick={() => setShowCms(!showCms)}
        />
      </HStack>
      {showCms && <CMS serviceUid={serviceUid} />}
      {!showCms && (
        <VStack padding={100}>
          <Box>
            <Button
              onClick={() =>
                openSafetyGraph("https://www.safetygraph.org/", 1500, 800)
              }
              size={"medium"}
            >
              Open Safety Graph
            </Button>
          </Box>
        </VStack>
      )}
    </VStack>
  );
};
