import { useEffect, useState } from "react";
import {
  BoundingBox,
  DataExplorationMapData,
  MapFilters,
} from "../../components/GenericMap/DataExplorationMapDataTypes";
import { GeographicalDataExploration } from "./GeographicalDataExploration";
import {
  getLocationdataDataset,
  getLocationdataDatasetName,
} from "../../services/services";
import { AxiosError } from "axios";
import { useToastbar } from "../../hooks/useToastbar";
import { GetLocationdataDatasetNameQueryParams } from "../../services/types";
import { Config } from "../../config";

export const GeographicalDataExplorationContainer: React.FC = () => {
  const [mapData, setMapData] = useState<DataExplorationMapData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const toastbar = useToastbar();
  const markerColours: string[] = [
    "#fa6607", // orange
    "#057ef7", // dark blue
    "#098203", // dark green
    "#a202c9", // purple
    "#fa2a2a", // red
    "#2afab8", // turqoise
    "#2aecfa", // light blue
    "#92fa82", // light green
  ];

  const euUrl = Config.eu_url;
  const usUrl = Config.us_url;

  useEffect(() => {
    setIsLoading(true);
    Promise.all(
      [euUrl, usUrl].map((url) => {
        return getLocationdataDataset({ baseURL: url })
          .then((response) => {
            const dataSets = response.datasets.map((ds, i) => {
              const gmd: DataExplorationMapData = {
                dataSetInfo: ds,
                color: markerColours[i] || "#000000",
                elements: [],
                baseUrl: url,
              };
              return gmd;
            });
            setMapData((prev) => [...prev, ...dataSets]);
          })
          .catch((error: AxiosError) => {
            const desc = error.response
              ? `${error.response?.status} ${error.response?.statusText}`
              : JSON.stringify(error.message);
            toastbar({
              title: "Unable to load datasets",
              description: desc,
              status: "error",
            });
          });
      })
    ).finally(() => setIsLoading(false));
  }, []);

  const loadMapData = (
    selectedDataSetTitles: string[],
    boundingBox: BoundingBox,
    filter: MapFilters
  ): void => {
    setIsLoading(true);
    const mapDataCopy = mapData;
    Promise.all(
      mapDataCopy.map((md) => {
        if (selectedDataSetTitles.includes(md.dataSetInfo.name)) {
          const params: GetLocationdataDatasetNameQueryParams = {
            sw_lat: boundingBox.lowerLeft.lat,
            sw_lon: boundingBox.lowerLeft.lng,
            ne_lat: boundingBox.upperRight.lat,
            ne_lon: boundingBox.upperRight.lng,
            start_unixtime: filter.start?.valueOf(),
            end_unixtime: filter.end?.valueOf(),
            num_samples: filter.maxDataCount,
          };
          return getLocationdataDatasetName(md.dataSetInfo.name, params, {
            baseURL: md.baseUrl,
          }).then((dataSetDataList) => {
            md.elements = dataSetDataList;
          });
        } else if (
          !selectedDataSetTitles.includes(md.dataSetInfo.name) &&
          md.elements.length > 0
        ) {
          md.elements = []; // Remove elements from deselected data sets
          return Promise.resolve();
        } else {
          return Promise.resolve();
        }
      })
    )
      .then(() => {
        setMapData(mapDataCopy);
      })
      .catch((error: AxiosError) => {
        const desc = error.response
          ? `${error.response?.status} ${error.response?.statusText}`
          : JSON.stringify(error.message);
        toastbar({
          title: "Unable to load data",
          description: desc,
          status: "error",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <GeographicalDataExploration
        dataSets={mapData}
        isLoading={isLoading}
        loadDataSetData={loadMapData}
      />
    </>
  );
};
