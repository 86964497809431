import { Heading, VStack, Text, HStack } from "@chakra-ui/react";
import { LatLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useState } from "react";
import { services } from "../../components/ServiceBox/services";
import { CMSButton } from "../../components/CMS/CMSButton";
import CMS from "../../components/CMS/CMS";
import { SinglePointResponse } from "../../http/http";
import { SinglePointMap } from "./SinglePointMap";

type MapMatchingServiceProps = {
  mapMatchSinglePoint: (
    point: LatLng,
    radius: number
  ) => Promise<SinglePointResponse>;
};

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export const MapMatchingService: React.FC<MapMatchingServiceProps> = ({
  mapMatchSinglePoint,
}) => {
  const [showCms, setShowCms] = useState(false);
  const serviceUid =
    services.find((s) => s.title === "Reverse Geocoding Service")?.id || 0;

  return (
    <VStack w="full" margin={10}>
      <HStack alignItems={"center"}>
        <Heading>REVERSE GEOCODING SERVICE</Heading>
        <CMSButton
          buttonText={showCms ? "Return" : "About"}
          variant={showCms ? undefined : "white"}
          onClick={() => setShowCms(!showCms)}
        />
      </HStack>
      <Text textAlign={"center"} pb={5}>
        Find road data attributes such as speed limits, curvature, road class
        and more from WGS84 position. <br /> Select start and end position by
        clicking on the map, add optional radius and click "Submit".
      </Text>
      {showCms && <CMS serviceUid={serviceUid} />}
      {!showCms && (
        <SinglePointMap mapMatchPointAndRadius={mapMatchSinglePoint} />
      )}
    </VStack>
  );
};
