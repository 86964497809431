import { Input, VStack, Text } from "@chakra-ui/react";

import { isEmptyString } from "../../../utils/check-utils";

type Props = {
  onFocusEnd: () => void;
  value: string;
  setValue: (value: string) => void;
};

export const LocationInputName = ({ onFocusEnd, value, setValue }: Props) => {
  const handleChange = (event: any) => setValue(event.target.value);
  return (
    <VStack spacing={0}>
      <Input
        borderColor={isEmptyString(value) ? "red" : "black"}
        onBlur={onFocusEnd}
        onChange={handleChange}
        value={value}
      />
      {isEmptyString(value) && (
        <Text color={"brand.red"}>Give a name to geofence</Text>
      )}
    </VStack>
  );
};
