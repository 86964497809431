export const isEmptyString = (value: string) => {
  if (value !== " " && value.trim() && value !== undefined) {
    return false;
  } else return true;
};

export const charToDots = (word: string, amountOfChar: number) => {
  if (word.length > amountOfChar) {
    return word.substring(0, amountOfChar).trimEnd() + "...";
  } else {
    return word;
  }
};
