import {
  Spinner,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  HStack,
} from "@chakra-ui/react";

type LoadingOverlayProps = { isOpen: boolean; onClose: () => void };

// Overlay component
export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <HStack>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor={"brand.lineGrey"}
              color={"brand.darkGreen"}
              size="xl"
            />
            <Text fontSize={"2xl"} color={"brand.green"}>
              Loading data...
            </Text>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
