import { Service, User } from "../../types";
import { services } from "../ServiceBox/services";

export const getFilteredServices = (user: User | undefined): Service[] => {
  if (!user) {
    return [];
  }
  return services.filter((service) =>
    // service scopes has at least one scope that is listed in 'scopes'
    service.scopes.some((serviceScope) =>
      user.serviceScopes.includes(serviceScope)
    )
  );
};
