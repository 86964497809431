import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RequestPasswordInput } from "../../types";

const defaultValues = {
  email: "",
};

type Props = {
  onSubmit: ({ email }: RequestPasswordInput) => void;
};

const schema: yup.SchemaOf<RequestPasswordInput> = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Must be a valid email")
      .required("You must specify an email"),
  })
  .defined();

export const ResetPassword = ({ onSubmit }: Props) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<RequestPasswordInput>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  return (
    <Flex mb={28} mt={16} flexDirection="column" justify={"center"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <Heading>Reset Password</Heading>
          <Text>
            Submit your email address to request an email with instructions to
            create a new password.
          </Text>
          <Box w={{ md: "md", base: "sm" }}>
            <Text>Email</Text>
            <Input type="email" {...register("email")} size="sm" />
            <Text color={"brand.red"}> {errors?.email?.message}</Text>
          </Box>
          <Button
            isLoading={isSubmitting}
            type="submit"
            size={"long"}
            variant="primary"
          >
            Request new password
          </Button>
        </VStack>
      </form>
    </Flex>
  );
};
