import { LinkNameAndHref } from "../../types";
import { HStack, Link, Text } from "@chakra-ui/react";

type LinkProps = {
  link: LinkNameAndHref;
  onClose?: () => void;
};

type PreviewLinksProps = {
  links: Array<LinkNameAndHref>;
  onClose: () => void;
};

export const HeaderLink = ({ link }: LinkProps) => {
  return (
    <Link mr={10} mt={4} color={"brand.black"} size="md" href={link.href}>
      {link.linkName}
    </Link>
  );
};

export const HeaderLinksPreview = ({ links }: PreviewLinksProps) => {
  return (
    <>
      {links.map((link, i) => (
        <HStack key={i} justify={"space-between"}>
          <HeaderLink link={link} />
          <Text
            pt={5}
            fontFamily={"Bebas Neue"}
            color={"brand.darkGreen"}
            mr={10}
          >
            PREVIEW
          </Text>
        </HStack>
      ))}
    </>
  );
};
