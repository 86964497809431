import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { TotpInput } from "../../types";

type MFAVerificationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (totp: TotpInput) => Promise<void>;
};

const defaultValues = {
  authenticatorCode: "",
};

const schema: yup.SchemaOf<TotpInput> = yup
  .object()
  .shape({
    authenticatorCode: yup
      .string()
      .required("You must enter an authenticator code"),
  })
  .defined();

export const MFAVerificationModal: React.FC<MFAVerificationModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<TotpInput>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalHeader>MFA Verification</ModalHeader>
      <ModalCloseButton />
      <ModalContent>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={5}>
              <Text>Enter Authenticator Code</Text>
              <Input
                id="authenticatorCode"
                type="string"
                size="sm"
                {...register("authenticatorCode")}
              />
              <Text color={"brand.red"}>
                {errors?.authenticatorCode?.message}
              </Text>
              <HStack>
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  size={"mediumAndLow"}
                  variant="primary"
                >
                  Submit
                </Button>
                <Button onClick={onClose} size={"mediumAndLow"} variant="white">
                  Cancel
                </Button>
              </HStack>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
