import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToastbar } from "../../hooks/useToastbar";
import { useUser } from "../../services/user.context";
import { LoginInput, TotpInput } from "../../types";
import { LogIn } from "./LogIn";
import {
  getLogin,
  getMeId,
  postLogin,
  postLoginMfaVerify,
} from "../../services/services";
import { AxiosError } from "axios";

export const LogInContainer = () => {
  const toastbar = useToastbar();
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [loginInfo, setLoginInfo] = useState<LoginInput>({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (user) {
      navigate("/start");
    }
  }, []);

  const onLoginSubmit = async ({ email, password }: LoginInput) => {
    await postLogin({ username: email, password })
      .then((response) => {
        if (response.mfa_active !== true) {
          toastbar({
            title: "MFA Link Sent",
            description:
              "A link to setup multi-factor authentication has been sent to your email address",
            status: "info",
            duration: 30000,
          });
          navigate("/");
        } else {
          setLoginInfo({ email, password });
          return Promise.resolve();
        }
      })
      .catch((error) => {
        let description: string = error.message ? error.message : "";
        if (error instanceof AxiosError) {
          description = error.response
            ? `${error.response.status} ${error.response.statusText}`
            : "Network error";
        }
        toastbar({
          title: "Failed to log in",
          status: "error",
          description: description,
        });
        return Promise.reject();
      });
  };

  const onMfaVerifySubmit = (totp: TotpInput): Promise<void> => {
    return postLoginMfaVerify({
      username: loginInfo.email,
      password: loginInfo.password,
      user_totp: totp.authenticatorCode,
    })
      .then(async (mfaVerifyResponse) => {
        const loginData = await getLogin({
          headers: {
            Authorization: `Bearer ${mfaVerifyResponse.access_token}`,
          },
        });

        const meData = await getMeId(loginData.user_id, {
          headers: {
            Authorization: `Bearer ${mfaVerifyResponse.access_token}`,
          },
        });

        setUser({
          id: loginData.user_id,
          companyId: loginData.company_id,
          roleId: loginData.role_id,
          roleScopes: loginData.role_scopes,
          serviceScopes: loginData.custom_scopes,
          authToken: mfaVerifyResponse.access_token,
          name: meData.name,
          email: meData.email,
          companyName: meData.company_name,
          hasMfaSetup: meData.hasMfaSetup,
        });
      })
      .then(() => {
        navigate("/start");
      })
      .catch((error) => {
        let description: string = error.message ? error.message : "";
        if (error instanceof AxiosError) {
          description = error.response
            ? `${error.response.status} ${error.response.statusText}`
            : "Network error";
        }
        toastbar({
          title: "Failed to log in",
          status: "error",
          description: description,
        });
      });
  };

  return (
    <>
      <LogIn
        onLoginSubmit={onLoginSubmit}
        onMfaVerifySubmit={onMfaVerifySubmit}
      />
    </>
  );
};
