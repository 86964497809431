import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  useOutsideClick,
} from "@chakra-ui/react";
import { useRef } from "react";

import { useNavigate } from "react-router-dom";
import { RoleScopes } from "../../enum";
import { useUser } from "../../services/user.context";
import { LinkNameAndHref } from "../../types";
import { logIn, manageUsers, startLink } from "../../utils/navigationLinks";
import { getFilteredServices } from "../Authentication/FilterServices";
import { HeaderLink, HeaderLinksPreview } from "./HeaderLinks";

type Prop = {
  isOpen: boolean;
  setIsOpen: () => void;
};

export const Menu = ({ isOpen, setIsOpen }: Prop) => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  const availableServices = user ? getFilteredServices(user) : [];
  const menuLinks = availableServices.map((service) => {
    return service.navigationLink as LinkNameAndHref;
  });
  const ref = useRef<HTMLInputElement>(null);
  const isAdmin =
    (user?.roleScopes?.includes(
      RoleScopes.ReadAdmin || RoleScopes.WriteAdmin
    ) &&
      user.roleId < 3) ||
    false;

  useOutsideClick({
    ref: ref,
    handler: setIsOpen,
  });

  const links = () => {
    return (
      <SimpleGrid
        ref={ref}
        pl={5}
        borderLeftWidth={1}
        borderLeftColor="brand.white"
        ml={5}
        position={"relative"}
        zIndex={2}
      >
        <Flex direction={"column"}>
          <HeaderLink onClose={setIsOpen} link={startLink} />
          <HeaderLinksPreview onClose={setIsOpen} links={menuLinks} />
          {/* <HeaderLink onClose={setIsOpen} link={termsAndConditions} />
            <HeaderLink onClose={setIsOpen} link={privacyAndPolicy} /> */}
          {isAdmin && <HeaderLink onClose={setIsOpen} link={manageUsers} />}
          {!user && <HeaderLink onClose={setIsOpen} link={logIn} />}
          {user && (
            <Button
              size={"tiny"}
              width={"6em"}
              onClick={() => {
                setUser(undefined);
                navigate("/login");
                setIsOpen();
              }}
            >
              Log out
            </Button>
          )}
        </Flex>
      </SimpleGrid>
    );
  };

  return (
    <Box
      zIndex="popover"
      pos="absolute"
      py={5}
      pr={5}
      right={10}
      top={isOpen ? "55" : "-350px"}
      boxShadow={isOpen ? "lg" : "none"}
      transition="0.6s ease"
      bg="#fff"
    >
      {links()}
    </Box>
  );
};
