import {
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useToastbar } from "../../hooks/useToastbar";
import { RouteMonitorData, RouteMonitorDataFilter } from "../../types";
import { PencilIcon, TrashIcon } from "../../assets/icons/icon";
import { AxiosError } from "axios";
import { EditRoute } from "../EditRoute/EditRoute";
import { useDeleteRouteMonitor } from "./polylinesAndCircles/routes/functions/useDeleteRouteMonitor";

type EditColumnProps = {
  onSave: (
    route: RouteMonitorData,
    title: string,
    filters: RouteMonitorDataFilter | undefined
  ) => Promise<void>;
  route: RouteMonitorData;
  identifier: number;
  getGeofenceName: (gfId: string) => string;
};

export const EditColumn = ({
  onSave,
  route,
  identifier,
  getGeofenceName,
}: EditColumnProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteRouteMonitor } = useDeleteRouteMonitor();
  const toastbar = useToastbar();

  return (
    <HStack className="editingColumnInRoutes">
      <IconButton
        aria-label="Edit"
        size={"xs"}
        onClick={onOpen}
        backgroundColor="transparent"
        boxShadow="none"
        icon={<PencilIcon />}
      />
      <IconButton
        size={"xs"}
        onClick={async () => {
          await deleteRouteMonitor(route).catch((err: AxiosError) =>
            toastbar({
              status: "error",
              title: "Unable to delete route",
              description: `${err.response?.status} ${err.response?.statusText}`,
            })
          );
        }}
        backgroundColor="transparent"
        icon={<TrashIcon />}
        aria-label="Delete"
        boxShadow="none"
      />
      <Modal isOpen={isOpen} onClose={onClose} key={identifier}>
        <ModalOverlay />
        <ModalContent px={10} py={10} borderRadius={5} borderWidth={2}>
          <ModalBody>
            <EditRoute
              onClose={onClose}
              onSave={async (title, filter) => {
                await onSave(route, title, filter)
                  .then(() => onClose())
                  .catch((err: AxiosError) =>
                    toastbar({
                      status: "error",
                      title: "Unable to save",
                      description: `${err.response?.status} ${err.response?.statusText}`,
                    })
                  );
              }}
              routeInfo={route}
              getGeofenceName={getGeofenceName}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </HStack>
  );
};
