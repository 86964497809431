import { Heading, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { DeleteAndEditLocation } from "./DeleteAndEditLocation";
import { circleTemporaryID } from "../mapUtils";
import { LocationInfoRow } from "./LocationInfoRow";
import { LocationInputName } from "./LocationNameInput";
import {
  useCircleGeofenceState,
  circleGeofenceState,
} from "../../../state/circle-geofence-state";
import { useAddGeofence } from "../polylinesAndCircles/geofences/functions/useAddGeofence";
import { GeofenceData } from "../../../http/http";

type Props = {
  geofences: Array<GeofenceData>;
};

export const Locations = ({ geofences = [] }: Props) => {
  const [value, setValue] = useState("");
  const [showAlert, setShowAlert] = useState<GeofenceData | null>(null);
  const selectedGeofence = circleGeofenceState.selectedGeofence;
  const { addGeofence } = useAddGeofence();

  const makingNewCircle = useCallback(() => {
    if (selectedGeofence?.id === circleTemporaryID) {
      useCircleGeofenceState().changeGeofenceName(value);
      return (
        <HStack>
          <LocationInputName
            value={value}
            setValue={setValue}
            onFocusEnd={() => onAddNameForNewGeofenceonFocusEnd()}
          />
          <Spacer></Spacer>
          <DeleteAndEditLocation
            geofence={selectedGeofence}
            setShowAlert={setShowAlert}
            showAlert={showAlert}
          />
        </HStack>
      );
    }
  }, [selectedGeofence, value]);

  const onAddNameForNewGeofenceonFocusEnd = () => {
    if (selectedGeofence) {
      addGeofence(selectedGeofence);
    }
    setValue("");
  };

  return (
    <VStack alignItems={"start"}>
      {makingNewCircle()}
      <Heading
        color={"brand.white"}
        size="sm"
        backgroundColor={"brand.darkGreen"}
        borderRadius={5}
        py={4}
        pl={9}
        letterSpacing={1.5}
        minWidth={"30rem"}
      >
        Location
      </Heading>
      {geofences.map((geofence, i) => (
        <HStack
          minWidth={"30rem"}
          borderTopWidth={i === 0 ? 1 : 0}
          borderBottomWidth={1}
          borderBottomColor={"border.lineGrey"}
          py={3}
          key={i}
        >
          <Text
            px={2}
            mb={1}
            color={"brand.white"}
            borderRadius={20}
            bg={"brand.black"}
          >
            {i + 1}
          </Text>
          <LocationInfoRow
            setValue={(value) => setValue(value)}
            value={value}
            showAlert={showAlert}
            geofence={geofence}
            setShowAlert={setShowAlert}
          />
        </HStack>
      ))}
    </VStack>
  );
};
