import {
  Stack,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  HStack,
  Text,
  Button,
} from "@chakra-ui/react";
import {
  getTtmMonitorIdStatistics,
  putTtmMonitorId,
} from "../../services/services";

import { RouteMonitorData, RouteMonitorDataFilter } from "../../types";
import { convertMinutesToSeconds } from "../../utils/time-utils";
import { RouteName } from "../RouteName";
import {
  convertKmToMeters,
  convertMetersToKM,
  convertSecondsToHHMM,
} from "./mapUtils";
import { EditColumn } from "./EditColumn";
import { usePolylineRouteState } from "../../state/polyline-route-state";
import { Fragment } from "react";

type RoutesProps = {
  routeMonitors: Array<RouteMonitorData>;
  geofenceElements: Array<JSX.Element>;
  setGeofenceElements: (geofenceElements: Array<JSX.Element>) => void;
  createGeofenceCirclesWithColor: (
    gfId: string,
    color: "red" | "black"
  ) => JSX.Element | null;
  getGeofenceName: (gfId: string) => string;
};

export const Routes = ({
  routeMonitors = [],
  geofenceElements = [],
  setGeofenceElements,
  createGeofenceCirclesWithColor,
  getGeofenceName,
}: RoutesProps) => {
  const onSelectRoute = usePolylineRouteState();

  const concatList = (list: string[] | undefined) => {
    if (list) {
      return list.join(",");
    } else {
      return "";
    }
  };

  const onSave = async (
    route: RouteMonitorData,
    title: string,
    filters: RouteMonitorDataFilter | undefined
  ) => {
    if (title !== "" && title !== route.title) {
      route.title = await putTtmMonitorId(route.id, { name: title }).then(
        (r) => {
          return r.name;
        }
      );
    }
    if (filters) {
      route.filter = filters;
      route.travelTimeStats = await getTtmMonitorIdStatistics(route.id, {
        start_time: filters.startTime || 0,
        end_time: filters.endTime,
        max_drive_time: filters.maxDriveTime
          ? convertMinutesToSeconds(filters.maxDriveTime)
          : undefined,
        max_distance: filters.maxDriveDistance
          ? convertKmToMeters(filters.maxDriveDistance)
          : undefined,
        via_gfs: concatList(filters.hasToTravelViaIds),
      });
    }

    usePolylineRouteState().setRouteMonitors(
      routeMonitors.map((r) => {
        if (r.id === route.id) {
          return route;
        } else {
          return r;
        }
      })
    );
  };

  const routeTitle = (route: RouteMonitorData, i: number) => {
    return (
      <HStack className="nameColumnInRoutes">
        <Text px={2} color={"brand.white"} borderRadius={20} bg={"brand.black"}>
          {i + 1}
        </Text>
        <RouteName maxW={"230px"} route={route} />
      </HStack>
    );
  };

  const travelCount = (count?: number) => {
    return (
      <Stack className="timeColumnInRoutes">
        <Text pl={1}>{count || 0}</Text>
      </Stack>
    );
  };

  const avgDist = (dist?: number) => {
    return (
      <Stack className="avgDistColumnInRoutes">
        <Text pl={2}>{convertMetersToKM(dist || 0)} km</Text>
      </Stack>
    );
  };

  const slowestAndFastest = (time?: number) => {
    return (
      <Stack className="slowestAndFastestColumnInRoutes">
        <Text pl={1}>{convertSecondsToHHMM(time || 0)}</Text>
      </Stack>
    );
  };

  return (
    <>
      {routeMonitors.length === 0 && (
        <Text>
          Create routes by clicking on geofences in the map. Once created, you
          can monitor their stats here.
        </Text>
      )}
      {routeMonitors.length > 0 && (
        <VStack>
          <TableContainer rounded="5">
            <Table variant={"simple"}>
              <TableCaption>Travel Time Statistics</TableCaption>
              <Thead
                rounded="10"
                backgroundColor={"brand.darkGreen"}
                borderRadius={5}
              >
                <Tr mb={-2} fontFamily={"Bebas Neue"}>
                  <Th>
                    <Text fontSize={"2xl"} color={"brand.white"}>
                      ROUTE
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize={"2xl"} color={"brand.white"}>
                      Via points
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize={"2xl"} color={"brand.white"}>
                      Travel Count
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize={"2xl"} color={"brand.white"}>
                      Avg Distance
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize={"2xl"} color={"brand.white"}>
                      Median Distance
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize={"2xl"} color={"brand.white"}>
                      Avg Drive Time
                    </Text>
                  </Th>
                  <Th>
                    <Text fontSize={"2xl"} color={"brand.white"}>
                      Median Drive Time
                    </Text>
                  </Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {routeMonitors.map((route, i) => {

                  let primRow =
                    <Tr key={"prim" + i}>
                      <Td>
                        <Button
                          onClick={() => onSelectRoute.setSelectedRoute(route)}
                          size={"auto"}
                          variant={"clickableText"}
                        >
                          {routeTitle(route, i)}
                        </Button>
                      </Td>
                      <Td>{route.via?.length || 0}</Td>
                      <Td>{travelCount(route.travelTimeStats?.count_travels)}</Td>
                      <Td>
                        {avgDist(route.travelTimeStats?.drive_distance?.mean)}
                      </Td>
                      <Td>
                        {avgDist(route.travelTimeStats?.drive_distance?.median)}
                      </Td>
                      <Td>
                        {slowestAndFastest(
                          route.travelTimeStats?.drive_time?.mean
                        )}
                      </Td>
                      <Td>
                        {slowestAndFastest(
                          route.travelTimeStats?.drive_time?.median
                        )}
                      </Td>
                      <Td>
                        <EditColumn
                          route={route}
                          identifier={i}
                          onSave={onSave}
                          getGeofenceName={getGeofenceName}
                        />
                      </Td>
                    </Tr>


                  let trRows = [primRow];
                  if (onSelectRoute?.selectedRoute?.id === route.id) {
                    route.travelTimeStats?.routes?.forEach((route, i) => {
                      let stats = route.stats;
                      let key = route.gfs.reduce((acc, gf) => acc + gf, "");
                      trRows.push(
                        (
                          <Tr key={key}>
                            <Td>via <b>{route.name}</b></Td>
                            <Td></Td>
                            <Td>{travelCount(stats.count_travels)}</Td>
                            <Td>
                              {avgDist(stats.drive_distance?.mean)}
                            </Td>
                            <Td>
                              {avgDist(stats.drive_distance?.median)}
                            </Td>
                            <Td>
                              {slowestAndFastest(
                                stats.drive_time?.mean
                              )}
                            </Td>
                            <Td>
                              {slowestAndFastest(
                                stats.drive_time?.median
                              )}
                            </Td>
                            <Td></Td>
                          </Tr>
                        )
                      )
                    });
                  }

                  return (
                    <Fragment key={i}>
                      {trRows}
                    </Fragment>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
      )}
    </>
  );
};
