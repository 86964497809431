import { LatLng } from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";

type BoundingBoxHandlerProps = {
  onMapMoveEnd: (lowerLeft: LatLng, upperRight: LatLng) => void;
};

export const BoundingBoxHandler: React.FC<BoundingBoxHandlerProps> = ({
  onMapMoveEnd,
}) => {
  const map = useMap();
  const handleMapMoveEnd = () => {
    const bounds = map.getBounds();
    const upperRight = bounds.getNorthEast();
    const lowerLeft = bounds.getSouthWest();
    onMapMoveEnd(lowerLeft, upperRight);
  };

  useEffect(() => {
    map.on("moveend", handleMapMoveEnd);

    return () => {
      // Clean up event listener when the component unmounts
      map.off("moveend", handleMapMoveEnd);
    };
  }, [map]);

  return null;
};
