import React, { useState } from "react";
import { Checkbox, VStack } from "@chakra-ui/react";

interface ViaPointsCheckboxFilterProps {
  viaPointIds: string[];
  onIdSelect: (id: string) => void;
  onIdDeselect: (id: string) => void;
  getGeofenceName: (gfId: string) => string;
  checkedViaPoints: string[];
}

const ViaPointsCheckboxFilter: React.FC<ViaPointsCheckboxFilterProps> = ({
  viaPointIds,
  onIdSelect,
  onIdDeselect,
  getGeofenceName,
  checkedViaPoints = [],
}) => {
  const handleCheck =
    (item: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        onIdSelect(item);
      } else {
        onIdDeselect(item);
      }
    };

  return (
    <VStack spacing={2} alignItems={"start"}>
      {viaPointIds.map((id, index) => (
        <Checkbox
          key={index}
          onChange={handleCheck(id)}
          defaultChecked={checkedViaPoints.includes(id)}
        >
          {getGeofenceName(id)}
        </Checkbox>
      ))}
    </VStack>
  );
};

export default ViaPointsCheckboxFilter;
