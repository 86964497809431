import { useCallback } from "react";
import { RouteMonitorData } from "../../../../../types";
import { GetTtmMonitorIdStatisticsQueryParams } from "../../../../../services/types";
import { getUnixMilliseconds1WeekBack } from "../../../../../utils/time-utils";
import { getTtmMonitorIdStatistics } from "../../../../../services/services";
import { createRouteMonitorObject } from "../../../mapUtils";
import {
  usePolylineRouteState,
  polylineRouteState,
} from "../../../../../state/polyline-route-state";

export const useGetRouteMonitorStats = () => {
  const setRouteMonitorsState = usePolylineRouteState();

  const getRouteMonitorStats = useCallback(
    async (routeId: string, rmObj: RouteMonitorData) => {
      const queryParams: GetTtmMonitorIdStatisticsQueryParams = {
        start_time: getUnixMilliseconds1WeekBack(),
      };

      let tempArray = polylineRouteState.routeMonitors;
      if (rmObj !== undefined) {
        await getTtmMonitorIdStatistics(routeId, queryParams)
          .then((stats) => {
            let temObject: RouteMonitorData = createRouteMonitorObject(
              rmObj.title,
              routeId,
              rmObj.from,
              rmObj.to,
              rmObj.via ?? [],
              stats
            );
            tempArray.push(temObject);
          })
          .then(() => {
            setRouteMonitorsState.setRouteMonitors(tempArray);
          });
      }
    },
    []
  );

  return { getRouteMonitorStats };
};
