import { createContext, useContext, useEffect, useState } from "react";
import { User } from "../types";
import localstorageService from "./localstorage.service";

type UserContextProps = {
  user: User | undefined;
  setUser: (user: User | undefined) => void;
};

const UserContext = createContext<UserContextProps | undefined>(undefined);

function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | undefined>(
    localstorageService.getUser() || undefined
  );

  // Persist user in localstorage
  useEffect(() => {
    // Don't bother if already in localstorage
    if (user?.id === localstorageService.getUser()?.id) return;

    if (!user) localstorageService.clearUser();
    else localstorageService.setUser(user);
  }, [user]);

  const value = { user, setUser };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUser };
