import { Box, VStack, Text, HStack, Divider } from "@chakra-ui/react";
import { FeatureCollection } from "../../http/http";

type FeatureCollectionDataBoxProps = {
  collection: FeatureCollection | null;
};

export const FeatureCollectionDataBox: React.FC<
  FeatureCollectionDataBoxProps
> = ({ collection }) => {
  // arrow function to convert the collection to a formatted JSON
  const formatCollection = (fc: FeatureCollection) => {
    return JSON.stringify(fc, null, 2);
  };

  return (
    <Box borderWidth="1px" maxHeight="600px" overflow="auto">
      <VStack spacing={5} alignItems={"center"} padding={5}>
        {collection !== null && (
          <>
            <Text as={"b"}>Results</Text>
            <Divider />
            <Text as="samp">
              <pre>{formatCollection(collection)}</pre>
            </Text>
          </>
        )}
        {collection === null && (
          <>
            <Text>Choose position on map and click Submit to load data</Text>
          </>
        )}
      </VStack>
    </Box>
  );
};
