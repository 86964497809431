import { Heading, VStack, Text, HStack } from "@chakra-ui/react";
import DataExplorationMap from "../../components/GenericMap/DataExplorationMap";
import {
  BoundingBox,
  DataExplorationMapData,
  MapFilters,
} from "../../components/GenericMap/DataExplorationMapDataTypes";
import { CMSButton } from "../../components/CMS/CMSButton";
import { useState } from "react";
import { services } from "../../components/ServiceBox/services";
import CMS from "../../components/CMS/CMS";

type GeographicalDataExplorationProps = {
  dataSets: DataExplorationMapData[];
  isLoading: boolean;
  loadDataSetData: (
    selectedDataSets: string[],
    boundingBox: BoundingBox,
    filter: MapFilters
  ) => void;
};

export const GeographicalDataExploration: React.FC<
  GeographicalDataExplorationProps
> = ({ dataSets, isLoading, loadDataSetData }) => {
  window.scrollTo({ top: 0, left: 0 });
  const [showCms, setShowCms] = useState(false);
  const serviceUid =
    services.find((s) => s.title === "Reverse Geocoding Service")?.id || 0;
  return (
    <VStack maxWidth={"container.xl"} w="full" margin={10}>
      <HStack alignItems={"center"}>
        <Heading>GEOGRAPHICAL DATA EXPLORATION</Heading>
        <CMSButton
          buttonText={showCms ? "Return" : "About"}
          variant={showCms ? undefined : "white"}
          onClick={() => setShowCms(!showCms)}
        />
      </HStack>
      <Text textAlign={"center"} pb={5}>
        Data exploration and verification using maps and filters.
      </Text>
      {!showCms && (
        <DataExplorationMap
          dataSets={dataSets}
          isLoading={isLoading}
          loadDataSetData={loadDataSetData}
        ></DataExplorationMap>
      )}
      {showCms && <CMS serviceUid={serviceUid} />}
    </VStack>
  );
};
