import { AxiosError } from "axios";
import { useToastbar } from "../../hooks/useToastbar";
import { postLoginPasswordForgot } from "../../services/services";
import { RequestPasswordInput } from "../../types";
import { ResetPassword } from "./ResetPassword";

export const ResetPasswordContainer = () => {
  const toastbar = useToastbar();
  const onSubmit = ({ email }: RequestPasswordInput) => {
    postLoginPasswordForgot({ email })
      .then(() => {
        toastbar({ title: "Request submitted", status: "success" });
      })
      .catch((requestError: AxiosError) => {
        if (requestError.response?.status === 422) {
          toastbar({
            title: "Validation error",
            status: "error",
            description:
              "Please verify that the entered email address is correct",
          });
        } else {
          toastbar({
            title: "An error occured",
            status: "error",
            description:
              requestError?.response?.statusText || requestError.message,
          });
        }
      });
  };

  return (
    <>
      <ResetPassword onSubmit={onSubmit} />
    </>
  );
};
