import React, { ReactNode, useState, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  MapContainerProps,
  Marker,
  Tooltip,
  LayersControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  BoundingBox,
  DataExplorationMapData,
  MapElement,
  MapFilters,
  MarkerData,
} from "./DataExplorationMapDataTypes";
import { LatLng } from "leaflet";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import MarkerModal from "./components/MarkerModal";
import { Stack, VStack, useDisclosure } from "@chakra-ui/react";
import { ToolBox } from "./components/ToolBox";
import { BoundingBoxHandler } from "./components/BoundigBoxHandler";
import { LoadingOverlay } from "./components/LoadingOverlay";
import { MetadataBox } from "./components/MetadataBox";

interface DataExplorationMapProps extends MapContainerProps {
  children?: ReactNode;
  dataSets: DataExplorationMapData[];
  isLoading: boolean;
  loadDataSetData: (
    selectedDataSets: string[],
    boundingBox: BoundingBox,
    filters: MapFilters
  ) => void;
}

const DataExplorationMap: React.FC<DataExplorationMapProps> = ({
  children,
  dataSets,
  isLoading,
  loadDataSetData,
  ...restProps
}) => {
  const { BaseLayer } = LayersControl;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMarker, setSelectedMarker] = useState<MarkerData | null>(null);
  const [clusterMarkers, setClusterMarkers] = useState(true);
  const [currentMapBb, setCurrentMapBb] = useState<BoundingBox>({
    lowerLeft: new LatLng(0.0, 0.0),
    upperRight: new LatLng(0.0, 0.0),
  });
  const [selectedDataSets, setSelectedDataSets] = useState<string[]>([]);
  const mapRef = useRef<L.Map>(null);

  const getMarkerHtmlStyle = (colourString: string) => {
    return `
    background-color: ${colourString};
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF`;
  };

  const handleMarkerClick = (marker: MarkerData) => {
    setSelectedMarker(marker);
    onOpen();
  };

  const handleMapMoveEnd = (lowerLeft: LatLng, upperRight: LatLng) =>
    setCurrentMapBb({ lowerLeft, upperRight });

  const handlePanToNewCenter = (newCenter: LatLng) => {
    if (mapRef.current) {
      mapRef.current.panTo(newCenter);
    }
  };

  const generateMarker = (data: MapElement, color: string) => {
    const icon = L.divIcon({
      className: "my-custom-pin",
      popupAnchor: [0, -36],
      html: `<span style="${getMarkerHtmlStyle(color)}" />`,
    });
    return (
      <Marker
        icon={icon}
        position={new LatLng(data.lat, data.lon)}
        key={"" + data.lat + data.lon + "marker" + Math.random()} // TODO: Find better key
        eventHandlers={{
          click: () => {
            handleMarkerClick({
              id: "" + data.lat + data.lon + "marker",
              data: data,
            });
          },
        }}
      >
        <Tooltip sticky key={"" + data.lat + data.lon + "tooltip"}>
          {attributesToText(data.data)}
        </Tooltip>
      </Marker>
    );
  };

  const attributesToText = (attributes: Record<string, any>) => {
    return Object.keys(attributes)
      .map((key) => `${key}: ${attributes[key]}`)
      .join(", ");
  };

  const onClusterSelect = (checked: boolean) => {
    setClusterMarkers(checked);
  };

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      padding={50}
      alignItems={{ base: "center", md: "start" }}
    >
      <MapContainer
        ref={mapRef}
        style={{ height: "950px", width: "950px" }}
        // center={[57.72502648981336, 11.850171984290064]} // VCC
        center={[39.76751518582779, -99.03024343782495]} // USA
        zoom={5} // Initial zoom level
        {...restProps}
      >
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </BaseLayer>
        </LayersControl>
        {!clusterMarkers &&
          dataSets.map((ds) =>
            ds.elements.map((e) => generateMarker(e, ds.color))
          )}
        {clusterMarkers && (
          <MarkerClusterGroup chunkedLoading>
            {dataSets.map((ds) =>
              ds.elements.map((e) => generateMarker(e, ds.color))
            )}
          </MarkerClusterGroup>
        )}
        {/* 
          Heatmap library (react-leaflet-heatmap-layer-v3) currently incompatible with
          React 18+
        */}
        {isLoading && <LoadingOverlay isOpen={isLoading} onClose={onClose} />}
        {selectedMarker && (
          <MarkerModal
            isOpen={isOpen}
            onClose={onClose}
            content={selectedMarker.data}
          />
        )}
        <BoundingBoxHandler onMapMoveEnd={handleMapMoveEnd} />
        {children}
      </MapContainer>
      <VStack alignItems={"start"}>
        <ToolBox
          dataSets={dataSets.map((ds) => ds.dataSetInfo.name)}
          onDataSetsLoad={(filters) =>
            loadDataSetData(selectedDataSets, currentMapBb, filters)
          }
          onClusterSelect={onClusterSelect}
          onDataSetSelect={(title) => {
            setSelectedDataSets(selectedDataSets.concat(title));
          }}
          onDataSetDeselect={(title) => {
            setSelectedDataSets(selectedDataSets.filter((ds) => ds != title));
          }}
          filtersDisabled={selectedDataSets.length < 1}
        />
        {selectedDataSets.length > 0 && (
          <>
            {selectedDataSets.map((dsName, i) => {
              return (
                <MetadataBox
                  key={i}
                  dataSet={
                    dataSets.find((ds) => ds.dataSetInfo.name === dsName)
                      ?.dataSetInfo || null
                  }
                />
              );
            })}
          </>
        )}
        {selectedDataSets.length === 0 && <MetadataBox dataSet={null} />}
      </VStack>
    </Stack>
  );
};

export default DataExplorationMap;
