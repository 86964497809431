import accidentRiskAlertImage from "../../assets/images/accidentRiskAlertImage.jpg";
import analysisGraphImage from "../../assets/images/analysisGraphImage.png";
import rekoroneImage from "../../assets/images/rekoroneImage.png";
import roadSignInformationImage from "../../assets/images/roadSignInformationImage.jpg";
import safeSpeedImage from "../../assets/images/safespeed.png";
import safetyGraphImage from "../../assets/images/safetygraphImage.png";
import trafficWatchImage from "../../assets/images/trafficwatchImage.png";
import travelTimeEstimationImage from "../../assets/images/travelTimeEstimationImage.jpg";
import accidentRiskEstimationImage from "../../assets/images/accidentRiskEstimationImage.png";
import generalMapImage from "../../assets/images/generalMapImage.png";
import geocodeImage from "../../assets/images/geocodeImage.jpg";
import { ServiceScopes } from "../../enum";
import { Service } from "../../types";
import {
  accidentRiskAlert,
  accidentRiskEstimation,
  geographicalDataExploration,
  historicalAccidentAnalysisService,
  rekorOne,
  roadSignAnalysis,
  safeSpeedService,
  safetyGraph,
  trafficWatch,
  travelTimeEstimation,
  rgs,
} from "../../utils/navigationLinks";

export const services: Array<Service> = [
  {
    id: 1,
    image: travelTimeEstimationImage,
    title: "Travel Time Measurements",
    description:
      "Travel time estimation between selected locations based on statistics.",
    navigationLink: travelTimeEstimation,
    preview: true,
    scopes: [ServiceScopes.ReadTtm, ServiceScopes.WriteTtm],
  },
  {
    id: 11,
    image: geocodeImage,
    title: "Reverse Geocoding Service",
    description:
      "Find road data attributes such as speed limits, curvature, road class and more from WGS84 position.",
    navigationLink: rgs,
    preview: true,
    scopes: [ServiceScopes.ReadRGService],
  },
  {
    id: 10,
    image: generalMapImage,
    title: "Geographical Data Exploration",
    description: "Data exploration using maps and filters.",
    navigationLink: geographicalDataExploration,
    preview: true,
    scopes: [ServiceScopes.ReadLocationData],
  },
  {
    id: 3,
    image: rekoroneImage,
    title: "Traffic Management",
    description:
      "AI Powered traffic management utilizing connected vehicle data.",
    navigationLink: rekorOne,
    preview: true,
    scopes: [ServiceScopes.ReadTrafficManagement],
  },
  {
    id: 4,
    image: safetyGraphImage,
    title: "Safety Graph",
    description: "Collaborative knowledge graphs applied in traffic safety.",
    navigationLink: safetyGraph,
    preview: true,
    scopes: [ServiceScopes.ReadSafetyGraph],
  },
  {
    id: 5,
    image: roadSignInformationImage,
    title: "Road Sign Analysis",
    description: "Road sign data aggregation for road operators and OEMs.",
    navigationLink: roadSignAnalysis,
    preview: true,
    scopes: [ServiceScopes.ReadRoadSignInfo, ServiceScopes.WriteRoadSignInfo],
  },
  {
    id: 9,
    image: accidentRiskEstimationImage,
    title: "Accident Risk Estimation - INACTIVE",
    description:
      "Per road segment accident risk, estimated using showcased historical data.",
    navigationLink: accidentRiskEstimation,
    preview: false,
    scopes: [
      ServiceScopes.ReadAccidentRiskEstimation,
      ServiceScopes.WriteAccidentRiskEstimation,
    ],
  },
  {
    id: 2,
    image: trafficWatchImage,
    title: "AD Clearance - INACTIVE",
    description: "Real time surveillence of traffic conditions.",
    navigationLink: trafficWatch,
    preview: false,
    scopes: [ServiceScopes.ReadADClearance],
  },
  {
    id: 6,
    image: analysisGraphImage,
    title: "Accident Analysis Service",
    description: "Graph-based accident analysis combining multiple data sets.",
    navigationLink: historicalAccidentAnalysisService,
    preview: false,
    scopes: [
      ServiceScopes.ReadAccidentAnalysis,
      ServiceScopes.WriteAccidentAnalysis,
    ],
  },
  {
    id: 7,
    image: accidentRiskAlertImage,
    title: "Accident Risk Alert",
    description:
      "Real-time dynamic risk estimation based on multiple data streams.",
    navigationLink: accidentRiskAlert,
    preview: false,
    scopes: [
      ServiceScopes.ReadAccidentRiskAlert,
      ServiceScopes.WriteAccidentRiskAlert,
    ],
  },
  {
    id: 8,
    image: safeSpeedImage,
    title: "Safe Speed Service",
    description: "Can we calculate safe speed?",
    navigationLink: safeSpeedService,
    preview: false,
    scopes: [ServiceScopes.ReadSafeSpeedInfo, ServiceScopes.WriteSafeSpeedInfo],
  },
];
