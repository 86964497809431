import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { ProtectedRoutes } from "./components/Authentication/ProtectedRoutes";
import { useToastbar } from "./hooks/useToastbar";
import { DashboardLayout } from "./layouts/DashboardLayout";
import { CreateAccountPage } from "./pages/CreateAccount";
import { CreatePassword } from "./pages/CreatePassword";
import { LandingPage } from "./pages/LandingPage";
import { LogIn } from "./pages/LogIn";
import { ManageUsers } from "./pages/ManageUsers";
import { RekorOne } from "./pages/RekorOne";
import { ResetPassword } from "./pages/ResetPassword";
import { SafetyGraph } from "./pages/SafetyGraph/SafetyGraph";
import { StartPage } from "./pages/StartPage";
import { TrafficWatch } from "./pages/TrafficWatch";
import { TravelTimeEstimation } from "./pages/TravelTimeEstimation";
import { TravelTimesApiUsers as TravelTimesApiUsers } from "./pages/TravelTimesApiUsers";
import { AccidentRiskEstimation } from "./pages/AccidentRiskEstimation";
import { UserProvider } from "./services/user.context";
import { NotFound } from "./pages/NotFound";
import {
  accidentRiskEstimation,
  createAccount,
  createPassword,
  logIn,
  manageUsers,
  rekorOne,
  resetPassword,
  safetyGraph,
  start,
  trafficWatch,
  travelTimeEstimation,
  roadSignAnalysis,
  geographicalDataExploration,
  mfaSetup,
  rgs,
  travelTimeEstimationInteractive,
  travelTimeEstimationApiUsers as travelTimeEstimationApiUsers,
} from "./utils/navigationLinks";
import { RSA } from "./pages/RoadSignAnalysis";
import { MFASetup } from "./pages/MFASetUp";
import { GeographicalDataExploration } from "./pages/GeographicalDataExploration";
import { MapMatchingService } from "./pages/MapMatchingService";
import { TravelTimesInteractiveMap } from "./pages/TravelTimesInteractiveMap";

const App = () => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const toastbar = useToastbar();

  useEffect(() => {
    if (errorMessage) {
      toastbar({ title: errorMessage, status: "info" });
    }
  }, [errorMessage, toastbar]);

  return (
    <UserProvider>
      <Routes>
        <Route element={<DashboardLayout />}>
          {/* Open routes */}
          <Route index element={<LandingPage />} />
          <Route path={logIn.href} element={<LogIn />} />
          <Route path={resetPassword.href} element={<ResetPassword />} />
          <Route path={createPassword.href} element={<CreatePassword />} />
          <Route path={mfaSetup.href} element={<MFASetup />} />
          {/* Protected routes */}
          <Route
            element={<ProtectedRoutes setErrorMessage={setErrorMessage} />}
          >
            <Route path={start} element={<StartPage />} />
            <Route path={createAccount.href} element={<CreateAccountPage />} />
            <Route
              path={travelTimeEstimation.href}
              element={<TravelTimeEstimation />}
            />
            <Route
              path={travelTimeEstimationInteractive.href}
              element={<TravelTimesInteractiveMap />}
            />
            <Route
              path={travelTimeEstimationApiUsers.href}
              element={<TravelTimesApiUsers />}
            />
            <Route path={manageUsers.href} element={<ManageUsers />} />
            <Route path={rgs.href} element={<MapMatchingService />} />
            <Route path={rekorOne.href} element={<RekorOne />} />
            <Route path={safetyGraph.href} element={<SafetyGraph />} />
            <Route path={roadSignAnalysis.href} element={<RSA />} />
            <Route
              path={geographicalDataExploration.href}
              element={<GeographicalDataExploration />}
            />
            {/* <Route
                path={accidentRiskEstimation.href}
                element={<AccidentRiskEstimation />}
              /> */}
            {/* <Route path={trafficWatch.href} element={<TrafficWatch />} /> */}
          </Route>
          <Route path="*" element={<NotFound />}></Route>
        </Route>
      </Routes>
    </UserProvider>
  );
};

export default App;
