import L from "leaflet";
import "leaflet-arrowheads";

export const NewPolyline = (
  from: { lat: number; lng: number },
  to: { lat: number; lng: number }
) => {
  return L.polyline([from, to], {
    color: "black",
    opacity: 0.5,
  }).arrowheads({
    size: "15px",
    fill: true,
    color: "black",
    opacity: 0.5,
  });
};
