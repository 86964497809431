import { VStack, Heading, Spinner, HStack } from "@chakra-ui/react";
import { LatLng } from "leaflet";
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import norwayIcon from "../../assets/icons/norway-icon.png";
import annotationIcon from "../../assets/icons/pencil-lower-left.png";
import cameraIcon from "../../assets/icons/camera-icon.png";
import {
  Annotation,
  CameraDetected,
  DiffStats,
  NVDBSign,
  SignCount,
} from "../../services/types";
import { BarChartBox } from "./components/BarChartBox";
import { SingleValueBox } from "./components/SingleValueBox";
import { useState } from "react";
import { services } from "../../components/ServiceBox/services";
import { CMSButton } from "../../components/CMS/CMSButton";
import CMS from "../../components/CMS/CMS";

const NVDBIcon = L.icon({
  iconSize: [30, 41],
  iconAnchor: [12, 41],
  iconUrl: norwayIcon,
});

const AnnotationIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  iconUrl: annotationIcon,
});

const CameraDetectedIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  iconUrl: cameraIcon,
});

const nvdbMarker = (sign: NVDBSign) => {
  return (
    <Marker position={new LatLng(sign.lat, sign.lon)} icon={NVDBIcon}>
      <Popup>{sign.info}</Popup>
    </Marker>
  );
};

const annotationMarker = (sign: Annotation) => {
  return (
    <Marker position={new LatLng(sign.lat, sign.lon)} icon={AnnotationIcon}>
      <Popup>{sign.info}</Popup>
    </Marker>
  );
};

const cameraMarker = (sign: CameraDetected) => {
  return (
    <Marker position={new LatLng(sign.lat, sign.lon)} icon={CameraDetectedIcon}>
      <Popup>{sign.info}</Popup>
    </Marker>
  );
};

type RSAProps = {
  annotations: Array<Annotation>;
  nvdbSigns: Array<NVDBSign>;
  cameraDetected: Array<CameraDetected>;
  isLoading: boolean;
  detectedStats: Array<DiffStats>;
  annotationsStats: Array<DiffStats>;
  signCount: SignCount;
};

export const RSA = ({
  annotations,
  nvdbSigns,
  cameraDetected,
  isLoading,
  detectedStats,
  annotationsStats,
  signCount,
}: RSAProps) => {
  const [showCms, setShowCms] = useState(false);
  const serviceUid =
    services.find((s) => s.title === "Road Sign Analysis")?.id || 0;
  return (
    <VStack padding={5}>
      <HStack alignItems={"center"}>
        <Heading pb={5} pt={5}>
          Road Sign Analysis
        </Heading>
        <CMSButton
          buttonText={showCms ? "Return" : "About"}
          variant={showCms ? undefined : "white"}
          onClick={() => setShowCms(!showCms)}
        />
      </HStack>
      {showCms && <CMS serviceUid={serviceUid} />}
      {isLoading && !showCms && (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={"brand.lineGrey"}
          color={"brand.darkGreen"}
          size="xl"
        />
      )}
      {!isLoading && !showCms && (
        <>
          <MapContainer
            style={{ width: "150em", height: "50em" }}
            center={[61.1089465, 11.8546754801]}
            zoom={6}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <MarkerClusterGroup chunkedLoading>
              {nvdbSigns.map((sign) => nvdbMarker(sign))}
              {annotations.map((a) => annotationMarker(a))}
              {cameraDetected.map((cam) => cameraMarker(cam))}
            </MarkerClusterGroup>
          </MapContainer>
          <HStack alignItems={"start"}>
            <BarChartBox
              detectedStats={detectedStats}
              annotationsStats={annotationsStats}
            />
            <VStack spacing={2}>
              <SingleValueBox
                title="Number of camera detected signs"
                value={signCount.detected_count}
              />
              <SingleValueBox
                title="Number of manually annotated signs"
                value={signCount.annotations_count}
              />
              <SingleValueBox
                title="Number of northbound NVDB signs"
                value={signCount.nvdb_count}
              />
            </VStack>
          </HStack>
        </>
      )}
    </VStack>
  );
};
