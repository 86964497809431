import { Box, VStack, Text, Heading } from "@chakra-ui/react";
import { DatasetInfo } from "../../../services/types";

type MetadataBoxProps = {
  dataSet: DatasetInfo | null;
};

export const MetadataBox: React.FC<MetadataBoxProps> = ({ dataSet }) => {
  return (
    <>
      <Box borderWidth="1px" maxWidth={"50vh"}>
        <VStack spacing={5} alignItems={"start"} padding={5}>
          {dataSet ? (
            <>
              <Heading size={"lg"}>{dataSet.name} Metadata</Heading>
              <Text as={"em"}>
                Data from:{" "}
                {`${
                  dataSet.start_unixtime
                    ? new Date(dataSet.start_unixtime * 1000).toDateString()
                    : "N/A"
                }`}
              </Text>
              <Text as={"em"}>
                Data to:{" "}
                {`${
                  dataSet.end_unixtime
                    ? new Date(dataSet.end_unixtime * 1000).toDateString()
                    : "N/A"
                }`}
              </Text>
              <Heading size={"md"}>Description</Heading>
              <Text noOfLines={5}>{dataSet.description}</Text>
            </>
          ) : (
            <>
              <Heading size={"lg"}>Metadata</Heading>
              <Text as={"em"}>Data from: N/A</Text>
              <Text as={"em"}>Data to: N/A</Text>
              <Heading size={"md"}>Description</Heading>
              <Text>No data selected.</Text>
            </>
          )}
        </VStack>
      </Box>
    </>
  );
};
