import { Flex, Heading, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useUser } from "../../services/user.context";
import { LinkNameAndHref } from "../../types";
import { footerPageGeneralLinks } from "../../utils/navigationLinks";
import { getFilteredServices } from "../Authentication/FilterServices";
import { Logo } from "../Logo";
import { FooterLinks } from "./FooterLinks";

export const Footer = () => {
  const { user } = useUser();
  const [firstColumnServiceLinks, setFirstColumnServiceLinks] = useState<
    LinkNameAndHref[]
  >([]);
  const [secondColumnServiceLinks, setSecondColumnServiceLinks] = useState<
    LinkNameAndHref[]
  >([]);

  useEffect(() => {
    const availableServices = getFilteredServices(user);
    let tempArray: LinkNameAndHref[] = [];

    availableServices
      .slice(0, 4)
      .map((service) => tempArray.push(service.navigationLink));
    setFirstColumnServiceLinks(tempArray);

    if (availableServices.length > 4) {
      tempArray = [];
      availableServices
        .slice(4)
        .map((service) => tempArray.push(service.navigationLink));

      setSecondColumnServiceLinks(tempArray);
    }
  }, [user]);

  const contactInfo = () => {
    return (
      <VStack alignItems={"start"}>
        <Heading as="h4" color={"brand.white"} size="md" marginBottom={1}>
          Contact
        </Heading>

        <Text mt={4} color={"brand.white"} size="md">
          info@evolvo.tech
        </Text>
        <Text mt={4} color={"brand.white"} size="md">
          +46- XX XXX XXX
        </Text>
        <Text mt={4} color={"brand.white"} size="md">
          Torslanda 151
        </Text>
        <Text color={"brand.white"} size="md">
          413 43
        </Text>
        <Text color={"brand.white"} size="md">
          Gothenburg
        </Text>
      </VStack>
    );
  };

  const links = (
    linksArray: Array<LinkNameAndHref>,
    heading: string,
    border = true
  ) => {
    if (linksArray.length > 0) {
      return (
        <Stack
          pl={5}
          borderLeftWidth={border ? 1 : 0}
          borderLeftColor="brand.white"
          mt={heading === "" ? 6 : 0}
          ml={5}
        >
          <Flex direction={"column"}>
            <Heading as="h4" color={"brand.white"} size="md">
              {heading}
            </Heading>
            <FooterLinks links={linksArray} />
          </Flex>
        </Stack>
      );
    } else {
      return (
        <Stack
          pl={5}
          borderLeftWidth={border ? 1 : 0}
          borderLeftColor="brand.white"
          mt={heading === "" ? 6 : 0}
          ml={5}
        >
          <Flex direction={"column"}>
            <Heading as="h4" color={"brand.white"} size="md">
              {heading}
            </Heading>
            <Text>No services available.</Text>
          </Flex>
        </Stack>
      );
    }
  };

  return (
    <HStack
      className="footer"
      as={"footer"}
      bg={"brand.darkGreen"}
      height="100%"
      width="100%"
      justifyContent={{ md: "space-between", base: "center" }}
      alignItems="start"
    >
      <Stack direction={"row"} mx={10} my="45px">
        {contactInfo()}

        {links(footerPageGeneralLinks, "Pages")}
        {firstColumnServiceLinks.length &&
          links(firstColumnServiceLinks, "Services")}
        {secondColumnServiceLinks.length > 0 && (
          <Stack>{links(secondColumnServiceLinks, "", false)}</Stack>
        )}
      </Stack>
      <Flex justifyContent={"flex-end"} pr={7} pt={28} flex={1}>
        <Logo
          systemColor={"brand.white"}
          levelColor={"brand.white"}
          safetyColor={"brand.white"}
        />
      </Flex>
    </HStack>
  );
};
