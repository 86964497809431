import { Button, HStack } from "@chakra-ui/react";
import { GeofenceSelectionType } from "../../enum";
import { useMapSnap, useMapState } from "../../state/map_state";

type Props = {
  onAddBothDirections: () => void;
};

export const TravelTimeToolbar = ({ onAddBothDirections }: Props) => {
  const mapState = useMapState();
  const geofenceSelection = useMapSnap().geofenceSelection;
  return (
    <HStack w="full">
      <HStack style={{ margin: "0 auto" }}>
        <Button
          onClick={() =>
            mapState.setGeofenceSelection(GeofenceSelectionType.Locations)
          }
          variant={
            geofenceSelection === GeofenceSelectionType.Locations
              ? "whiteWithUnderLine"
              : "inactive"
          }
        >
          Locations
        </Button>
        <Button
          onClick={() =>
            mapState.setGeofenceSelection(GeofenceSelectionType.Routes)
          }
          variant={
            geofenceSelection === GeofenceSelectionType.Routes
              ? "whiteWithUnderLine"
              : "inactive"
          }
        >
          Routes
        </Button>
      </HStack>

      {/* <Switch
        onChange={() => onAddBothDirections()}
        colorScheme="green"
        id="bothDirections"
      /> */}
    </HStack>
  );
};
