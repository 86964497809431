import Axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
//@ts-ignore
import qs from "qs";
import { Config } from "../config";
import localStorageService from "../services/localstorage.service";

const baseConfig: AxiosRequestConfig = {
  baseURL: Config.api_url,
  headers: {
    "Content-Encoding": "UTF-8",
    Accept: "application/json",
    "Content-Type": "application/json-patch+json",
  },
  paramsSerializer: {
    serialize: (param: any) => qs.stringify(param, { indices: false }),
  },
};

const rewriteUrl = (path: string) => {
  return path.replace("/api", "");
};

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  if (config.url?.startsWith("/api")) {
    config.url = rewriteUrl(config.url as string);
    config.baseURL = Config.api_url;
  }
  const user = localStorageService.getUser();
  if (user?.authToken) {
    config.headers["Authorization"] = "Bearer " + user?.authToken;
  }
  return config;
};

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const AXIOS_INSTANCE = Axios.create(baseConfig);
  AXIOS_INSTANCE.interceptors.request.use(onRequest);
  // AXIOS_INSTANCE.interceptors.response.use(
  //   (async (response: AxiosResponse): Promise<SwaggerResponse<T>> => {
  //     // Any status code that lie within the range of 2xx cause this function to trigger
  //     // Do something with response data
  //   }) as any,
  //   (error: AxiosError) => {
  //     // Any status codes that falls outside the range of 2xx cause this function to trigger
  //     // Do something with response error
  //   }
  // );
  const source = Axios.CancelToken.source();

  const promise = AXIOS_INSTANCE<T>({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);
  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};
