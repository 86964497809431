import { VStack, Text, Heading, Link } from "@chakra-ui/react";

export const NotFound = () => {
  return (
    <VStack padding={100}>
      <Heading>404 Page Not Found</Heading>
      <Text>Sorry, that page doesn't exist.</Text>
      <Link href="/start">Return to Start</Link>
    </VStack>
  );
};
