import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { ChangeEvent, FormEvent, useState } from "react";
import UnsavedChangesAlert from "./UnsavedChangesAlert";
import { Section } from "./CMS";
import { TrashIcon } from "../../assets/icons/icon";
import { Alert } from "../Alert";

export type CMSEditorFormState = {
  title: string;
  text: string;
  imageFile: File | null;
};

type CMSEditorModalProps = {
  content: Section;
  isModalOpen: boolean;
  handleSubmit: (formData: CMSEditorFormState) => void;
  handleDelete: (sec: Section) => void;
  onModalClose: () => void;
  isEditing: boolean;
};

export const CMSEditorModal: React.FC<CMSEditorModalProps> = ({
  content,
  isModalOpen,
  handleSubmit,
  handleDelete,
  onModalClose,
  isEditing,
}) => {
  const [formState, setFormState] = useState<CMSEditorFormState>({
    title: content.title,
    text: content.text,
    imageFile: null,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const handleClose = () => {
    if (formHasChanges()) {
      onOpen();
    } else {
      onModalClose();
    }
  };

  const handleSave = (event: FormEvent) => {
    event.preventDefault();
    if (formHasChanges()) {
      handleSubmit({ ...formState });
    }
    setFormState({ title: "", text: "", imageFile: null });
    onModalClose();
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleFileUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFormState({ ...formState, imageFile: e.target.files[0] });
    }
  };

  const formHasChanges = () =>
    formState.title !== content.title ||
    formState.text !== content.text ||
    formState.imageFile !== null;

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Section</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSave}>
            <FormControl id="title">
              <FormLabel>Title</FormLabel>
              <Input
                width={"100%"}
                type="text"
                name="title"
                value={formState.title}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="body">
              <FormLabel>Body Text</FormLabel>
              <Textarea
                name="text"
                value={formState.text}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="imageFile">
              <FormLabel>Upload Image</FormLabel>
              <Input
                width={"100%"}
                type="file"
                name="imageFile"
                accept="image/*"
                onChange={handleFileUploadChange}
              />
            </FormControl>
            <HStack>
              <Button mt="4" size={"md"} type="submit">
                Save
              </Button>
              <Button
                mt="4"
                size={"md"}
                variant={"outline"}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Alert
                onCancel={() => setShowDeleteAlert(false)}
                BodyText="Are you sure you want to delete this section? This action cannot be undone."
                ApplyButtonText="Delete"
                HeadingText="Delete section"
                onApply={() => {
                  handleDelete(content);
                  onModalClose();
                }}
                isOpen={showDeleteAlert}
                TriggerButton={
                  <IconButton
                    isDisabled={!isEditing}
                    mt="4"
                    size={"sm"}
                    variant={"destructive"}
                    onClick={() => setShowDeleteAlert(true)}
                    icon={<TrashIcon />}
                    aria-label="Delete section"
                    backgroundColor="transparent"
                  />
                }
              ></Alert>
              <UnsavedChangesAlert
                isOpen={isOpen}
                onExit={() => {
                  onClose();
                  setFormState({
                    title: "",
                    text: "",
                    imageFile: null,
                  });
                  onModalClose();
                }}
                onClose={onClose}
              />
            </HStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
