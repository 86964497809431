import { Text, Heading, Table, TableCaption, TableContainer, Tbody, Tr, Td, Thead, VStack, Th, IconButton, Button, Input, Center } from "@chakra-ui/react"
import { TrashIcon } from "../../assets/icons/icon"
import { useState } from "react"
import { NewlyCreatedApiUser } from "../../http/http"

type Props = {
    apiUsers: { id: string, name: string }[]
    onDelete: (id: string) => Promise<void>
    onCreate: (name: string) => Promise<NewlyCreatedApiUser>
}

enum ViewState {
    LIST,
    GIVE_NAME,
    CREATED
}

export const ApiUsers = ({ apiUsers: apiUsers, onDelete, onCreate }: Props) => {
    const [viewState, setViewState] = useState(ViewState.LIST);
    const [givenName, setGivenName] = useState("");
    const [newlyCreatedToken, setNewlyCreatedToken] = useState<NewlyCreatedApiUser | null>(null);

    const onCreateButton = async () => {
        let tokenData = await onCreate(givenName);
        setNewlyCreatedToken(tokenData)
        setViewState(ViewState.CREATED)
    };

    if (ViewState.CREATED == viewState && newlyCreatedToken != null) {
        return <>
            <VStack backgroundColor={"brand.lineGrey"} padding={"10px"} align={"left"}>
                <Heading lineHeight="normal">Here is you new api user</Heading>
                <Text>Important: The token will only be shown this once, copy and keep it secure</Text>
                <Text>Name: <b><i>{newlyCreatedToken.name}</i></b></Text>
                <Text>Token: <b><i>{newlyCreatedToken.token}</i></b></Text>
            </VStack >

            <Center margin={"10px"}>
                <Button onClick={() => setViewState(ViewState.LIST)}>OK</Button>
            </Center>
        </>
    }
    if (viewState == ViewState.GIVE_NAME) {
        return <>
            <Heading lineHeight="normal">Creating new api user</Heading>
            <Input
                marginBottom={"10px"}
                marginTop={"10px"}
                placeholder='Name of API user'
                onChange={(event) => setGivenName(event.target.value)}
            />
            <Button onClick={onCreateButton}>Submit</Button>
        </>
    }

    // Otherwise just list the api users
    return <VStack>
        <Button size={"sm"} onClick={() => setViewState(ViewState.GIVE_NAME)}>Create new api user</Button>


        {/* <Heading lineHeight="normal">API USERS</Heading> */}
        <TableContainer rounded="5">
            <Table variant={"simple"}>
                <TableCaption>API USERS</TableCaption>
                <Thead
                    rounded="10"
                    backgroundColor={"brand.darkGreen"}
                    borderRadius={5}
                >
                    <Tr mb={-2} fontFamily={"Bebas Neue"}>
                        <Th>
                            <Text fontSize={"2xl"} color={"brand.white"}>
                                NAME
                            </Text>
                        </Th>
                        <Th>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {apiUsers.map((token, index) => {
                        return <Tr key={index}>
                            {/* <Td>{token.id}</Td> */}
                            <Td>{token.name}</Td>
                            <Td>
                                <IconButton
                                    size={"xs"}
                                    onClick={async () => { await onDelete(token.id) }}
                                    backgroundColor="transparent"
                                    icon={<TrashIcon />}
                                    aria-label="Delete"
                                    boxShadow="none"
                                />

                            </Td>
                        </Tr>
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    </VStack>
}