import { Button } from "@chakra-ui/react";
import React from "react";

type CMSButtonProps = {
  buttonText: "About" | "Return";
  variant: undefined | "white";
  onClick: () => void;
};

export const CMSButton: React.FC<CMSButtonProps> = ({
  buttonText,
  variant,
  onClick,
}: CMSButtonProps) => {
  return (
    <Button
      variant={variant}
      size={"md"}
      style={{ position: "relative", bottom: "4px" }}
      onClick={() => {
        onClick();
      }}
    >
      {buttonText}
    </Button>
  );
};
