import { VStack, Text, Stack } from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = {
  title: string;
  boxContent: ReactNode;
  borderAroundBoxContent?: boolean;
};

export const TitleAndBox = ({
  title,
  boxContent,
  borderAroundBoxContent = false,
}: Props) => {
  return (
    <VStack width={"full"} alignItems={"start"}>
      <Text color={"brand.darkGrey"}>{title}</Text>
      {borderAroundBoxContent ? (
        <Stack
          width={"100%"}
          borderRadius={12}
          borderWidth={1}
          borderColor={"brand.lineGrey"}
        >
          {boxContent}
        </Stack>
      ) : (
        <Stack width={"100%"}>{boxContent}</Stack>
      )}
    </VStack>
  );
};
