export const MenuIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 7L4 7" stroke="#111111" strokeWidth="1.5" />
      <path d="M20 12L4 12" stroke="#111111" strokeWidth="1.5" />
      <path d="M20 17L4 17" stroke="#111111" strokeWidth="1.5" />
    </svg>
  );
};

export const TrashIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.84063 20C2.37188 20 1.97344 19.838 1.64531 19.5139C1.31719 19.1898 1.15313 18.7963 1.15313 18.3333V2.5H0V0.833333H5.2875V0H12.7125V0.833333H18V2.5H16.8469V18.3333C16.8469 18.7778 16.6781 19.1667 16.3406 19.5C16.0031 19.8333 15.6094 20 15.1594 20H2.84063ZM15.1594 2.5H2.84063V18.3333H15.1594V2.5ZM5.82188 15.9444H7.50938V4.86111H5.82188V15.9444ZM10.4906 15.9444H12.1781V4.86111H10.4906V15.9444ZM2.84063 2.5V18.3333V2.5Z"
        fill="black"
      />
    </svg>
  );
};

export const PencilIcon = () => {
  return (
    <svg
      fill="#000000"
      height="18px"
      width="18px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <path
        d="M70.2,337.4l104.4,104.4L441.5,175L337,70.5L70.2,337.4z M0.6,499.8c-2.3,9.3,2.3,13.9,11.6,11.6L151.4,465L47,360.6
   L0.6,499.8z M487.9,24.1c-46.3-46.4-92.8-11.6-92.8-11.6c-7.6,5.8-34.8,34.8-34.8,34.8l104.4,104.4c0,0,28.9-27.2,34.8-34.8
   C499.5,116.9,534.3,70.6,487.9,24.1z"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none">
      <circle cx="7" cy="7" r="6" stroke="#111111" strokeWidth="2" />
      <line
        x1="11.4142"
        y1="12"
        x2="16"
        y2="16.5858"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
