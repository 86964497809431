import {
  Box,
  Text,
  Heading,
  Button,
  VStack,
  Input,
  FormControl,
  Select,
} from "@chakra-ui/react";
import { CreateUserInput } from "../../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { RoleSelection } from "../../enum";

const defaultValues = {
  name: "",
  email: "",
  roleId: RoleSelection.CompanyUser as
    | RoleSelection.CompanyAdmin
    | RoleSelection.CompanyUser,
};

type Props = {
  onConfirm: ({
    name,
    email,
    roleId,
  }: Pick<CreateUserInput, "name" | "email" | "roleId">) => void;
};

const schema: yup.SchemaOf<Pick<CreateUserInput, "name" | "email" | "roleId">> =
  yup
    .object()
    .shape({
      name: yup.string().required("You must specify a name"),
      email: yup
        .string()
        .email("Must be a valid email")
        .required("You must specify an email"),
      roleId: yup.number().required("Select user role"),
    })
    .defined();

export const CreateAccountPage = ({ onConfirm }: Props) => {
  interface RoleMap {
    roleName: string;
    roleValue: number;
  }

  const roles: RoleMap[] = [
    {
      roleName: "Company User",
      roleValue: RoleSelection.CompanyUser.valueOf(),
    },
    {
      roleName: "Company Admin",
      roleValue: RoleSelection.CompanyAdmin.valueOf(),
    },
  ];

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Pick<CreateUserInput, "name" | "email" | "roleId">>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const setRole = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue("roleId", parseInt(event.target.value) as 2 | 3, {
      shouldValidate: true,
    });
  };

  return (
    <VStack mb={28} mt={16} flexDirection="column" justify={"center"}>
      <Box w={{ md: "md", base: "lg" }}>
        {/* text needs to be changed */}
        <Heading textAlign={"center"}>create company account</Heading>
        {/* text needs to be changed */}
        <Text my={4}>
          Invite your colleagues to the portal by entering their email address,
          name and portal role here. A link is sent to the email which allows
          the user to finalize their account.
        </Text>
        <form onSubmit={handleSubmit(onConfirm)}>
          <FormControl>
            <Box mb={3}>
              <Text>Email*</Text>

              <Input
                focusBorderColor={"brand.darkGreen"}
                type="email"
                {...register("email")}
                size="sm"
              />
              <Text color={"brand.red"}> {errors?.email?.message}</Text>
            </Box>
            <Box mb={3}>
              <Text>Name*</Text>
              <Input {...register("name")} size="sm" />
              <Text color={"brand.red"}>{errors?.name?.message}</Text>
            </Box>

            <Box mb={3}>
              <Text>Role*</Text>
              <Select onChange={setRole}>
                {roles.map((role, i) => {
                  return (
                    <option key={role.roleValue} value={role.roleValue}>
                      {role.roleName}
                    </option>
                  );
                })}
              </Select>
            </Box>
          </FormControl>
          <VStack
            pb={5}
            borderBottomWidth={1}
            borderBottomColor={"brand.lineGrey"}
          >
            <Button isLoading={isSubmitting} type="submit" size={"long"}>
              Create account
            </Button>
          </VStack>
        </form>
      </Box>
    </VStack>
  );
};
