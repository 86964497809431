import {
  VStack,
  Heading,
  HStack,
  Button,
  Input,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { RouteMonitorData, RouteMonitorDataFilter } from "../../types";
import { RouteDatePicker } from "../Calendar/RouteDatePicker";

import { TitleAndBox } from "./TitleAndBox";
import { useState } from "react";
import { getUnixMillisecondsFromDate } from "../../utils/time-utils";
import ViaPointsCheckboxFilter from "./ViaPointsCheckboxFilter";

type EditRouteProps = {
  routeInfo: RouteMonitorData;
  onClose: () => void;
  onSave: (title: string, filters: RouteMonitorDataFilter) => void;
  getGeofenceName: (gfId: string) => string;
};

export const EditRoute = ({
  routeInfo,
  onClose,
  onSave,
  getGeofenceName,
}: EditRouteProps) => {
  const title = routeInfo.title
    ? routeInfo.title
    : "from " + routeInfo.from.name + " to " + routeInfo.to.name;

  const [routeTitle, setRouteTitle] = useState(title);
  const [startDate, setStartDate] = useState(
    routeInfo.filter?.startTime !== undefined &&
      routeInfo.filter?.startTime !== null
      ? new Date(routeInfo.filter.startTime)
      : new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDate, setEndDate] = useState(
    routeInfo.filter?.endTime !== undefined &&
      routeInfo.filter?.endTime !== null
      ? new Date(routeInfo.filter.endTime)
      : new Date()
  );

  const [maxTime, setMaxTime] = useState(
    routeInfo.filter?.maxDriveTime ? routeInfo.filter.maxDriveTime + "" : "0"
  );

  const [maxDistance, setMaxDistance] = useState(
    routeInfo.filter?.maxDriveDistance
      ? routeInfo.filter.maxDriveDistance + ""
      : "0"
  );

  const [selectedViaPoints, setSelectedViaPoints] = useState<string[]>(
    routeInfo.filter?.hasToTravelViaIds || []
  );

  const maxTimeAndDistance = (
    title: string,
    value: string,
    onChangeValue: (value: string) => void,
    rightText: string
  ) => {
    return (
      <TitleAndBox
        title={title}
        boxContent={
          <NumberInput
            onChange={(event) => onChangeValue(event)}
            value={value + " " + rightText}
            defaultValue={0}
            min={0}
          >
            <NumberInputField />
          </NumberInput>
        }
      />
    );
  };

  const stringToIntOrUndefined = (input: string) => {
    const parsedInt = parseInt(input);
    if (parsedInt <= 0) return undefined;
    return parsedInt;
  };

  return (
    <VStack>
      <Heading>Edit route</Heading>
      <TitleAndBox
        title="Title"
        boxContent={
          <Input
            onChange={(event) => setRouteTitle(event.target.value)}
            value={routeTitle}
          />
        }
      />
      <HStack pt={4}>
        <RouteDatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </HStack>
      <HStack style={{ width: "400px" }} pt={4}>
        {maxTimeAndDistance("Maximal drive time", maxTime, setMaxTime, "min")}
        {maxTimeAndDistance(
          "Maximal distance",
          maxDistance,
          setMaxDistance,
          "km"
        )}
      </HStack>
      {routeInfo.via && routeInfo.via.length > 0 && (
        <TitleAndBox
          title="Travels has to pass through:"
          boxContent={
            <ViaPointsCheckboxFilter
              viaPointIds={routeInfo.via}
              onIdSelect={(id) =>
                setSelectedViaPoints([...selectedViaPoints, id])
              }
              onIdDeselect={(id) =>
                setSelectedViaPoints(
                  selectedViaPoints.filter((via) => via !== id)
                )
              }
              getGeofenceName={getGeofenceName}
              checkedViaPoints={selectedViaPoints}
            />
          }
        />
      )}

      <HStack pt={7}>
        <Button onClick={onClose} size={"mediumAndLow"} variant="white">
          Cancel
        </Button>
        <Button
          onClick={() =>
            onSave(routeTitle, {
              startTime: getUnixMillisecondsFromDate(startDate),
              endTime: getUnixMillisecondsFromDate(endDate),
              maxDriveDistance: stringToIntOrUndefined(maxDistance),
              maxDriveTime: stringToIntOrUndefined(maxTime),
              hasToTravelViaIds: selectedViaPoints,
            })
          }
          size={"mediumAndLow"}
        >
          Save
        </Button>
      </HStack>
    </VStack>
  );
};
