import { Container, Flex, VStack } from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import CookieConsent from "react-cookie-consent";
import { useUser } from "../services/user.context";

export const DashboardLayout = () => {
  const { setUser } = useUser();
  const navigate = useNavigate();
  return (
    <Container className="App" px={0} width="100%" maxW="none">
      <Header />
      <VStack width="100%" maxW="none">
        <Flex
          as="main"
          w="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Outlet />
        </Flex>
        <CookieConsent
          enableDeclineButton
          buttonText="Accept"
          declineButtonText="Decline"
          setDeclineCookie={false}
          flipButtons
          expires={60}
          onDecline={() => {
            setUser(undefined);
            navigate("/login");
          }}
          buttonStyle={{
            color: "white",
            backgroundColor: "darkgreen",
          }}
          declineButtonStyle={{ color: "darkgreen", backgroundColor: "white" }}
        >
          This site uses cookies for enhanced functionality and user experience.
        </CookieConsent>
      </VStack>
      <Footer />
    </Container>
  );
};
