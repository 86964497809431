import { User } from "../types";

class LocalStorageService {
  private LOCALSTORAGE_KEY = "slxportaluser";
  private storage = localStorage;

  public getUser = (): User | null => {
    return JSON.parse(localStorage.getItem(this.LOCALSTORAGE_KEY) as string);
  };

  public setUser = (user: User) => {
    this.storage.setItem(this.LOCALSTORAGE_KEY, JSON.stringify(user));
  };

  public clearUser = () => this.storage.removeItem(this.LOCALSTORAGE_KEY);
}

export default new LocalStorageService();
