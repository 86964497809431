export const ArrowDownIcon = () => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.79289 8.20711C7.18342 8.59763 7.81658 8.59763 8.20711 8.20711L14.5711 1.84315C14.9616 1.45262 14.9616 0.819458 14.5711 0.428933C14.1805 0.038409 13.5474 0.0384089 13.1569 0.428933L7.5 6.08579L1.84315 0.428931C1.45262 0.0384067 0.819458 0.0384066 0.428933 0.428931C0.0384088 0.819455 0.0384087 1.45262 0.428933 1.84314L6.79289 8.20711ZM6.5 6.5L6.5 7.5L8.5 7.5L8.5 6.5L6.5 6.5Z"
        fill="#111111"
      />
    </svg>
  );
};
