import jwtDecode from "jwt-decode";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { RoleScopes } from "../../enum";
import { useUser } from "../../services/user.context";
import { TokenContent, User } from "../../types";
import {
  createAccount,
  logIn,
  manageUsers,
  startLink,
} from "../../utils/navigationLinks";
import { getFilteredServices } from "./FilterServices";
import localstorageService from "../../services/localstorage.service";

type Props = {
  setErrorMessage: (errorMessage: string | undefined) => void;
};

export const ProtectedRoutes = ({ setErrorMessage }: Props) => {
  const location = useLocation();
  const { user, setUser } = useUser();

  const userHasAccess = (user: User | undefined): boolean => {
    if (!user || !user.authToken) {
      setErrorMessage("User not found, please log in");
      return false;
    }
    const tokenContent: TokenContent = jwtDecode(user.authToken);
    const unixNow = Date.now() / 1000; // in seconds
    if (tokenContent.expires <= unixNow) {
      localstorageService.clearUser();
      setUser(undefined);
      setErrorMessage("User session expired, please log in again");
      return false;
    }

    const availableServices = getFilteredServices(user);
    if (location.pathname === startLink.href) {
      // Start page access
      return true;
    } else if (
      location.pathname === manageUsers.href &&
      user.roleScopes.includes(RoleScopes.ReadAdmin || RoleScopes.WriteAdmin) &&
      user.roleId < 3
    ) {
      // User management access
      return true;
    } else if (
      location.pathname === createAccount.href &&
      user.roleScopes.includes(RoleScopes.WriteAdmin) &&
      user.roleId < 3
    ) {
      // Create user access
      return true;
    } else if (
      availableServices
        .map((s) => s.navigationLink.href)
        .filter(url => location.pathname.startsWith(url)).length > 0
    ) {
      // Service access
      return true;
    } else {
      // User doesn't have access
      return false;
    }
  };

  return userHasAccess(user) ? (
    <Outlet />
  ) : (
    <Navigate to={logIn.href} replace />
  );
};
