import { geofenceToLatLng } from "../../../mapUtils";
import { useCallback } from "react";
import { RouteMonitorData } from "../../../../../types";
import { GeofenceSelectionType } from "../../../../../enum";
import L from "leaflet";
import "leaflet-arrowheads";

export const AllPolylines = (
  color: string,
  route: RouteMonitorData
) => {
  let fromLatLng = geofenceToLatLng(route.from);
  let toLatLng = geofenceToLatLng(route.to);

  return L.polyline([fromLatLng, toLatLng], {
    color: color,
    opacity: color === "red" ? 0.7 : 0.3,
  }).arrowheads({
    size: "15px",
    fill: true,
    color: color,
    fillOpacity: color === "red" ? 0.8 : 0.4,
  });
};
