import { Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { useUser } from "../../services/user.context";
import { Service } from "../../types";
import { getFilteredServices } from "../Authentication/FilterServices";
import { ServiceBox } from "./ServiceBox";

export const AllServices = () => {
  const { user } = useUser();
  const availableServices: Service[] = getFilteredServices(user);
  if (availableServices.length > 0) {
    return (
      <>
        <Heading pb={5} pt={16}>
          Services
        </Heading>
        <SimpleGrid
          px={6}
          columns={{ md: 3, lg: 4, sm: 2, base: 1 }}
          spacing={5}
        >
          {availableServices.map((service, i) => (
            <ServiceBox
              key={i}
              image={service.image}
              title={service.title}
              description={service.description}
              preview={service.preview}
              navigationLink={service.navigationLink}
            />
          ))}
        </SimpleGrid>
      </>
    );
  } else {
    return (
      <>
        <VStack>
          <Heading pb={5} pt={16}>
            Services
          </Heading>
          <Text>
            No services availble. Contact your company administrator for service
            access.
          </Text>
        </VStack>
      </>
    );
  }
};
