import { useCallback } from "react";
import { RouteMonitorData } from "../../../../../types";
import { postTtmMonitor } from "../../../../../services/services";
import { usePolylineRouteSnap } from "../../../../../state/polyline-route-state";
import { useGetRouteMonitorStats } from "./useGetRouteMonitorStats";
import { polylineTemporaryID } from "../../../mapUtils";

export const useAddRouteMonitor = () => {
  const routeMonitors = usePolylineRouteSnap().routeMonitors;
  const { getRouteMonitorStats } = useGetRouteMonitorStats();
  const addRouteMonitor = useCallback(
    async (routemonitor: RouteMonitorData) => {
      await postTtmMonitor({
        name: routemonitor.title,
        start_geofence_id: routemonitor.from.id,
        stop_geofence_id: routemonitor.to.id,
      })
        .then(async (response) => {
          if (routeMonitors.find((r) => r.id === response._id)) {
            return; // Route monitor already exists
          }
          routemonitor.id = response._id;
          routemonitor.title = response.name;
          await getRouteMonitorStats(response._id, routemonitor);
        })
        .then(() => {
          let tempArray = routeMonitors.filter(
            (item) => item.id !== polylineTemporaryID
          );
          tempArray.push(routemonitor);
        });
    },
    []
  );

  return { addRouteMonitor };
};
