export const getUnixMillisecondsNow = () => Date.now();

export const getUnixMillisecondsFromDate = (date: Date) =>
  new Date(date).getTime();

export const getUnixMilliseconds1WeekBack = () => {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return new Date(date).getTime();
};

export const convertMinutesToSeconds = (minutes: number) => minutes * 60;
