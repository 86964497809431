import {
  Button,
  ButtonGroup,
  Heading,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

type Props = {
  HeadingText: string;
  BodyText: string;
  ApplyButtonText: string;
  CancelButtonText?: string;

  isOpen?: boolean;
  SetIsOpen?: () => void;
  TriggerButton: JSX.Element;
  onApply: () => void;
  onCancel: () => void;
};

export const Alert = ({
  HeadingText,
  ApplyButtonText,
  CancelButtonText = "Cancel",
  BodyText,
  TriggerButton,
  SetIsOpen,
  isOpen = false,
  onApply,
  onCancel,
}: Props) => {
  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={SetIsOpen}
      placement="right"
    >
      <PopoverTrigger>{TriggerButton}</PopoverTrigger>
      <PopoverContent>
        <VStack m={2} alignContent={"center"}>
          <Heading mb={-1}>{HeadingText}</Heading>
          <Text lineHeight={"100%"} textAlign={"center"}>
            {BodyText}
          </Text>

          <ButtonGroup pt={2} size={"lg"}>
            <Button onClick={onCancel} variant="outline">
              {CancelButtonText}
            </Button>
            <Spacer />
            <Button onClick={onApply} variant={"destructive"}>
              {ApplyButtonText}
            </Button>
          </ButtonGroup>
        </VStack>
      </PopoverContent>
    </Popover>
  );
};
