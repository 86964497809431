import { useCallback } from "react";
import { getTtmMonitor } from "../../../../../services/services";
import { useMapState } from "../../../../../state/map_state";
import { RouteMonitorData } from "../../../../../types";
import { useGetRouteMonitorStats } from "./useGetRouteMonitorStats";
import { GeofenceData } from "../../../../../http/http";

export const useGetAllCreatedRouteMonitors = () => {
  const setLoading = useMapState();
  const { getRouteMonitorStats } = useGetRouteMonitorStats();

  const getAllCreatedRouteMonitors = useCallback(
    async (allGeofences: Array<GeofenceData>) => {
      await getTtmMonitor().then(async (monitors) => {
        let delay = 0;
        const delayIncrement = 500;
        const requests = monitors.map((monitor) => {
          const from = allGeofences.find(
            (geofence) => geofence.id === monitor.start_geofence_id
          );
          const to = allGeofences.find(
            (geofence) => geofence.id === monitor.stop_geofence_id
          );

          if (from !== undefined && to !== undefined) {
            let routeMonitor: RouteMonitorData = {
              title: monitor.name,
              id: monitor._id,
              from: from!,
              to: to!,
              via: monitor.gf_via,
            };
            return getRouteMonitorStats(monitor.id, routeMonitor);
          }
        });
        const promises = requests.map((r) => {
          delay += delayIncrement;
          new Promise((res) => setTimeout(res, delay)).then(() => r);
        });
        await Promise.all(promises).finally(() =>
          setLoading.setIsLoading(false)
        );
      });
    },
    []
  );

  return { getAllCreatedRouteMonitors };
};
