import { useCallback } from "react";
import { GeofenceSelectionType } from "../../../../../../enum";
import { useCircleGeofenceSnap } from "../../../../../../state/circle-geofence-state";
import { GeofenceData } from "../../../../../../http/http";

export const useCircleStyles = () => {
  const selectedGeofence = useCircleGeofenceSnap().selectedGeofence;
  const circleStyle = useCallback(
    (selected: GeofenceData) => {
      return {
        color:
          GeofenceSelectionType.Locations &&
          selected.id === selectedGeofence?.id
            ? "red"
            : "black",
        weight: 0,
        fillOpacity: 0.3,
      };
    },
    [selectedGeofence]
  );

  const circleMarkerStyle = useCallback(
    (selected: GeofenceData) => {
      return {
        color:
          GeofenceSelectionType.Locations &&
          selected.id === selectedGeofence?.id
            ? "red"
            : "black",
        fillOpacity: 1,
      };
    },
    [selectedGeofence]
  );

  return { circleStyle, circleMarkerStyle };
};
