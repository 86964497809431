import { useCallback } from "react";
import { postTtmGeofence } from "../../../../../services/services";
import { useCircleGeofenceState } from "../../../../../state/circle-geofence-state";
// import { creactGeofenceObject } from "../../../mapUtils";
import { useCircleGeofenceSnap } from "../../../../../state/circle-geofence-state";
import { Geofence } from "../../../../../http/http";

export const useAddGeofence = () => {
  // const selectedArea = useCircleGeofenceSnap().selectedGeofence;

  const addGeofence = useCallback(
    async (gf: Geofence) => {
      postTtmGeofence(gf).then((gf_response) => {
        useCircleGeofenceState().setOnGeofenceMode(null);
        useCircleGeofenceState().setSelectedGeofence(null);
        useCircleGeofenceState().addGeofence(
          gf_response
        );
      });
    },
    []
  );
  return { addGeofence };
};
