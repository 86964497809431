import { AxiosError } from "axios";
import { useToastbar } from "../../hooks/useToastbar";
import { postUser } from "../../services/services";
import { CreateUserInput } from "../../types";
import { CreateAccountPage } from "./CreateAccountPage";

export const CreateAccountContainer = () => {
  const toastbar = useToastbar();
  const onConfirm = ({
    name,
    email,
    roleId,
  }: Pick<CreateUserInput, "name" | "email" | "roleId">) => {
    postUser({
      name: name,
      email: email,
      role_id: roleId,
      deactivated: false,
    })
      .then(() => {
        toastbar({
          title: "User invitation sent",
          status: "success",
        });
      })
      .catch((error: AxiosError) => {
        let errorMessage;
        if (error.response && error.response.status === 422) {
          errorMessage = "Validation error on email or name";
        }
        toastbar({
          title: "Unable to invite user",
          description: errorMessage,
          status: "error",
        });
      });
  };

  return (
    <>
      <CreateAccountPage onConfirm={onConfirm} />
    </>
  );
};
