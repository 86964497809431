import { useEffect, useState } from "react";
import { useToastbar } from "../../hooks/useToastbar";
import { RSA } from "./RSA";
import {
  Annotation,
  CameraDetected,
  DiffStats,
  NVDBSign,
  SignCount,
} from "../../services/types";
import {
  getRsaAnnotations,
  getRsaAnnotations_diff_stats,
  getRsaDetected,
  getRsaDetected_diff_stats,
  getRsaNvdb,
  getRsaSigns_count,
} from "../../services/services";
import { AxiosError } from "axios";

export const RSAContainer = () => {
  const toastbar = useToastbar();
  const [isLoading, setIsLoading] = useState(true);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [nvdbSigns, setNvdbSigns] = useState<NVDBSign[]>([]);
  const [cameraDetected, setCameraDetected] = useState<CameraDetected[]>([]);
  const [detectedStats, setDetectedStats] = useState<DiffStats[]>([]);
  const [annotationsStats, setAnnotationsStats] = useState<DiffStats[]>([]);
  const [signCount, setSignCount] = useState<SignCount>({
    nvdb_count: 0,
    annotations_count: 0,
    detected_count: 0,
  });

  const handleError = (title: string, errorMessage: string) => {
    toastbar({
      title: title,
      description: errorMessage,
      status: "error",
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getRsaAnnotations()
      .then((response) => setAnnotations(response))
      .catch((error: AxiosError) => {
        handleError("Unable to load Annotaions", error.message);
      });

    getRsaNvdb()
      .then((response) => setNvdbSigns(response))
      .catch((error: AxiosError) => {
        handleError("Unable to load NVDB signs", error.message);
      });

    getRsaDetected()
      .then((response) => {
        setCameraDetected(response);
      })
      .catch((error: AxiosError) => {
        handleError("Unable to load camera detected signs", error.message);
      });

    getRsaDetected_diff_stats()
      .then((response) => {
        setDetectedStats(response);
      })
      .catch((error: AxiosError) => {
        handleError("Unable to load detected signs stats", error.message);
      });

    getRsaAnnotations_diff_stats()
      .then((response) => {
        setAnnotationsStats(response);
      })
      .catch((error: AxiosError) => {
        handleError("Unable to load anootated signs stats", error.message);
      });

    getRsaSigns_count()
      .then((signCount) => {
        setSignCount(signCount);
        setIsLoading(false);
      })
      .catch((error: AxiosError) => {
        handleError("Unable to load sign count", error.message);
      });
  }, []);

  return (
    <>
      <RSA
        isLoading={isLoading}
        annotations={annotations}
        nvdbSigns={nvdbSigns}
        cameraDetected={cameraDetected}
        detectedStats={detectedStats}
        annotationsStats={annotationsStats}
        signCount={signCount}
      />
    </>
  );
};
