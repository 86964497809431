import { Box, Button, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";

type MFASignUpProps = {
  mfaUrl: string;
};

export const MFASetup = ({ mfaUrl }: MFASignUpProps) => {
  const navigate = useNavigate();

  return (
    <Flex mb={28} mt={16} flexDirection="column" justify={"center"}>
      <VStack spacing={4}>
        <Heading as="h1">Multi-Factor Authentication Setup</Heading>
        <Box w={{ md: "md", base: "sm" }}>
          <VStack spacing={5}>
            <QRCode value={mfaUrl} />
            <Text>
              Scan the QR code above using the authenticator app of your choice,
              e.g. Microsoft Authenticator.
            </Text>
          </VStack>
        </Box>
        <Button
          size={"long"}
          variant="primary"
          onClick={() => navigate("/login")}
        >
          Back to Log In
        </Button>
      </VStack>
    </Flex>
  );
};
