import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useState } from "react";
import { SearchIcon } from "../../../assets/icons/icon";

type Props = {
  itemsArray: Array<any>;
  filter: (item: any, searchInpur: string) => boolean;
  setFilteredList: (item: Array<any>) => void;
};

export const SearchBox = ({ filter, itemsArray, setFilteredList }: Props) => {
  const [value, setValue] = useState("");
  const handleChange = (event: any) => {
    setValue(event.target.value);

    setFilteredList(
      itemsArray.filter((item) => filter(item, event.target.value))
    );
  };

  return (
    <InputGroup alignSelf={"flex-center"} w={"40%"} size="md">
      <Input
        value={value}
        onChange={handleChange}
        pr="4.5rem"
        placeholder="Search..."
      />
      <InputRightElement width="4.5rem">
        <SearchIcon />
      </InputRightElement>
    </InputGroup>
  );
};
