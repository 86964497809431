import { HStack, Text, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { CheckboxList } from "../Lists/CheckboxList/CheckboxList";
import { ArrowDownIcon } from "../../assets/icons/arrowDownIcon";
import { CheckboxServiceItem } from "../../types";

type Props = {
  disabled: boolean;
  checkboxServiceItems: Array<CheckboxServiceItem>;
  onUpdate: (item: CheckboxServiceItem) => void;
};

export const DropdownWithCheckbox = ({
  disabled,
  checkboxServiceItems,
  onUpdate,
}: Props) => {
  const [showCheckbox, setShowcheckbox] = useState(false);

  const dropdownTitle = () => {
    return `${
      checkboxServiceItems.filter(s => s.readIsChecked || s.writeIsChecked)
        .length
    }/${checkboxServiceItems.length} Services`;
  };

  return (
    <HStack
      marginRight={3}
      justifySelf={"end"}
      w={"70%"}
      onClick={() => setShowcheckbox(true)}
      justify={"space-between"}
      alignItems={"center"}
      borderRadius={5}
      borderWidth={1}
      borderColor={"brand.lineGrey"}
      px={3}
    >
      <Text>{dropdownTitle()}</Text>
      <IconButton
        size={"xs"}
        variant={"ghost"}
        colorScheme="whiteAlpha"
        aria-label="arrow"
        icon={<ArrowDownIcon />}
      />

      {showCheckbox && (
        <CheckboxList
          disabled={disabled}
          checkboxServiceItems={checkboxServiceItems}
          onUpdate={onUpdate}
          setShowcheckbox={() => setShowcheckbox(false)}
        />
      )}
    </HStack>
  );
};
