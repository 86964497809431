import { HStack, Text } from "@chakra-ui/react";

export const UsersAndCompanyUsersBar = () => {
  let titles = ["Email", "Services", "Remove"];

  return (
    <HStack
      borderRadius={3}
      justify={"space-between"}
      w={"100%"}
      backgroundColor="brand.darkGreen"
      mb={-2}
    >
      {titles.map((title, i) => {
        return (
          <Text
            key={i}
            fontSize={"2xl"}
            py={2}
            pl={5}
            pr={20}
            color={"brand.white"}
          >
            {title}
          </Text>
        );
      })}
    </HStack>
  );
};
