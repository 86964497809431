import { useCallback } from "react";
import { deleteTtmGeofenceId } from "../../../../../services/services";
import {
  useCircleGeofenceSnap,
  useCircleGeofenceState,
} from "../../../../../state/circle-geofence-state";
import { circleTemporaryID } from "../../../mapUtils";
import { usePolylineRouteState } from "../../../../../state/polyline-route-state";
import { GeofenceData } from "../../../../../http/http";

export const useDeleteGeofence = () => {
  const circleGeofenceState = useCircleGeofenceState();
  const selected = useCircleGeofenceSnap().selectedGeofence;

  const deleteGeofence = useCallback(
    async (geofence: GeofenceData) => {
      if (geofence.id !== circleTemporaryID) {
        await deleteTtmGeofenceId(geofence.id).then(() => {
          circleGeofenceState.deleteGeofence(geofence);
          usePolylineRouteState().setFilteredRouteMonitorIfGeofenceRemoved(
            geofence
          );
        });
      } else {
        circleGeofenceState.deleteGeofence(geofence);
      }
    },
    [selected]
  );

  return { deleteGeofence };
};
