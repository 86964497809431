import { Flex, Link } from "@chakra-ui/react";
import { LinkNameAndHref } from "../../types";

type Props = {
  links: Array<LinkNameAndHref>;
};

export const FooterLinks = ({ links }: Props) => {
  return (
    <Flex direction={"column"}>
      {links.map((link, i) => (
        <Link mt={4} color={"brand.white"} key={i} size="md" href={link.href}>
          {link.linkName}
        </Link>
      ))}
    </Flex>
  );
};
