import { useEffect, useState } from "react";
export const useCkeckIfMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(
    () => (window.innerWidth < 750 ? setIsMobile(true) : setIsMobile(false)),
    []
  );

  window.addEventListener("resize", () => {
    window.innerWidth < 750 ? setIsMobile(true) : setIsMobile(false);
  });

  return { isMobile };
};
