import { VStack, useOutsideClick } from "@chakra-ui/react";
import { useRef } from "react";
import { CheckboxServiceItem } from "../../../types";
import { CheckboxListTitle } from "./CheckboxListTitle";
import { DoubleCheckboxWithTitle } from "./DoubleCheckboxWithTitle";

type CheckboxListProps = {
  disabled?: boolean;
  checkboxServiceItems: Array<CheckboxServiceItem>;
  onUpdate: (item: CheckboxServiceItem) => void;
  setShowcheckbox: () => void;
};
export const CheckboxList = ({
  disabled,
  checkboxServiceItems,
  onUpdate,
  setShowcheckbox,
}: CheckboxListProps) => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  useOutsideClick({
    ref: ref,
    handler: () => setShowcheckbox(),
  });

  const onReadBoxChange = (checked: boolean, item: CheckboxServiceItem) => {
    item.readIsChecked = checked;
    onUpdate(item);
  };

  const onWriteBoxChange = (checked: boolean, item: CheckboxServiceItem) => {
    item.writeIsChecked = checked;
    onUpdate(item);
  };

  return (
    <VStack
      ref={ref}
      borderRadius={5}
      borderWidth={1}
      padding={4}
      alignItems={"start"}
      borderColor={"brand.lineGrey"}
      background={"brand.white"}
      position={"absolute"}
      zIndex={"overlay"}
    >
      <CheckboxListTitle />
      {checkboxServiceItems.map((item, i) => {
        return (
          <DoubleCheckboxWithTitle
            key={item.serviceName + i}
            title={item.serviceName}
            disabled={disabled || false}
            secondBoxDisabled={item.writeScope === undefined}
            initialState={{
              first: item.readIsChecked,
              second: item.writeIsChecked,
            }}
            onFirstBoxChange={(checked: boolean) =>
              onReadBoxChange(checked, item)
            }
            onSecondBoxChange={(checked: boolean) =>
              onWriteBoxChange(checked, item)
            }
          />
        );
      })}
    </VStack>
  );
};

/**
 <CheckboxGroup key={item.serviceName + "Group"} colorScheme={"green"}>
            <HStack key={item.serviceName} spacing={2}>
              <Checkbox // I think we need to rethink this whole component and get rid of the prop drilling
                // Simpify as much as possible
                // Start from the bottom, think about what the component needs and work your way up
                isDisabled={disabled || false}
                onChange={() => {
                  onChange(item);
                  item.readIsChecked = toggle(item.readIsChecked);
                }}
                //isChecked={item.readIsChecked}
                key={i}
              ></Checkbox>
              {item.writeIsChecked !== undefined && (
                <Checkbox
                  isDisabled={disabled || false}
                  onChange={e => {
                    if (item.writeIsChecked !== undefined) {
                      onChange(item);
                      // item.writeIsChecked = toggle(item.writeIsChecked);
                      item.writeIsChecked = !item.writeIsChecked;
                    }
                  }}
                  //isChecked={item.writeIsChecked}
                  key={item.serviceName + i}
                >
                  {item.serviceName}
                </Checkbox>
              )}
              {item.writeIsChecked === undefined && <>{item.serviceName}</>}
            </HStack>
          </CheckboxGroup>
 */
