import { Center, Container, HStack, Heading, Link, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { ApiUsers } from "../../components/ApiUsers/ApiUsersComponent";
import { ApiUser, getApiUsersTtmApiusersGet, getApiUsersTtmApiusersIdDelete, getApiUsersTtmApiusersPost } from "../../http/http";

type NewlyCreatedToken = {
  name: string,
  token: string,
  id: string
}

export const TravelTimesApiUsers = () => {
  const [apiUsers, setApiUsers] = useState<ApiUser[]>([]);

  if (apiUsers.length === 0) getApiUsersTtmApiusersGet().then(setApiUsers)

  const createToken = async (name: string) => {
    const newUser = await getApiUsersTtmApiusersPost({ name: name })
    setApiUsers(await getApiUsersTtmApiusersGet())
    return newUser
  }

  const deleteToken = async (id: string) => {
    await getApiUsersTtmApiusersIdDelete(id)
    setApiUsers(await getApiUsersTtmApiusersGet())
  }

  return (
    <VStack maxWidth={"container.xl"} w="full" margin={10}>
      <Center>
        <Link href="/travel-time-measurements">
          <Heading lineHeight="normal">TRAVEL TIME MEASUREMENT</Heading>
        </Link>
      </Center>

      <Container padding={5}>
        <ApiUsers apiUsers={apiUsers} onDelete={deleteToken} onCreate={createToken}></ApiUsers>
      </Container >

    </VStack >
  );
};
