import { useCallback } from "react";
import { useMapState } from "../../../../../state/map_state";
import { getTtmGeofence } from "../../../../../services/services";
import { useCircleGeofenceState } from "../../../../../state/circle-geofence-state";
import { useGetAllCreatedRouteMonitors } from "../../routes/functions/useGetAllCreatedMonitors";
import { GeofenceData } from "../../../../../http/http";

export const useGetAllCreatedGeofences = () => {
  const setLoading = useMapState();
  const setCircleGeofenceState = useCircleGeofenceState();
  const { getAllCreatedRouteMonitors } = useGetAllCreatedRouteMonitors();

  const getAllCreatedGeofences = useCallback(async () => {
    // Avoid making the same request multiple times
    if(setLoading.loading){
      return
    }
    setLoading.setIsLoading(true);

    await getTtmGeofence().then((gf_data) => {
      const tempArray: GeofenceData[] = gf_data.map((gf) =>
        gf
      );

      setCircleGeofenceState.setGeofencesState(tempArray);
      getAllCreatedRouteMonitors(tempArray);
    });
  }, []);

  return { getAllCreatedGeofences };
};
