import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { MapElement } from "../DataExplorationMapDataTypes";

type MarkerModalProps = {
  content: MapElement;
  isOpen: boolean;
  onClose: () => void;
};

const MarkerModal: React.FC<MarkerModalProps> = ({
  content,
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalHeader>Marker Details</ModalHeader>
      <ModalCloseButton />
      <ModalContent>
        <ModalBody>
          <Text>Latitude: {content.lat}</Text>
          <Text>Longitude: {content.lon}</Text>
          <Text>Unixtime: {content.unixtime}</Text>
          <AttributesTable attributes={content.data} />
          <Button onClick={onClose} size={"sm"}>
            Close
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

type TableProps = {
  attributes: Record<string, any>;
};

const AttributesTable: React.FC<TableProps> = ({ attributes }) => {
  const keys = Object.keys(attributes);
  return (
    <TableContainer>
      <Table variant={"simple"}>
        <TableCaption placement="top">Attributes</TableCaption>
        {keys.length === 0 && (
          <Thead>
            <Tr>
              <Th>No attributes</Th>
            </Tr>
          </Thead>
        )}
        {keys.length > 0 && (
          <>
            <Thead>
              <Tr>
                <Th>Key</Th>
                <Th>Value</Th>
              </Tr>
            </Thead>
            <Tbody>
              {keys.map((k, i) => {
                return (
                  <Tr key={i}>
                    <Td>{k}</Td>
                    <Td>{attributes[k] + ""}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default MarkerModal;
