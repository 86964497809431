import { Center, Heading, Link, VStack } from "@chakra-ui/react";
import { TravelTimesMapContainer } from "../../components/Map/TravelTimesMapContainer";

export const TravelTimesInteractiveMap = () => {

  return (
    <VStack maxWidth={"container.xl"} w="full" margin={10}>
      <Center>
        <Link href="/travel-time-measurements">
          <Heading lineHeight="normal">TRAVEL TIME MEASUREMENT</Heading>
        </Link>
      </Center>

      <TravelTimesMapContainer></TravelTimesMapContainer>

    </VStack >
  );
};
