//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from "axios";
import type { SwaggerResponse } from "./axiosConfig";
import { Http } from "./httpRequest";
//@ts-ignore
import qs from "qs";
import type {
  GetSubscriptionCompanyCompany_idQueryParams,
  GetTtmMonitorIdStatisticsQueryParams,
  GetAreGet_accident_dataQueryParams,
  GetAreGet_risk_scoreQueryParams,
  GetAreGet_markersQueryParams,
  GetLocationdataDatasetNameQueryParams,
  Annotation,
  LoginPayload,
  NewPasswordPayload,
  ResetTokenPayload,
  VerifyMfaPayload,
  CameraDetected,
  Company,
  CreateUser,
  DatasetData,
  DatasetsData,
  DiffStats,
  GetTokenData,
  Impersonator,
  LoginReturn,
  MeData,
  MeUpdate,
  Monitor,
  MonitorData,
  MonitorName,
  MonitorStatistics,
  NVDBSign,
  ResetToken,
  ScopeData,
  Service,
  SignCount,
  Subscription,
  SubscriptionCreate,
  SubscriptionRenew,
  Token,
  UidScopeData,
  UpdateCompany,
  UserData,
  UserEmail,
  UserUpdate,
} from "./types";
import { GeofenceData } from "../http/http";

const __DEV__ = process.env.NODE_ENV !== "production";

function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, "i");
    path = path.replace(re, obj[key]);
  });

  return path;
}

function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

/**
 *
 * Delete Company Subscription
 */
export const deleteSubscriptionCompanyCompany_idId = (
  company_id: string,
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteSubscriptionCompanyCompany_idId.key, { company_id, id }),
    undefined,
    undefined,
    _CONSTANT11,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteSubscriptionCompanyCompany_idId.key =
  "/subscription/company/{company_id}/{id}";

/**
 *
 * Delete Geofence
 */
export const deleteTtmGeofenceId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteTtmGeofenceId.key, { id }),
    undefined,
    undefined,
    _CONSTANT13,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteTtmGeofenceId.key = "/ttm/geofence/{id}";

/**
 *
 * Delete Monitoring
 */
export const deleteTtmMonitorId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteTtmMonitorId.key, { id }),
    undefined,
    undefined,
    _CONSTANT13,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteTtmMonitorId.key = "/ttm/monitor/{id}";

/**
 *
 * Delete User
 */
export const deleteUserId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteUserId.key, { id }),
    undefined,
    undefined,
    _CONSTANT3,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
deleteUserId.key = "/user/{id}";

/**
 *
 * Read Root
 */
export const get = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    get.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
get.key = "/";

/**
 *
 * Get Accident Data
 */
export const getAreGet_accident_data = (
  queryParams: GetAreGet_accident_dataQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getAreGet_accident_data.key,
    queryParams,
    undefined,
    _CONSTANT14,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getAreGet_accident_data.key = "/are/get_accident_data";

/**
 *
 * Get Markers
 */
export const getAreGet_markers = (
  queryParams: GetAreGet_markersQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getAreGet_markers.key,
    queryParams,
    undefined,
    _CONSTANT14,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getAreGet_markers.key = "/are/get_markers";

/**
 *
 * Get Risk Score
 */
export const getAreGet_risk_score = (
  queryParams: GetAreGet_risk_scoreQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getAreGet_risk_score.key,
    queryParams,
    undefined,
    _CONSTANT14,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getAreGet_risk_score.key = "/are/get_risk_score";

/**
 *
 * Get Companies
 */
export const getCompany = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Company[]>> => {
  return Http.getRequest(
    getCompany.key,
    undefined,
    undefined,
    _CONSTANT5,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCompany.key = "/company";

/**
 *
 * Get Company
 */
export const getCompanyId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Company>> => {
  return Http.getRequest(
    template(getCompanyId.key, { id }),
    undefined,
    undefined,
    _CONSTANT6,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getCompanyId.key = "/company/{id}";

/**
 *
 * Get Datasets
 */
export const getLocationdataDataset = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DatasetsData>> => {
  return Http.getRequest(
    getLocationdataDataset.key,
    undefined,
    undefined,
    _CONSTANT16,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getLocationdataDataset.key = "/locationdata/dataset";

/**
 *
 * Get Dataset
 */
export const getLocationdataDatasetName = (
  name: string,
  queryParams: GetLocationdataDatasetNameQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DatasetData[]>> => {
  return Http.getRequest(
    template(getLocationdataDatasetName.key, { name }),
    queryParams,
    undefined,
    _CONSTANT16,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getLocationdataDatasetName.key = "/locationdata/dataset/{name}";

/**
 *
 * Get Login Info
 */
export const getLogin = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GetTokenData>> => {
  return Http.getRequest(
    getLogin.key,
    undefined,
    undefined,
    _CONSTANT3,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getLogin.key = "/login";

/**
 *
 * Get Me
 */
export const getMeId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MeData>> => {
  return Http.getRequest(
    template(getMeId.key, { id }),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getMeId.key = "/me/{id}";

/**
 *
 * Get Annotations
 */
export const getRsaAnnotations = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Annotation[]>> => {
  return Http.getRequest(
    getRsaAnnotations.key,
    undefined,
    undefined,
    _CONSTANT15,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRsaAnnotations.key = "/rsa/annotations";

/**
 *
 * Get Annotations Diff Stats
 */
export const getRsaAnnotations_diff_stats = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DiffStats[]>> => {
  return Http.getRequest(
    getRsaAnnotations_diff_stats.key,
    undefined,
    undefined,
    _CONSTANT15,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRsaAnnotations_diff_stats.key = "/rsa/annotations_diff_stats";

/**
 *
 * Get Camera Detected
 */
export const getRsaDetected = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CameraDetected[]>> => {
  return Http.getRequest(
    getRsaDetected.key,
    undefined,
    undefined,
    _CONSTANT15,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRsaDetected.key = "/rsa/detected";

/**
 *
 * Get Detected Diff Stats
 */
export const getRsaDetected_diff_stats = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DiffStats[]>> => {
  return Http.getRequest(
    getRsaDetected_diff_stats.key,
    undefined,
    undefined,
    _CONSTANT15,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRsaDetected_diff_stats.key = "/rsa/detected_diff_stats";

/**
 *
 * Get Nvdb Data
 */
export const getRsaNvdb = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<NVDBSign[]>> => {
  return Http.getRequest(
    getRsaNvdb.key,
    undefined,
    undefined,
    _CONSTANT15,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRsaNvdb.key = "/rsa/nvdb";

/**
 *
 * Get Signs Count
 */
export const getRsaSigns_count = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<SignCount>> => {
  return Http.getRequest(
    getRsaSigns_count.key,
    undefined,
    undefined,
    _CONSTANT15,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getRsaSigns_count.key = "/rsa/signs_count";

/**
 *
 * Get All Scopes
 */
export const getScope = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ScopeData[]>> => {
  return Http.getRequest(
    getScope.key,
    undefined,
    undefined,
    _CONSTANT9,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getScope.key = "/scope";

/**
 *
 * Get All Service Scopes
 */
export const getScopeService = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UidScopeData[]>> => {
  return Http.getRequest(
    getScopeService.key,
    undefined,
    undefined,
    _CONSTANT9,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getScopeService.key = "/scope/service";

/**
 *
 * Get Scopes For Service
 */
export const getScopeServiceUid = (
  uid: number,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UidScopeData[]>> => {
  return Http.getRequest(
    template(getScopeServiceUid.key, { uid }),
    undefined,
    undefined,
    _CONSTANT9,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getScopeServiceUid.key = "/scope/service/{uid}";

/**
 *
 * Get Services
 */
export const getService = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Service[]>> => {
  return Http.getRequest(
    getService.key,
    undefined,
    undefined,
    _CONSTANT8,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getService.key = "/service";

/**
 *
 * Get Service
 */
export const getServiceId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Service>> => {
  return Http.getRequest(
    template(getServiceId.key, { id }),
    undefined,
    undefined,
    _CONSTANT8,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getServiceId.key = "/service/{id}";

/**
 *
 * Get All Subscriptions
 */
export const getSubscription = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Subscription[]>> => {
  return Http.getRequest(
    getSubscription.key,
    undefined,
    undefined,
    _CONSTANT5,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getSubscription.key = "/subscription";

/**
 *
 * Get Company Subscriptions
 */
export const getSubscriptionCompanyCompany_id = (
  company_id: string,
  queryParams?: GetSubscriptionCompanyCompany_idQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Subscription[]>> => {
  return Http.getRequest(
    template(getSubscriptionCompanyCompany_id.key, { company_id }),
    queryParams,
    undefined,
    _CONSTANT10,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getSubscriptionCompanyCompany_id.key = "/subscription/company/{company_id}";

/**
 *
 * Get Company Subscription
 */
export const getSubscriptionCompanyCompany_idId = (
  company_id: string,
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Subscription>> => {
  return Http.getRequest(
    template(getSubscriptionCompanyCompany_idId.key, { company_id, id }),
    undefined,
    undefined,
    _CONSTANT10,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getSubscriptionCompanyCompany_idId.key =
  "/subscription/company/{company_id}/{id}";

/**
 *
 * Get Geofences
 */
export const getTtmGeofence = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GeofenceData[]>> => {
  return Http.getRequest(
    getTtmGeofence.key,
    undefined,
    undefined,
    _CONSTANT12,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTtmGeofence.key = "/ttm/geofence";

/**
 *
 * Get Geofence
 */
export const getTtmGeofenceId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GeofenceData>> => {
  return Http.getRequest(
    template(getTtmGeofenceId.key, { id }),
    undefined,
    undefined,
    _CONSTANT12,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTtmGeofenceId.key = "/ttm/geofence/{id}";

/**
 *
 * Get Monitorings
 */
export const getTtmMonitor = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MonitorData[]>> => {
  return Http.getRequest(
    getTtmMonitor.key,
    undefined,
    undefined,
    _CONSTANT12,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTtmMonitor.key = "/ttm/monitor";

/**
 *
 * Get Monitoring
 */
export const getTtmMonitorId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MonitorData>> => {
  return Http.getRequest(
    template(getTtmMonitorId.key, { id }),
    undefined,
    undefined,
    _CONSTANT12,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTtmMonitorId.key = "/ttm/monitor/{id}";

/**
 *
 * Get Monitoring Statistics With Filters
 */
export const getTtmMonitorIdStatistics = (
  id: string,
  queryParams: GetTtmMonitorIdStatisticsQueryParams,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MonitorStatistics>> => {
  return Http.getRequest(
    template(getTtmMonitorIdStatistics.key, { id }),
    queryParams,
    undefined,
    _CONSTANT12,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getTtmMonitorIdStatistics.key = "/ttm/monitor/{id}/statistics";

/**
 *
 * Get Active Users
 */
export const getUser = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserData[]>> => {
  return Http.getRequest(
    getUser.key,
    undefined,
    undefined,
    _CONSTANT3,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUser.key = "/user";

/**
 *
 * Get Deactivated Users
 */
export const getUserDeactivated = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserData[]>> => {
  return Http.getRequest(
    getUserDeactivated.key,
    undefined,
    undefined,
    _CONSTANT3,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUserDeactivated.key = "/user/deactivated";

/**
 *
 * Get User
 */
export const getUserId = (
  id: string,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserData>> => {
  return Http.getRequest(
    template(getUserId.key, { id }),
    undefined,
    undefined,
    _CONSTANT3,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
getUserId.key = "/user/{id}";

/**
 *
 * Create Company
 */
export const postCompany = (
  requestBody: Company,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Company>> => {
  return Http.postRequest(
    postCompany.key,
    undefined,
    requestBody,
    _CONSTANT5,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCompany.key = "/company";

/**
 *
 * Update Company
 */
export const postCompanyId = (
  id: string,
  requestBody: UpdateCompany,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Company>> => {
  return Http.postRequest(
    template(postCompanyId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT7,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postCompanyId.key = "/company/{id}";

/**
 *
 * Login
 */
export const postLogin = (
  requestBody: LoginPayload,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LoginReturn>> => {
  return Http.postRequest(
    postLogin.key,
    undefined,
    objToUrlencoded(requestBody),
    undefined,
    overrideConfig(_CONSTANT4, configOverride)
  );
};

/** Key is end point string without base url */
postLogin.key = "/login";

/**
 *
 * Get Impersonator Token
 */
export const postLoginImpersonator = (
  requestBody: Impersonator,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Token>> => {
  return Http.postRequest(
    postLoginImpersonator.key,
    undefined,
    requestBody,
    _CONSTANT5,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postLoginImpersonator.key = "/login/impersonator";

/**
 *
 * Verify Mfa
 */
export const postLoginMfaVerify = (
  requestBody: VerifyMfaPayload,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Token>> => {
  return Http.postRequest(
    postLoginMfaVerify.key,
    undefined,
    objToUrlencoded(requestBody),
    undefined,
    overrideConfig(_CONSTANT4, configOverride)
  );
};

/** Key is end point string without base url */
postLoginMfaVerify.key = "/login/mfa/verify";

/**
 *
 * Forgot Password
 */
export const postLoginPasswordForgot = (
  requestBody: UserEmail,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postLoginPasswordForgot.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postLoginPasswordForgot.key = "/login/password/forgot";

/**
 *
 * New Password
 */
export const postLoginPasswordNew = (
  requestBody: NewPasswordPayload,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postLoginPasswordNew.key,
    undefined,
    objToUrlencoded(requestBody),
    _CONSTANT3,
    overrideConfig(_CONSTANT4, configOverride)
  );
};

/** Key is end point string without base url */
postLoginPasswordNew.key = "/login/password/new";

/**
 *
 * Refresh Token
 */
export const postLoginRefresh = (
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Token>> => {
  return Http.postRequest(
    postLoginRefresh.key,
    undefined,
    undefined,
    _CONSTANT3,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postLoginRefresh.key = "/login/refresh";

/**
 *
 * Reset Token
 */
export const postLoginTokenReset = (
  requestBody: ResetTokenPayload,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ResetToken>> => {
  return Http.postRequest(
    postLoginTokenReset.key,
    undefined,
    objToUrlencoded(requestBody),
    undefined,
    overrideConfig(_CONSTANT4, configOverride)
  );
};

/** Key is end point string without base url */
postLoginTokenReset.key = "/login/token/reset";

/**
 *
 * Update Me
 */
export const postMeId = (
  id: string,
  requestBody: MeUpdate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MeUpdate>> => {
  return Http.postRequest(
    template(postMeId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT2,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postMeId.key = "/me/{id}";

/**
 *
 * Create Company Subscription
 */
export const postSubscriptionCompanyCompany_id = (
  company_id: string,
  requestBody: SubscriptionCreate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Subscription>> => {
  return Http.postRequest(
    template(postSubscriptionCompanyCompany_id.key, { company_id }),
    undefined,
    requestBody,
    _CONSTANT5,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postSubscriptionCompanyCompany_id.key = "/subscription/company/{company_id}";

/**
 *
 * Renew Company Subscription
 */
export const postSubscriptionCompanyCompany_idId = (
  company_id: string,
  id: string,
  requestBody: SubscriptionRenew,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Subscription>> => {
  return Http.postRequest(
    template(postSubscriptionCompanyCompany_idId.key, { company_id, id }),
    undefined,
    requestBody,
    _CONSTANT11,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postSubscriptionCompanyCompany_idId.key =
  "/subscription/company/{company_id}/{id}";

/**
 *
 * Create Geofence
 */
export const postTtmGeofence = (
  requestBody: Geofence,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<GeofenceData>> => {
  return Http.postRequest(
    postTtmGeofence.key,
    undefined,
    requestBody,
    _CONSTANT13,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTtmGeofence.key = "/ttm/geofence";

/**
 *
 * Create Monitor
 */
export const postTtmMonitor = (
  requestBody: Monitor,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MonitorData>> => {
  return Http.postRequest(
    postTtmMonitor.key,
    undefined,
    requestBody,
    _CONSTANT13,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postTtmMonitor.key = "/ttm/monitor";

/**
 *
 * Create User
 */
export const postUser = (
  requestBody: CreateUser,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserData>> => {
  return Http.postRequest(
    postUser.key,
    undefined,
    requestBody,
    _CONSTANT3,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postUser.key = "/user";

/**
 *
 * Update User
 */
export const postUserId = (
  id: string,
  requestBody: UserUpdate,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserUpdate>> => {
  return Http.postRequest(
    template(postUserId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT3,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
postUserId.key = "/user/{id}";

/**
 *
 * Update Geofence Name
 */
export const putTtmGeofenceId = (
  id: string,
  requestBody: GeofenceName,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putTtmGeofenceId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT13,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTtmGeofenceId.key = "/ttm/geofence/{id}";

/**
 *
 * Update Monitor Name
 */
export const putTtmMonitorId = (
  id: string,
  requestBody: MonitorName,
  configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MonitorData>> => {
  return Http.putRequest(
    template(putTtmMonitorId.key, { id }),
    undefined,
    requestBody,
    _CONSTANT13,
    overrideConfig(_CONSTANT0, configOverride)
  );
};

/** Key is end point string without base url */
putTtmMonitorId.key = "/ttm/monitor/{id}";
export const _CONSTANT0 = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
export const _CONSTANT1 = [{ OAuth2PasswordBearer: ["read_me"] }];
export const _CONSTANT10 = [{ OAuth2PasswordBearer: ["read_subscribe"] }];
export const _CONSTANT11 = [{ OAuth2PasswordBearer: ["write_subscribe"] }];
export const _CONSTANT12 = [{ OAuth2PasswordBearer: ["read_ttm"] }];
export const _CONSTANT13 = [{ OAuth2PasswordBearer: ["write_ttm"] }];
export const _CONSTANT14 = [
  { OAuth2PasswordBearer: ["read_accident_risk_estimation"] },
];
export const _CONSTANT15 = [{ OAuth2PasswordBearer: ["read_road_sign_info"] }];
export const _CONSTANT16 = [{ OAuth2PasswordBearer: ["read_location_data"] }];
export const _CONSTANT2 = [{ OAuth2PasswordBearer: ["write_me"] }];
export const _CONSTANT3 = [{ OAuth2PasswordBearer: [] }];
export const _CONSTANT4 = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  },
};
export const _CONSTANT5 = [{ OAuth2PasswordBearer: ["super_admin"] }];
export const _CONSTANT6 = [{ OAuth2PasswordBearer: ["read_company_info"] }];
export const _CONSTANT7 = [{ OAuth2PasswordBearer: ["write_company_info"] }];
export const _CONSTANT8 = [{ OAuth2PasswordBearer: ["read_service"] }];
export const _CONSTANT9 = [{ OAuth2PasswordBearer: ["read_scope"] }];
