import { VStack, Box, Button, Link, HStack, Heading } from "@chakra-ui/react";
import { CMSButton } from "../../components/CMS/CMSButton";
import { useState } from "react";
import { services } from "../../components/ServiceBox/services";
import CMS from "../../components/CMS/CMS";

export const RekorOne = () => {
  const [showCms, setShowCms] = useState(false);
  const serviceUid =
    services.find((s) => s.title === "Traffic Management")?.id || 0;
  return (
    <VStack maxWidth={"container.xl"} w="full" margin={10}>
      <HStack alignItems={"center"}>
        <Heading lineHeight="normal">TRAFFIC MANAGEMENT BY REKOR</Heading>
        <CMSButton
          buttonText={showCms ? "Return" : "About"}
          variant={showCms ? undefined : "white"}
          onClick={() => setShowCms(!showCms)}
        />
      </HStack>
      {showCms && <CMS serviceUid={serviceUid} />}
      {!showCms && (
        <VStack padding={100}>
          <Box>
            <Button size={"medium"}>
              <Link
                href="https://desktop.waycare.io/live-map#57.695188676344685,11.971978624034397,12.25z"
                isExternal
              >
                Open RekorOne Client
              </Link>
            </Button>
          </Box>
        </VStack>
      )}
    </VStack>
  );
};
