import { Text } from "@chakra-ui/react";
import { RouteMonitorData } from "../types";
import { charToDots } from "../utils/check-utils";

type Props = {
  route: RouteMonitorData;
  maxW?: string;
};

export const RouteName = ({ route, maxW = "non" }: Props) => {
  return (
    <>
      <Text maxW={maxW} pl={1} fontSize={"xl"}>
        <b style={styles.textStyle}>{charToDots(route.title, 20)}</b>
      </Text>
    </>
  );
};

const styles = {
  textStyle: {
    textShadow: "1px 0 #000",
  },
};
