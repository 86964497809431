import {
  Text,
  Checkbox,
  Box,
  HStack,
  Heading,
  Stack,
  VStack,
} from "@chakra-ui/react";
import React from "react";

type DataSelectorProps = {
  dataSets: string[];
  onDataSetSelect: (dataset: string, checked: boolean) => void;
};

export const DataSelector: React.FC<DataSelectorProps> = ({
  dataSets,
  onDataSetSelect,
}) => {
  const chunk = (arr: any[], size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
      arr.slice(i * size, i * size + size)
    );

  const dataSetChunks = chunk(dataSets, 3);

  return (
    <Box borderWidth={"1px"}>
      <Stack spacing={2} padding={2}>
        <Heading size={"md"}>Select data sets</Heading>
        <VStack spacing={2} alignItems={"flex-start"}>
          {dataSets.length >= 1 &&
            dataSetChunks.map((chunk, i) => (
              <HStack key={i} alignItems={"flex-start"}>
                {chunk.map((ds, j) => (
                  <Checkbox
                    onChange={(e) => {
                      onDataSetSelect(ds, e.target.checked);
                    }}
                    key={j}
                  >
                    {ds}
                  </Checkbox>
                ))}
              </HStack>
            ))}
        </VStack>
        {dataSets.length < 1 && (
          <Text fontSize={"sm"} as={"i"}>
            No data sets available
          </Text>
        )}
      </Stack>
    </Box>
  );
};
