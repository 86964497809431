import { GeofenceData } from "../../../http/http";
import { RouteMonitorData } from "../../../types";

const ckeckIfIncludes = (firstValue: string | undefined, secondValue: string) =>
  firstValue?.toLowerCase().includes(secondValue.toLowerCase()) ?? false;

export const routeFilter = (item: RouteMonitorData, searchInpur: string) =>
  ckeckIfIncludes(item?.title, searchInpur);

export const geofenceFilter = (item: GeofenceData, searchInpur: string) =>
  ckeckIfIncludes(item?.name, searchInpur);
