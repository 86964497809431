import {
  Box,
  Checkbox,
  HStack,
  VStack,
  Button,
  Stack,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { DataSelector } from "./DataSelector";
import DatePicker from "react-datepicker";
import { TitleAndBox } from "../../EditRoute/TitleAndBox";
import { MapFilters } from "../DataExplorationMapDataTypes";

type ToolBoxProps = {
  dataSets: string[];
  onDataSetsLoad: (filters: MapFilters) => void;
  onClusterSelect: (value: boolean) => void;
  onDataSetSelect: (dataSetTitle: string) => void;
  onDataSetDeselect: (dataSetTitle: string) => void;
  filtersDisabled: boolean;
};

export const ToolBox: React.FC<ToolBoxProps> = ({
  dataSets,
  onDataSetsLoad,
  onClusterSelect,
  onDataSetSelect,
  onDataSetDeselect,
  filtersDisabled,
}) => {
  const [currentFilter, setCurrentFilter] = useState<MapFilters>({
    start: null,
    end: null,
    maxDataCount: 500,
  });

  const handleDataSetSelect = (dataSet: string, isSelected: boolean) => {
    if (isSelected) {
      // Add selected data set
      onDataSetSelect(dataSet);
    } else {
      // Remove unselected data set
      onDataSetDeselect(dataSet);
    }
  };

  return (
    <Box borderWidth="1px">
      <VStack spacing={5} alignItems={"start"} padding={5}>
        <DataSelector
          dataSets={dataSets}
          onDataSetSelect={(dataSet, isChecked) =>
            handleDataSetSelect(dataSet, isChecked)
          }
        />
        <HStack spacing={5}>
          <Checkbox
            defaultChecked
            onChange={(e) => onClusterSelect(e.target.checked)}
            colorScheme="green"
            isDisabled={filtersDisabled}
          >
            Cluster markers
          </Checkbox>
        </HStack>
        <Box borderWidth="1px">
          <Stack spacing={2} padding={2}>
            <Heading size={"md"}>Filters</Heading>
            <HStack spacing={2}>
              <Text>Max number of data points:</Text>
              <Input
                value={currentFilter.maxDataCount}
                onChange={(e) => {
                  setCurrentFilter({
                    ...currentFilter,
                    maxDataCount: parseInt(e.target.value) || 0,
                  });
                }}
                htmlSize={4}
                width="auto"
                isDisabled={filtersDisabled}
              />
            </HStack>
            <HStack>
              <TitleAndBox
                title="Data from"
                boxContent={
                  <DatePicker
                    showTimeSelect
                    selected={currentFilter.start}
                    onChange={(date: Date | null) =>
                      setCurrentFilter({ ...currentFilter, start: date })
                    }
                    className="date-picker-grey-border"
                    disabled={filtersDisabled}
                    isClearable
                  />
                }
              />
              <TitleAndBox
                title="Data to"
                boxContent={
                  <DatePicker
                    showTimeSelect
                    selected={currentFilter.end}
                    onChange={(date: Date | null) =>
                      setCurrentFilter({ ...currentFilter, end: date })
                    }
                    className="date-picker-grey-border"
                    disabled={filtersDisabled}
                    isClearable
                  />
                }
              />
            </HStack>
          </Stack>
        </Box>
        <Button
          isDisabled={filtersDisabled}
          size={"sm"}
          onClick={() => onDataSetsLoad(currentFilter)}
        >
          Load data with filters
        </Button>
      </VStack>
    </Box>
  );
};
