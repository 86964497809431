import { HStack, Heading, Text, VStack } from "@chakra-ui/react";
import { TravelTimesMap } from "../../components/Map";
import { useState } from "react";
import CMS from "../../components/CMS/CMS";
import { CMSButton } from "../../components/CMS/CMSButton";
import { services } from "../../components/ServiceBox/services";
import { Link } from "react-router-dom";

export const TravelTimeEstimation = () => {
  const [showCms, setShowCms] = useState(false);
  const serviceUid =
    services.find((s) => s.title === "Travel Time Measurements")?.id || 0;
  return (
    <VStack maxWidth={"container.xl"} w="full" margin={10}>
      <HStack alignItems={"center"}>
        <Heading lineHeight="normal">TRAVEL TIME MEASUREMENT</Heading>
        <CMSButton
          buttonText={showCms ? "Return" : "About"}
          variant={showCms ? undefined : "white"}
          onClick={() => setShowCms(!showCms)}
        />
      </HStack>
      <Text textAlign={"center"} pb={5}>
        Collect travel time statistics for cars driving from one location to the other.
        <br />
        Optionally you can add via points for filtering the travel time statistics.
      </Text>
      {!showCms && <>
        <Link to="/travel-time-measurements/map" style={{ fontWeight: "bold" }}>INTERACTIVE MAP</Link>
        <Link to="/travel-time-measurements/apiusers" style={{ fontWeight: "bold" }}>API TOKENS</Link>
      </>}
      {showCms && <CMS serviceUid={serviceUid} />}
    </VStack>
  );
};
