import { HStack, Text } from "@chakra-ui/react";

export const CheckboxListTitle = () => {
  return (
    <HStack spacing={1}>
      <Text>Read</Text>
      <Text>Write</Text>
    </HStack>
  );
};
