import L, { LatLngTuple } from "leaflet";
import { Geofence, GeofenceData } from "../../http/http";
import { MonitorStatistics } from "../../http/http";
import { RouteMonitorData } from "../../types";

export const circleTemporaryID = "circleTemporaryID";
export const polylineTemporaryID = "polylineTemporaryID";

export const polylineTemplate = (from: GeofenceData, to: GeofenceData) => {
  return {
    title: from.name !== to.name ? from.name + " - " + to.name : "Temporary",
    id: polylineTemporaryID,
    from: from,
    to: to,
    via: [],
  };
};

export const createRouteMonitorObject = (
  title: string,
  id: string,
  startGeofence: GeofenceData,
  stopGeofence: GeofenceData,
  via: string[],
  stats: MonitorStatistics
): RouteMonitorData => {
  return {
    title: title,
    id: id,
    from: startGeofence,
    to: stopGeofence,
    via: via || [],
    travelTimeStats: stats,
  };
};

export const convertSecondsToHHMM = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().slice(11, 16);
};

export const convertMetersToKM = (meters: number) => {
  const km = meters / 1000;
  return km.toFixed(1);
};

export function convertSecondsToHoursAndMinutes(seconds: number) {
  var min = seconds / 60;
  var hours = min / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);

  if (rhours === 0 && rminutes === 0) {
    return seconds + "s";
  } else if (rhours === 0) {
    return rminutes + " min";
  } else if (minutes === 0) {
    return rhours + "h";
  } else {
    return rhours + "h " + rminutes + " min";
  }
}
export const convertKmToMeters = (km: number) => km * 1000;

export function geofenceToLatLng(gf: Geofence) {
  if (gf.geometry.circle) {
    return L.latLng(gf.geometry.circle.la, gf.geometry.circle.lo);
  }
  if (gf.geometry.polygon) {
    let coords = gf.geometry.polygon.coordinates;
    let centerLa = coords.reduce((acc, c) => acc + c.la, 0) / coords.length;
    let centerLo = coords.reduce((acc, c) => acc + c.lo, 0) / coords.length;

    return L.latLng(centerLa, centerLo);
  }

  throw new Error("Geofence data is not valid");
}
