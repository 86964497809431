import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToastbar } from "../../hooks/useToastbar";
import { CreatePasswordInput } from "../../types";
import { CreatePassword } from "./CreatePassword";
import { postLoginPasswordNew } from "../../services/services";
import { AxiosError, AxiosRequestConfig } from "axios";

export const CreatePasswordContainer = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const resetToken = searchParams.get("reset_token");
  const toastbar = useToastbar();

  useEffect(() => {
    if (!resetToken) {
      navigate("/login");
    }
  }, [resetToken]);

  const onSubmit = ({ password, confirmPassword }: CreatePasswordInput) => {
    if (password !== confirmPassword) {
      toastbar({ title: "Passwords must match", status: "error" });
      return;
    }
    const configOverride: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + resetToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    postLoginPasswordNew(
      { username: "test@example.com", password: password },
      configOverride
    )
      .then(() => {
        toastbar({ title: "Password has been set", status: "success" });
      })
      .catch((requestError: AxiosError) => {
        if (requestError.response && requestError.response?.status === 401) {
          toastbar({
            title: "Unable to set password",
            status: "error",
            description: "Token is invalid, please resend invite and try again",
          });
        } else if (
          requestError.response &&
          requestError.response?.status === 422
        ) {
          toastbar({
            title: "Unable to set password",
            status: "error",
            description:
              "Validation error, pick another password and try again",
          });
        } else {
          toastbar({
            title: "An error occured",
            status: "error",
            description:
              requestError?.response?.statusText || requestError.message,
          });
        }
      });
  };

  return (
    <>
      <CreatePassword onSubmit={onSubmit} />
    </>
  );
};
