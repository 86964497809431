import { proxy, useSnapshot } from "valtio";
import { circleTemporaryID } from "../components/Map/mapUtils";
import { GeofenceData } from "../http/http";

export enum GeofenceMode {
  Scaling = "scaling",
  GivingAName = "givingName",
  Editing = "editing",
  Non = "Non",
}

export class CircleGeofenceState {
  geofences: GeofenceData[] = [];
  geofenceMode: GeofenceMode | null = null;
  selectedGeofence: GeofenceData | null = null;
  inputName: string = "";

  addGeofence(geofences: GeofenceData) {
    this.geofences.push(geofences);
  }

  setInputName = (inputName: string) => {
    this.inputName = inputName;
  };

  setSelectedGeofence(geofence: GeofenceData | null) {
    if (geofence?.id == this.selectedGeofence?.id) {
      this.selectedGeofence = null;
    } else {
      this.selectedGeofence = geofence;
    }
  }

  changeGeofenceName(name: string) {
    if (this.selectedGeofence !== null) {
      this.selectedGeofence.name = name;
    }
  }

  edditGeofence(id: string, name: string) {
    let objIndex = this.geofences.findIndex((obj) => obj.id === id);
    this.geofences[objIndex].name = name;
  }

  changeGeofenceRadius(radius: number) {
    let circle = this.selectedGeofence?.geometry.circle;
    if (circle) {
      circle.radius = radius;
    }
  }

  setOnGeofenceMode(changeMode: GeofenceMode | null) {
    if (
      changeMode === GeofenceMode.Editing
    ) {
      this.geofenceMode = this.geofenceMode;
    } else if (changeMode === this.geofenceMode) {
      this.geofenceMode = null;
      this.selectedGeofence = null;
    } else {
      this.geofenceMode = changeMode;
    }
  }

  addNewGeofenceToGeofencesArray(geofence: GeofenceData) {
    this.geofences.push(geofence);
  }

  setGeofencesState(geofence: GeofenceData[]) {
    if (geofence !== null) {
      this.geofences = geofence;
    }
  }
  deleteGeofence(geofence: GeofenceData) {
    let g = this.geofences.filter((l: GeofenceData) => l.id !== geofence.id);
    this.geofences = g;
    this.setOnGeofenceMode(null);
    this.setSelectedGeofence(null);
  }
}

export const circleGeofenceState = proxy(new CircleGeofenceState());

export const useCircleGeofenceSnap = () => useSnapshot(circleGeofenceState);
export const useCircleGeofenceState = () => circleGeofenceState;
