import {
  Button,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DropdownWithCheckbox } from "../../components/Dropdown/DropdownWithCheckbox";
import { ThreeColumnLists } from "../../components/Lists/ThreeColumnLists";
import { UsersAndCompanyUsersBar } from "../../components/TitlesBars/UsersAndCompanyUsersBar";
import { useUser } from "../../services/user.context";
import { CheckboxServiceItem, User } from "../../types";
import { createAccount } from "../../utils/navigationLinks";

type ManageUsersProps = {
  users: Array<User>;
  serviceItems: Array<CheckboxServiceItem>;
  updateUser: (user: User, item: CheckboxServiceItem) => void;
  removeUser: (user: User) => void;
};

export const ManageUsers = ({
  updateUser,
  removeUser,
  serviceItems,
  users,
}: ManageUsersProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { user: currentUser } = useUser();
  const [userAndServices, setUsersAndServices] = useState<
    {
      user: User;
      serviceList: CheckboxServiceItem[];
    }[]
  >([]);

  useEffect(() => {
    if (serviceItems.length > 0 && users.length > 0) {
      const list = users.map((user) => {
        const myList: CheckboxServiceItem[] = [];
        serviceItems.forEach((item) => myList.push(Object.assign({}, item)));
        return {
          user,
          serviceList: initiateServiceItems(user, myList),
        };
      });
      setUsersAndServices(list);
      setIsLoading(false);
    }
  }, [users, serviceItems]);

  const initiateServiceItems = (
    user: User,
    serviceItems: CheckboxServiceItem[]
  ): CheckboxServiceItem[] => {
    return serviceItems.map((item) => {
      item.readIsChecked = user.serviceScopes.includes(item.readScope);
      item.writeIsChecked = item.writeScope
        ? user.serviceScopes.includes(item.writeScope)
        : undefined;
      return item;
    });
  };

  return (
    <VStack w={"95%"}>
      <HStack mt={5} w={"100%"} justify={"space-between"}>
        <Heading size="3xl">company users</Heading>
        <Button
          onClick={() => navigate(createAccount.href)}
          marginRight={2}
          size={"long"}
          variant={"secondary"}
        >
          Invite company users
        </Button>
      </HStack>
      <UsersAndCompanyUsersBar />
      {isLoading && (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={"brand.lineGrey"}
          color={"brand.darkGreen"}
          size="xl"
        />
      )}
      {!isLoading && (
        <>
          {userAndServices.map((userWithServices, i) => {
            const disabled = userWithServices.user.id === currentUser?.id;
            return (
              <Stack key={i} w={"100%"}>
                <ThreeColumnLists
                  first={<Text px={2}>{userWithServices.user.email}</Text>}
                  second={
                    <DropdownWithCheckbox
                      disabled={disabled}
                      onUpdate={(item: CheckboxServiceItem) =>
                        updateUser(userWithServices.user, item)
                      }
                      checkboxServiceItems={userWithServices.serviceList}
                    ></DropdownWithCheckbox>
                  }
                  third={
                    <Button
                      isDisabled={disabled}
                      marginRight={2}
                      justifySelf={"end"}
                      size={"mediumAndLow"}
                      variant={"destructive"}
                      onClick={() => removeUser(userWithServices.user)}
                    >
                      Remove user
                    </Button>
                  }
                />
              </Stack>
            );
          })}
        </>
      )}
    </VStack>
  );
};
