import { Box, HStack, Heading, Text } from "@chakra-ui/react";
import BarChart from "../../../components/Statistics/BarChart/BarChart";
import { DiffStats } from "../../../services/types";

type BarChartBoxProps = {
  detectedStats: DiffStats[];
  annotationsStats: DiffStats[];
};

export const BarChartBox: React.FC<BarChartBoxProps> = ({
  detectedStats,
  annotationsStats,
}) => {
  const chartData = {
    labels: ["10 m", "20 m", "50 m", "100 m"],
    datasets: [
      {
        label: "Detected signs",
        data: detectedStats.map((d) => d.no_signs),
        backgroundColor: "rgba(75, 192, 192, 0.7)",
      },
      {
        label: "Annotated signs",
        data: annotationsStats.map((a) => a.no_signs),
        backgroundColor: "rgba(255, 99, 132, 0.7)",
      },
    ],
  };

  return (
    <HStack spacing={5}>
      <Box borderWidth={"1px"} padding={5}>
        <Text size={"sm"}>
          Detected and annotated signs within X meters that differ from NVDB
          data
        </Text>
        <BarChart data={chartData} />
      </Box>
    </HStack>
  );
};
