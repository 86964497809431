import {
  Box,
  HStack,
  Stack,
  VStack,
  Text,
  Input,
  Button,
} from "@chakra-ui/react";
import { LatLng } from "leaflet";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { FeatureCollection, SinglePointResponse } from "../../http/http";
import { useState } from "react";
import L from "leaflet";
import { FeatureCollectionDataBox } from "./FeatureCollectionDataBox";
import orangeNeedleIcon from "../../assets/icons/needleleftyellow.png";
import { useToastbar } from "../../hooks/useToastbar";
import { AxiosError } from "axios";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const limitDecimals = (num: number) => {
  return Math.round(num * 100000) / 100000;
};

type SinglePointMapProps = {
  mapMatchPointAndRadius: (
    latlng: LatLng,
    radius: number
  ) => Promise<SinglePointResponse>;
};

export const SinglePointMap: React.FC<SinglePointMapProps> = ({
  mapMatchPointAndRadius,
}) => {
  const [position, setPosition] = useState<LatLng | null>(null);
  const [radius, setRadius] = useState<number>(50);
  const [singlePointDataState, setSinglePointDataState] =
    useState<SinglePointResponse | null>(null);
  const [featureMarkers, setFeatureMarkers] = useState<JSX.Element[] | null>(
    null
  );
  const toast = useToastbar();

  const HandleMapClick = () => {
    useMapEvents({
      click: (event) => {
        setPosition(event.latlng);
      },
    });
    return null;
  };

  const createMarker = (position: LatLng) => {
    return (
      <Marker position={position}>
        <Popup>
          <span>Latitude: {position.lat}</span>
          <br />
          <span>Longitude: {position.lng}</span>
        </Popup>
      </Marker>
    );
  };

  const needleIcon = L.icon({
    iconSize: [30, 41],
    iconAnchor: [12, 41],
    iconUrl: orangeNeedleIcon,
    popupAnchor: [0, -30],
  });

  const featuresToMarkers = (fc: FeatureCollection) => {
    if (!fc) return [];
    const markers = fc.features.map((feature, index) => {
      return (
        <Marker
          key={index}
          icon={needleIcon}
          position={[
            feature.geometry.coordinates[1],
            feature.geometry.coordinates[0],
          ]}
        >
          <Popup>
            <span>Feature {index}</span>
            <br />
            <span>Latitude: {feature.geometry.coordinates[1]}</span>
            <br />
            <span>Longitude: {feature.geometry.coordinates[0]}</span>
            <br />
            <span>
              Properties: <br />
              <pre>{JSON.stringify(feature.properties, null, 2)}</pre>
            </span>
          </Popup>
        </Marker>
      );
    });
    return markers;
  };

  const handleSubmit = async (pos: LatLng, radius = 50) => {
    await mapMatchPointAndRadius(pos, radius)
      .then((result) => {
        setSinglePointDataState(result);
        if(result.geo_json){
          const markers = featuresToMarkers(result.geo_json);
          setFeatureMarkers(markers);
        }
      })
      .catch((error: AxiosError) => {
        console.log(error);
        toast({
          title: "Failed to fetch geocoding data",
          description: error.message,
          status: "error",
        });
      });
  };

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      alignItems={{ base: "center", md: "start" }}
    >
      <MapContainer
        style={{ width: "950px", height: "950px" }}
        center={[57.72502648981336, 11.850171984290064]} // VCC
        zoom={16}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {featureMarkers}
        {position ? createMarker(position) : null}
        <HandleMapClick />
      </MapContainer>
      <VStack alignItems={"start"}>
        <Box borderWidth="1px">
          <VStack spacing={5} alignItems={"start"}>
            <HStack alignItems={"start"}>
              <VStack spacing={5} alignItems={"start"} padding={5}>
                <Text as={"b"}>Selected Position</Text>
                <HStack>
                  <Text>
                    Latitude: {position ? limitDecimals(position.lat) : "N/A"}
                  </Text>
                </HStack>
                <HStack>
                  <Text>
                    Longitude: {position ? limitDecimals(position.lng) : "N/A"}
                  </Text>
                </HStack>
                <HStack>
                  <Text>Radius: (in meters, max 200)</Text>
                  <Input
                    w={20}
                    type="number"
                    placeholder="50"
                    onChange={(e) => setRadius(Number(e.target.value))}
                    inputMode="numeric"
                  />
                </HStack>
                {(radius > 200 || radius < 1) && (
                  <Text fontSize={"xs"} color={"tomato"}>
                    Radius must be an integer between or equal to 1 and 200
                  </Text>
                )}
                <HStack spacing={5}>
                  <Button
                    size={"sm"}
                    isDisabled={position == null || radius > 200 || radius < 1}
                    onClick={() => handleSubmit(position!, radius)}
                  >
                    Submit
                  </Button>
                  <Button
                    size={"sm"}
                    variant="white"
                    onClick={() => {
                      setPosition(null);
                      setSinglePointDataState(null);
                      setFeatureMarkers(null);
                      setRadius(50);
                    }}
                  >
                    Reset
                  </Button>
                </HStack>
              </VStack>
            </HStack>
          </VStack>
        </Box>
        <FeatureCollectionDataBox
          collection={singlePointDataState?.geo_json || null}
        />
      </VStack>
    </Stack>
  );
};
