import { Box, Button, HStack, IconButton, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuIcon } from "../../assets/icons/icon";
import { useCkeckIfMobile } from "../../hooks/useCheckIfMobile";
import { useUser } from "../../services/user.context";
import { linksWithHiddenLoginButton, logIn } from "../../utils/navigationLinks";
import { Logo } from "../Logo";
import { Menu } from "./Menu";

export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useCkeckIfMobile();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [isOpen]);

  const renderLoginButton = () => {
    if (linksWithHiddenLoginButton.includes(location.pathname)) {
      return;
    }
    if (user) {
      return <p data-testid="authenticated">Welcome {user.name}!</p>;
    } else {
      return (
        <Button
          onClick={() => navigate(logIn.href)}
          mr={5}
          size={"small"}
          variant="primary"
        >
          Log in
        </Button>
      );
    }
  };

  return (
    <>
      <HStack
        className="Header"
        bg="#fff"
        zIndex={1}
        w={"100%"}
        alignItems="center"
        justifyContent="space-between"
        px={isMobile ? 0 : 5}
        py={5}
        borderBottomColor={"border.lineGrey"}
        borderBottomWidth={1}
      >
        <Logo
          systemColor="brand.black"
          levelColor="brand.darkGrey"
          safetyColor="brand.darkGreen"
        />

        <VStack>
          <Box>
            {renderLoginButton()}
            <IconButton
              size={"lg"}
              onClick={() => setIsOpen(!isOpen)}
              colorScheme="whiteAlpha"
              backgroundColor="transparent"
              icon={<MenuIcon />}
              aria-label="Open menu"
              boxShadow="none"
            />
          </Box>

          {isOpen && (
            <Menu isOpen={isOpen} setIsOpen={() => setIsOpen(!isOpen)} />
          )}
        </VStack>
      </HStack>
    </>
  );
};
