import { useEffect } from "react";
import { TravelTimesMap } from "./TravelTimesMap";

import { useCircleGeofenceSnap } from "../../state/circle-geofence-state";
import { useAddRouteMonitor } from "./polylinesAndCircles/routes/functions/useAddRouteMonitor";
import { useGetAllCreatedGeofences } from "./polylinesAndCircles/geofences/functions/useGetAllCreatedGeofences";

export const TravelTimesMapContainer = () => {
  const { addRouteMonitor } = useAddRouteMonitor();
  const listOfGeofence = useCircleGeofenceSnap().geofences;
  const { getAllCreatedGeofences } = useGetAllCreatedGeofences();

  useEffect(() => {
    if (listOfGeofence.length === 0) getAllCreatedGeofences();
  }, []);

  return <TravelTimesMap onAddRouteMonitor={addRouteMonitor} />;
};
