import { useNavigate, useSearchParams } from "react-router-dom";
import { MFASetup } from "./MFASetup";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { MFATokenContent } from "../../types";
import { useToastbar } from "../../hooks/useToastbar";
// TODO: Add a way to reset the MFA setup

export const MFASetupContainer = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const mfaToken = searchParams.get("mfa_token");
  const [url, setUrl] = useState("");
  const toastbar = useToastbar();

  useEffect(() => {
    if (!mfaToken) {
      return navigate("/login");
    }
    try {
      const mfaTokenContent: MFATokenContent = jwtDecode(mfaToken);
      setUrl(mfaTokenContent.mfa_url);
    } catch (error: any) {
      const errorMessage: string =
        typeof error.message === "string"
          ? error.message
          : "Malformed token or parsing error";
      toastbar({
        title: "Error loading MFA setup",
        description: errorMessage,
        status: "error",
      });
    }
  }, [mfaToken]);

  return (
    <>
      <MFASetup mfaUrl={url} />
    </>
  );
};
