import { MapMatchingService } from "./MapMatchingService";
import { LatLng } from "leaflet";
import {
  SinglePointResponse,
  mapmatchSinglePointRgsPointGet,
} from "../../http/http";

export const MapMatchingServiceContainer: React.FC = () => {
  const mapMatchPointAndRadius: (
    point: LatLng,
    radius: number
  ) => Promise<SinglePointResponse> = async (point: LatLng, radius: number) => {
    if (radius > 200) {
      Promise.reject("Radius is too large");
    }
    return await mapmatchSinglePointRgsPointGet({
      lat: point.lat,
      lon: point.lng,
      radius: radius,
    }).then((res) => res);
  };

  return (
    <>
      <MapMatchingService mapMatchSinglePoint={mapMatchPointAndRadius} />
    </>
  );
};
