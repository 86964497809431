import { useCallback } from "react";
import { deleteTtmMonitorId } from "../../../../../services/services";
import { RouteMonitorData } from "../../../../../types";

import { usePolylineRouteState } from "../../../../../state/polyline-route-state";

export const useDeleteRouteMonitor = () => {
  const deleteRouteMonitor = useCallback(async (route: RouteMonitorData) => {
    await deleteTtmMonitorId(route.id).then(() => {
      usePolylineRouteState().setFilteredRouteMonitors(route);
    });
  }, []);

  return { deleteRouteMonitor };
};
