import { SimpleGrid } from "@chakra-ui/react";

type Props = {
  first: any;
  second?: any;
  third?: any;
};

export const ThreeColumnLists = ({ first, second, third }: Props) => {
  return (
    <SimpleGrid
      borderBottomColor={"brand.lineGrey"}
      borderBottomWidth={1}
      py={1}
      borderRadius={3}
      mb={2}
      columns={3}
      spacing={5}
    >
      {first}
      {second}
      {third}
    </SimpleGrid>
  );
};
