import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Link,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { LoginInput, TotpInput } from "../../types";
import { resetPassword } from "../../utils/navigationLinks";
import { MFAVerificationModal } from "../../components/Authentication/MFAVerificationModal";

const defaultValues = {
  email: "",
  password: "",
};

type Props = {
  onLoginSubmit: (login: LoginInput) => Promise<void>;
  onMfaVerifySubmit: (totp: TotpInput) => Promise<void>;
};

const schema: yup.SchemaOf<LoginInput> = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Must be a valid email")
      .required("You must specify an email"),
    password: yup
      .string()
      .required("You must specify a password")
      .min(8, "Password must contain at least 8 characters"),
  })
  .defined();

export const LogIn = ({ onLoginSubmit, onMfaVerifySubmit }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<LoginInput>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  return (
    <Flex mb={28} mt={16} flexDirection="column" justify={"center"}>
      <form
        onSubmit={handleSubmit((login: LoginInput) =>
          onLoginSubmit(login)
            .then(onOpen)
            .catch((_) => {})
        )}
      >
        <VStack spacing={4}>
          <Heading as="h1">Login</Heading>
          <Box w={{ md: "md", base: "sm" }}>
            <Text as="label" htmlFor="email">
              Email*
            </Text>
            <Input id="email" type="email" {...register("email")} size="sm" />
            <Text color={"brand.red"}> {errors?.email?.message}</Text>
          </Box>

          <Box w={{ md: "md", base: "sm" }}>
            <Text as="label" htmlFor="password">
              Password*
            </Text>
            <Input
              id="password"
              type="password"
              {...register("password")}
              size="sm"
            />
            <Text color={"brand.red"}>{errors?.password?.message}</Text>
          </Box>

          <Button
            isLoading={isSubmitting}
            type="submit"
            size={"long"}
            variant="primary"
          >
            Log in
          </Button>
        </VStack>
      </form>
      <VStack mt={4}>
        <Link mb={2} href={resetPassword.href}>
          Forgot/Reset Password
        </Link>
        {/* <VStack
          style={{ borderBottomWidth: 1, borderBottomColor: "brand.lineGrey" }}
          w="md"
        ></VStack>
        <Text pt={2}>Don’t have an account?</Text>
        <Button
          onClick={() => navigate(createAccount.href)}
          size={"long"}
          variant="white"
        >
          Request account
        </Button> */}
      </VStack>
      <MFAVerificationModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onMfaVerifySubmit}
      />
    </Flex>
  );
};
