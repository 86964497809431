import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CreatePasswordInput } from "../../types";

const defaultValues: CreatePasswordInput = {
  password: "",
  confirmPassword: "",
};

type Props = {
  onSubmit: ({ password, confirmPassword }: CreatePasswordInput) => void;
};

const schema: yup.SchemaOf<CreatePasswordInput> = yup
  .object()
  .shape({
    password: yup
      .string()
      .required("You must specify a password")
      .min(8, "Password must contain at least 8 characters"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .defined();

export const CreatePassword = ({ onSubmit }: Props) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<CreatePasswordInput>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  return (
    <Flex mb={28} mt={16} flexDirection="column" justify={"center"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <Heading>Create new password</Heading>
          <Text>
            Please enter your desired password to finish account setup.
          </Text>
          <Box w={{ md: "md", base: "sm" }}>
            <Text>New Password</Text>
            <Input type="password" {...register("password")} size="sm" />
            <Text color={"brand.red"}>{errors?.password?.message}</Text>
          </Box>

          <Box w={{ md: "md", base: "sm" }}>
            <Text>Confirm Password</Text>
            <Input type="password" {...register("confirmPassword")} size="sm" />
            <Text color={"brand.red"}>{errors?.confirmPassword?.message}</Text>
          </Box>

          <Button
            isLoading={isSubmitting}
            type="submit"
            size={"long"}
            variant="primary"
          >
            Set Password
          </Button>
        </VStack>
      </form>
    </Flex>
  );
};
