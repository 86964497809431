import { Box, Heading, VStack } from "@chakra-ui/react";
import startPageCar from "../../assets/images/startPageCar.png";
import startPageCarMobile from "../../assets/images/startPageCarMobile.jpg";
import { AllServices } from "../../components/ServiceBox/AllServices";
import { useCkeckIfMobile } from "../../hooks/useCheckIfMobile";

export const StartPage = () => {
  const { isMobile } = useCkeckIfMobile();
  return (
    <VStack className="page">
      <Box
        w={isMobile ? "27rem" : "60rem"}
        h={isMobile ? "33rem" : "32rem"}
        backgroundImage={isMobile ? startPageCarMobile : startPageCar}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        //  mb={isMobile ? 0 : 64}
      >
        {/* <Image src={isMobile ? startPageCarMobile : startPageCar} /> */}
        <Heading
          as="h1"
          textAlign="center"
          color={"brand.white"}
          pt={isMobile ? 40 : 28}
          fontSize={{ md: "xxl", base: "6xl" }}
          fontWeight={400}
        >
          Use real time
          <br /> vehicle data
        </Heading>
      </Box>

      {/* <Stack
        pt={isMobile ? 0 : "31rem"}
        pos={isMobile ? "unset" : "absolute"}
        direction={isMobile ? "column" : "row"}
        justify={"start"}
        pl={isMobile ? 0 : 32}
      >
        <CreateAccount />
        <VStack
          alignItems={isMobile ? "center" : "start"}
          pl={isMobile ? 3 : 20}
          pr={20}
          pt={isMobile ? 15 : 20}
          justify={"center"}
        >
          <Heading>System Level Safety </Heading>
          <Text>
            Taking a system level perspective on traffic safety, applying
            connected vehicle data to new use cases.
          </Text>
        </VStack> 
      </Stack> */}
      <VStack pb={24}>
        <AllServices />
      </VStack>
    </VStack>
  );
};
